//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import FiltersList from "./Components/FiltersList";
import FilterPopup from "./Components/FilterPopup";
import arrow from "~/assets/img/icons/settings.svg?raw";
import FilterApplied from "./Components/FilterApplied";

export default {
    name: "StoreFilterMinimal",
    components: {FilterApplied, FiltersList, FilterPopup},
    data() {
        return {
            arrowIcon: arrow,
        }
    },
    computed: {
        ...mapGetters({
            filters: 'catalog/getFiltersList',
            productsCount: 'catalogStore/productsCount',
            showClearAllLink: 'catalogStore/showClearAllLink',
            clearLinkUrl: 'catalogStore/clearLinkUrl',
            getMobileFiltersPopupShowed: 'catalogStore/getMobileFiltersPopupShowed',
        }),
        closeLink() {
            return '/man'
        },
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            } else  {
                return false;
            }
        }
    },
    methods: {
        togglePopup() {
            this.$store.commit(`catalogStore/${types.CHANGE_MOBILE_FILTERS_POPUP_SHOWED_STATE}`, !this.getMobileFiltersPopupShowed)
            if (this.getMobileFiltersPopupShowed) {
                this.body && this.body.classList.add('v-popup-open');
                this.$breakpoint.isMacOs && this.body.classList.add('isMacOs');
                this.$liveChat && this.$liveChat.hide();
            } else  {
                this.body && this.body.classList.remove('v-popup-open');
                this.$liveChat && this.$liveChat.show();
            }
        }
    }

}
