export default {
    adidasFrontend: 'Адидас Фронтенд',
    registerSign: 'Тіркелген тауар белгісі',
    text: 'Мәтін',
    compilationTitle: 'ҚЫТАЙ ЖАҢА ЖЫЛЫ',
    compilationSubtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',

    forWhom: 'КІМГЕ ТАҢДАЙСЫҢ?',

    // MAN
    titleMan: 'Еркектерге',

    // WOMAN
    titleWoman: 'Әйелдерге',

    // CHILD
    titleChild: 'Балаларға',

    // TeaserCardSmall
    TeaserCardLabel1: 'Кроссовка',
    TeaserCardLabel2: 'Шалбар',
    TeaserCardLabel3: 'Желбегей және күрткелер',
    TeaserCardLabel4: 'Толстовки және худи',

    // MainBannerText
    mainBannerTitle: 'HI ENERGY - жүгіріп қуаттан',
    mainBannerSubTitle: 'Ultraboost 21 бұл энергия қайтарудың жаңа деңгейі',

    // SEO TEXT

    seoTextTitle1: 'ADIDAS - ТАРИХ, СТИЛЬ, 1949 ЖЫЛДАН БЕРІ КЕЛЕ ЖАТҚАН СПОРТТЫҚ КИІМ ЖӘНЕ АЯҚ КИІМ',
    seoTextTitle2: 'СЕНІҢ СПОРТТЫҚ ДҮКЕНІҢ. КЕЗ КЕЛГЕН СПОРТ ТҮРІ ҮШІН',
    seoText1: 'Спорт бізді қалпымызда ұстайды. Тәртіпке үйретеді. Бізді біріктіреді. Спорт арқылы өмірімізді өзгерте аламыз. Сонымен бірге спортшылардың шабыт беретін оқиғалары арқылы. Сенің орныңнан тұрып, алға басуыңа көмектеседі. Ең соңғы технологиялар спорттық мақсатта қолданылады, олар саған үздік нәтижелерге жетуге көмектеседі. adidas желаяқ, баскетболшы, жас футболшы мен фитнес жанкүйері үшін пана болар үйге айналды. Таудағы жалғызаяқ жолдарды бағындыруды немесе күнін медитациядан бастауды жақсы көретін жанның үйі. Музыканттар да үш жолақ киеді. Сахнада, фестивальдерде және жай ғана көшеде жүргенде. Біздің спорттық киім мәре ысқырығы естілгенде зейін қоюға көмектеседі. Жарыс кезінде. Сөре сызығында. Біз мұнда спортты маңызды деп санайтындарды қолдау үшін жүрміз. Олардың ойынын жақсартамыз. Өмірін де. Әлемнің де керемет қырын ашамыз.',
    seoText2: 'adidas барлық бағыттағы атлеттер үшін киім тігеді. Ойынның барысын өзгерткенді жақсы көретіндермен. Шарттылықтарға қарсы шығу. Ережелерді бұзу және жаңасын орнату. Сосын оны қайта бұзу. Спортшылар матч алдында біздің спорттық киімді киеді. Зейін қою үшін. Жарыс кезінде де. Жеңу үшін. Біз - әйелдерге арналған спорттық тауарлар дүкеніміз, онда бра мен денебітім шалбарының жеке мақсаты бар.Компрессия мен қолдаудың төменнен жоғары деңгейіне дейін. Біз жасаймыз, жаңартамыз және қайталаймыз. Жаңа технологияларды қолданыс үстінде сынақтан өткіземіз. Алаңда, жүгіру жолында, кортта және бассейнде. Біз өткеннің спорттық сұлбасынан шабыт алып, қала көшелері үшін жаңа модельдер жасаймыз. NMD немесе Firebird спорттық костюмдері сияқты. Классикалық спорттық силуэттер қайта оралуда. Stan Smith сияқты. Superstar да бар. Бүгінгі күні көшелерден және медиада кездестіруге болады.',

    // Subcategories
    subcat1: 'АЯҚ КИІМ',
    subcat2: 'КИІМ',
    subcat3: 'АКСЕССУАРЛАР',
    subcat4: 'ЖАҢА ТҮСІМДЕР',
    subcat5: 'БАРЛЫҚ ӘЙЕЛДЕР ТОПТАМАСЫ',


    buy: 'САТЫП АЛУ',
    readyToWork: 'Қысқа дайынбыз',

}
