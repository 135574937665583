//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
    name: "ResponsiveVideo",
    props:{
        media: {
            type: Object,
            required: true
        },
        videoLoop: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
            getCurrentImageCount: 'getCurrentImageCount',
            pageH1: 'dynamicPage/pageH1',
        }),
        data: function() {
            return {
                mediaIndex: 1
            }
        },
        responsiveSrc() {
            return this.$breakpoint.tabletLandscapeUp ? this.media.src.desktop['1x'] : this.$breakpoint.tabletSmallUp ? this.media.src.tablet['1x'] : this.media.src.mobile['1x']
        },
        title (){
            const title = _get(this, 'media.title', '');
            return title !== ''? title : `${this.pageH1} ${this.mediaIndex}`
        },
        alt (){
            const alt = _get(this, 'media.alt', '');
            return alt !== ''? alt : `${this.pageH1} ${this.mediaIndex}`
        }
    },
    mounted() {
        this.mediaIndex = this.getCurrentImageCount
        this.$store.commit('INCREASE_IMAGE_COUNT')
    }
}
