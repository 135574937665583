//
//
//
//
//
//
//
//
//
//

import mainLayout from "./layouts/Main";
import widgetMixin from "@/mixins/widgetMixin";
import SectionContainer from "~/components/common/section/SectionContainer.vue";

export default {
    name: "EsRecommendation",

    components: {
        SectionContainer,
        mainLayout,
    },

    mixins: [widgetMixin],

    props: {
        slideWidth: {
            type: Array,
            default: () => {
                return [254, 226, 131];
            }
        },
    },
};
