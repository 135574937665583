//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "MenuColumn",
    components: {
        MenuColumnFooter: () => import("./components/MenuColumnFooter"),
        TextItem: () => import("./components/components/textItem"),
        MenuColumnItem: () => import("./components/MenuColumnItem")
    },
    props: {
        columnKey: {
            required: true,
            type: Number
        },
        columnData: {
            required: true,
            type: Object
        },
        showOnlyFooter: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showAsSidebar: function () {
            return this.columnData.sidebarView
        },
        footerInfo: function () {
            return this.columnData.footer
        },
        columnItemsList: function () {
            return this.columnData.items
        }
    }
}
