//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "ProductPrice",
    props: {
        product: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        price() {
            return _get(this, 'product.price', {})
        },
        hasSale() {
            return this.price.sale !== this.price.first
        }
    }
}
