import { render, staticRenderFns } from "./Item.vue?vue&type=template&id=0709d061&scoped=true&"
import script from "./Item.vue?vue&type=script&lang=js&"
export * from "./Item.vue?vue&type=script&lang=js&"
import style0 from "./Item.vue?vue&type=style&index=0&id=0709d061&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0709d061",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonMedia: require('/opt/atlassian/pipelines/agent/build/components/common/CommonMedia.vue').default,CommonTitle: require('/opt/atlassian/pipelines/agent/build/components/common/CommonTitle.vue').default,CommonText: require('/opt/atlassian/pipelines/agent/build/components/common/CommonText.vue').default})
