//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";
import { mapGetters } from 'vuex';

export default {
    name: "LanguageSwitch",
    components: {VPopup},
    data() {
        return {
            curLocale: this.$i18n.locale,
            selectedLocale: this.$i18n.localeProperties,
        }
    },
    computed:{
        ...mapGetters({
            'links': 'header/getLangLinks',
        }),
    },
    methods: {
        onClose(){
            this.setPreferenceLocaleCookie()
        },
        setPreferenceLocaleCookie(lang){
            this.$store.dispatch('core/setLocaleCookie', lang);
        },
        async setNewLocale() {
            const selectedLocale = this.getLocale(this.selectedLocale);
            this.setPreferenceLocaleCookie(selectedLocale.code);
            let newLangUrl = this.links?.[selectedLocale.iso] || false;
            await this.$i18n.setLocale(selectedLocale.code);
            this.$popups.close();
            if (newLangUrl && !this.isNoAlternatePage) {
                window.location.href = newLangUrl;
            } else {
                window.location.reload();
            }
        },
        changeLangInput(lang){
            this.selectedLocaleCode = lang.code;
        },
        getLocale(locale) {
            return this.$i18n.locales.find((lang) => {
                return lang.code === locale.code;
            });
        },
        changeSelectedLocale(locale){
            this.selectedLocale = locale;
        }
    }
}
