export default {

    // ABOUT US
    titleAboutUs: 'Біз туралы',
    titleSizeGuide: 'Өлшемдер кестесі',

    // CONTACTS
    titleContacts: 'Дүкендердің байланыс деректері',
    ogDescriptionPrefix: 'adidas интернет-дүкені',

    // FAQ
    faq: {
        popular: {
            title: 'Жиі қойылатын сұрақтар',
            q_1: 'Тапсырысты қалай доғару керек?',
            q_2: 'Байланыс деректері',
            q_3: 'Курьерлік қызметпен жеткізу',
            q_4: 'Жеткізу және тауарды қайтару',
            q_5: 'Жеткізу',
            q_6: 'Сайтта adidas Universe жеңілдігін қалай қолдануға болады?'
        },
        themes: {
            title: 'Сұрақтар тақырыптары'
        },
        myOrder: {
            title: 'Менің тапсырысым қайда',
            subtitle: 'Сен өз тапсырысың туралы ақпаратты жеке кабинетте немесе «Тапсырысты бақылау» бөлімінде біле аласың.',
            buttonEnter: 'Кіру',
            buttonTracking: 'Тапсырысты бақылау'
        },
        noQuestion: {
            title: 'Жауабын таппадың ба?',
            subtitle: 'Клиенттерді қолдау қызметі саған {from} бастап {to} дейін, демалыссыз көмектесуге дайын:',
            phone: '+7 727 350 52 98 нөмірі арқылы;',
            email: 'support@kz.adidas.com электронды поштасына;',
            message: 'adidas.kz сайтында онлайн чатта. Чаттың белгішесі сайттың әрбір бетінде қолжетімді.'
        }
    },

    orderTracker: {
        title: 'Тапсырысты бақылау',
        text1: 'Тапсырысыңыздың нөмірін және оны ресімдеу кезінде көрсетілген электрондық мекенжайды енгізіңіз.',
        errorTitle: 'Қате комбинация',
        errorText: 'Электрондық пошта мекенжайы немесе тапсырыс нөмірі қате.',
        infoTitle: 'Бұл не?',
        infoText: 'Тапсырыс нөмірі хатта тапсырысты растаумен көрсетілген. Хатты алмадың ба? Ол «Спам» папкасына түсіп кетуі мүмкін. Егер тапсырыс жақында істелсе оны өңдеу уақыты 5 минутқа дейінгі уақытты алуы мүмкін.',
        btnText: 'Тапсырыс мәртебесі',
        btnText2: 'Аккаунтқа кіру',
        text2: 'Тапсырыс нөмірін таба алмай тұрсың ба? Тапсырыстардың барлық тарихын көру үшін, аккаунтқа кір',
    },

    // PRODUCT INFORMATION
    titleProductInformation: 'Тауар туралы ақпарат',

    // PRIVACY POLICY
    titlePrivacyPolicy: 'Құпиялылық саясаты',

    // CHECKOUT INFO
    titleCheckoutInfo: 'Тапсырысты ресімдеу',

    // DELIVERY
    titleDelivery: 'Жеткізу',

    // RETURN AND REFUND
    titleReturnRefund: 'Қайтару және ауыстыру',

    // PAYMENT TYPES
    titlePaymentTypes: 'Төлем тәсілдері',

    // COUPONS AND SALES
    titleCouponsSales: 'Купондар мен акциялар',

    // ACCOUNT AND SUBSCRIBE
    titleAccountSubscribe: 'Жеке кабинет және жаңалыққа жазылу',

    // PUBLIC OFFERT
    titlePublicOffert: 'Жария оферта',

    // GDPR
    titleGDPR: 'Дербес деректерді жинаудың жалпы қағидалары',

    // LOYALTY PROGRAM
    titleLoyaltyProgram: 'Бейілділік бағдарламасы',

    days: {
        monday: 'Дүйсенбі',
        tuesday: 'Сейсенбі',
        wednesday: 'Сәрсенбі',
        thursday: 'Бейсенбі',
        friday: 'Жұма',
        saturday: 'Сенбі',
        sunday: 'Жексенбі',
    },

    storeFinder: {
        title: 'Дүкенді іздеу',
        searchPlaceholder: 'ЕЛ, ҚАЛА, ӨҢІР, ИНДЕКС',
        findNear: 'Жақын жердегі дүкенді табу',
        phoneTitle: 'Телефон',
        addressTitle: 'Мекен-жай',
        scheduleTitle: 'Жұмыс уақыты',
        destinationAfter: 'сенен {distance} км.',
        loadMore: 'Тағы жүктеу',
        time: '{from} бастап {to} дейін',
        from: 'бастап',
        to: 'дейін',
    },

    schema: {
        description: 'Сүйікті брендіңізден киім, аяқ киім және аксессуарлар ᐈ adidas сайтында онлайн-шопингтен ләззат алыңыз ᐈ Қазақстанның барлық қалаларына жеткіземіз',
        addressLocality: 'Алматы',
        streetAddress: 'Достық даңғылы, 210',
    }
}
