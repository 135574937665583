//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonForm from "~/components/common/CommonForm";
import LazyFormRow from '~/components/common/LazyFormRow';
import CommonFormError from "~/components/common/CommonFormError";
import componentWithPostForm from "@/mixins/componentWithPostForm";
import YeezyPhonePopup from '@/components/popups/YeezyPopups/YeezyPhonePopup/index';
import YeezyOrderThanks from "@/components/popups/YeezyPopups/YeezyOrderThanks/index.vue";
import YeezyConfirmSize from "@/components/popups/YeezyPopups/YeezyConfirmSize/index.vue";
import FormChapterLayout from "./Components/YeezyFormDataCharterLayout/index.vue";
import Input from "../../../../../../../../components/common/LazyFormRow/components/Checkbox";
import {mapGetters} from "vuex";


export default {
    name: "YeezyFormData",
    mixins: [
        componentWithPostForm
    ],
    components: {
        Input,
        LazyFormRow,
        CommonForm,
        CommonFormError,
        FormChapterLayout
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            formId: 'yeezy_booking_form',
            formPhoneName: 'otp_verify_sms_step_one',
            loading: false,
            hasOneSubmit: false,
        }
    },
    methods: {
        async successSubmit(responseData) {
            await this.$store.dispatch('user/fetchUserData');
            this.loading = false;
            this.$popups.close();
            this.$popups.open({
                component: YeezyOrderThanks,
            })
        },
        errorSubmit(data, codes) {
            let errorCode = _get(codes, 'ApiYeezyBookingForm');
            if (data.isAlreadyExist) {
                this.$emit('isAlreadyExist', _get(data, 'size', ''))
            } else {
                this.$popups.close();
            }
        },
        formSubmit() {
            this.loading = true
        },
        async openConfirmPhone(){
            await this.$store.dispatch(`forms/loadFormById`, this.formPhoneName);
            this.$store.commit(`forms/${types.UPDATE_FORM_VALUE_BY_ID}`, {
                formId: this.formId,
                fieldName: 'yeezy_id',
                value: this.product.id
            })
            this.$popups.close();
            this.$popups.open({
                component: YeezyPhonePopup,
                props: {
                    formId: this.formId,
                    yeezyMode: true,
                    pushToRegisterOnError: this.loginPage
                },
                on: {
                     phoneConfirm: function (phone, token) {
                        this.$store.commit(`forms/${types.UPDATE_FORM_VALUE_BY_ID}`, {
                            formId: this.formId,
                            fieldName: 'phone_number',
                            value: phone
                        })
                         if(token && this.isGuest){
                             this.$store.commit(`forms/${types.UPDATE_FORM_VALUE_BY_ID}`, {
                                 formId: this.formId,
                                 fieldName: 'token',
                                 value: token
                             })
                         }
                    }.bind(this)
                }
            })
        },
        async checkSize() {
            await this.$refs.yeezyForm.validateForm();
            this.hasOneSubmit = true;

            if (!!this.sizeFormValue) {
                setTimeout(function (){
                    if (this.$refs.yeezyForm.allValidated) {
                        this.$emit('sizeError', '')
                        this.$popups.close();
                        this.$popups.open({
                            component: YeezyConfirmSize,
                            props: {
                                currentSize: this.currentSize
                            },
                            on: {
                                submitSize: function () {
                                    this.$refs.submitForm.click()
                                }.bind(this)
                            }
                        })
                    }
                }.bind(this), 300);
        } else {
            this.$emit('sizeError', this.$t('yeezy.chooseYourSize'))
        }
    }
    },
    async fetch() {
        if(!this.formId) {
            return
        }
        await this.$store.dispatch(`forms/loadFormByIdWithData`, {
            formId: this.formId,
        });
    },
    computed: {
        ...mapGetters({
            'isGuest': 'user/isGuest',
            'formValid': `ApiYeezyBookingForm/getValidateFormNow`
        }),
        personalDataRows() {
            return this.fields.filter(i => i.groupName === 'personalDataRows')
        },
        birthDateRows() {
            return this.fields.filter(i => i.groupName === 'birthDateRows')
        },
        confirmRows() {
            return this.fields.filter(i => i.groupName === 'confirmRows')
        },
        phoneIsFilled() {
            return this.fields.find(i => i.name === 'phone_number').value.length
        },
        sizeFormValue() {
            return this.fields.find(i => i.name === 'yeezy_size_id').value || ''
        },
        currentSize() {
            return this.product.sizes.find(i => i.id === this.sizeFormValue) || null
        },
        showConfirmPhoneBtn(){
            return !this.phoneIsFilled && this.isGuest
        }
    },
    async mounted(){
        this.$store.commit(`forms/${types.UPDATE_FORM_VALUE_BY_ID}`, {
            formId: this.formId,
            fieldName: 'yeezy_id',
            value: this.product.id
        })
    },
    beforeDestroy() {
        this.$store.commit(`forms/${types.CLEAR_ALL_FIELDS}`, {
            formId: this.formId,
            exclusionFields: []
        })
    },
}
