//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import pageMixin from "./mixins/page";
import ProductCarousel from "@/widgets/productCarousel/Index";
import { debounce } from "lodash";
import searchRequestEvent from "~/assets/js/eSputnik/searchRequestEvent";

const debouncedEsEvent = debounce(searchRequestEvent, 500);

export default {
    name: "PageNoResults",
    components: { ProductCarousel },
    mixins: [pageMixin],
    data() {
        return {
            widgetData: null
        };
    },
    computed: {
        searchQuery() {
            return _get(this.$route, "query.s", null);
        }
    },
    watch: {
        searchQuery: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    debouncedEsEvent({
                        search: newVal,
                        isFound: 0,
                    });
                }
            },
        },
    },
    async mounted() {
        let widgetId = "widget_we_recommend__automatic";
        await this.$axios.post(`${ API.main }/widgets`, [
            widgetId
        ]).then(function(response) {
            this.widgetData = { data: response.data[widgetId] };
        }.bind(this));
        if (this.searchQuery) {
            debouncedEsEvent({
                search: this.searchQuery,
                isFound: 0,
            });
        }
    }
};
