//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import langSwitchPopup from '~/components/popups/LanguageSwitch';

import HeaderMobileContainer from "./components/HeaderMobileContainer";
import HeaderIconContainer from "./components/HeaderIconContainer";
import HeaderMenu from "./components/HeaderMenu";
import HeaderStatic from "./components/HeaderStatic";
import HeaderLogo from "./components/HeaderLogo";

import {mapGetters} from 'vuex';
import LazyHydrate from "vue-lazy-hydration";

export default {
    name: "HeaderMain",
    components: {
        LazyHydrate,
        HeaderMobileContainer,
        HeaderIconContainer,
        HeaderMenu,
        HeaderStatic,
        HeaderLogo,
    },
    data: function () {
        return {}
    },
    computed: {
        ...mapGetters({
            topLinksCount: 'header/getTopLinksCount',
            topLinks: 'header/getTopLinks',
            menuItems: 'header/getMenu',
            showLangPopup: 'core/showLangPopup'
        }),
        showTopLinks() {
            return this.topLinksCount
        },
        routeFull() {
            return this.$route.fullPath
        },
        isDesktop() {
            return this.$breakpoint.tabletLandscapeUp
        }
    },
    methods: {
        sendHeight() {
            const height = this.$el.offsetHeight;
            this.$emit('height', height)
        },
        onClickProfile() {
            // TODO Change route name
            this.$router.push(this.localePath({name: 'account-details'}))
        },
        onClickFavorite() {
            // TODO Change route name
            this.$router.push(this.localePath({name: 'account-favorites'}))
        },
        onClickBag() {
            // TODO Change route name
            this.$router.push(this.localePath({name: 'cart'}))
        },
        openLanguageSwitch() {
            this.$popups.open({
                component: langSwitchPopup
            })
        }
    },
    watch: {
        routeFull: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.sendHeight()
                }
            }
        }
    },
    mounted() {
        this.sendHeight();
        this.showLangPopup && this.openLanguageSwitch();
    },
}
