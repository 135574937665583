//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import icons from "~/assets/img/icons";
import {mapGetters} from "vuex";

export default {
    name: "HeaderStaticLanguage",
    data() {
        return {
            icons,
            showList: false,
            isMacOs: false,
            addRouterHook:true
        }
    },
    computed: {
        ...mapGetters({
            'links': 'header/getLangLinks',
        }),
        langName: function () {
            return this.$i18n.locales.find((lang) => {
                return lang.code === this.$i18n.locale
            })?.label;
        },
        localesListWithOutActive: function () {
            return this.$i18n.locales.filter((lang) => {
                return lang.code !== this.$i18n.locale
            });
        },
        isNoAlternatePage() {
            return this.$route.path.includes('/account') || this.$route.path.includes('/order-view')
        },
        currentLocale() {
            return this.$i18n
        }
    },
    methods: {
        toggleList(state) {
            state = typeof state == 'boolean' ? state : !this.showList;
            this.showList = state
        },
        checkOS: function () {
            if(window.navigator.userAgent.indexOf("Macintosh") !== -1) {
                this.isMacOs = true;
            }
        },
        setPreferenceLocaleCookie(lang){
            this.$store.dispatch('core/setLocaleCookie', lang);
        },
        async setNewLang(lang) {
            this.toggleList(false);
            this.setPreferenceLocaleCookie(lang.code);
            let newLangUrl = this.links?.[lang.iso] || false;
            await this.$i18n.setLocale(lang.code);
            const requestQueryStr = Object.keys(this.$route.query).reduce((acc, key, index) => {
                return index === 0 ?
                    `${ acc }?${ key }=${ this.$route.query[key] }` :
                    `${ acc }&${ key }=${ this.$route.query[key] }`;
            }, '');
            if (newLangUrl && !this.isNoAlternatePage) {
                window.location.href = newLangUrl+requestQueryStr;
            } else {
                window.location.reload();
            }
        }
    },
    mounted(){
        this.addRouterHook && this.$router.beforeHooks.unshift((to, from, next)=>{
            this.toggleList(false);
            this.addRouterHook = false;
            next();
        });
        this.checkOS();
    }
}
