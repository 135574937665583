export default {
    notFound: 'Кесте табылмады',
    shoes: {
        title: "ЕРЛЕР МЕН ӘЙЕЛДЕРДІҢ АЯҚ КИІМ ӨЛШЕМДЕРІ",
        description: "Кестеде табанның ұзындығы көрсетілген. Ұлтанның ұзындығы 10-15 мм. функционалдық қосымша шамасына табан ұзындығынан үлкенірек",
        howFindSize: "ӨЛ ӨЛШЕМІҢДІ ҚАЛАЙ АНЫҚТАЙСЫҢ",
        tabs: {
            title1: "дюйм",
            title2: "cm"
        },
        table: {
            headers: {
                inch: {
                    title: "Табанның ұзындығы (INCH)"
                },
                centimeter: {
                    title: "Табанның ұзындығы"
                }
            },
            body: {
                inch: {
                    subtitle1: "UK",
                    subtitle2: "EU",
                    subtitle3: "US - Ерлер",
                    subtitle4: "US - Әйелдер",
                    subtitle5: "Табан ұзындығы (см)",
                },
                centimeter: {
                    subtitle1: "KZ",
                    subtitle2: "EU",
                    subtitle3: "US - Ерлер",
                    subtitle4: "US - Әйелдер",
                    subtitle5: "UK",
                }
            },
            description: "Көбірек көру үшін, айналдыр"
        },
        questions: {
            block1: {
                title: "екі өлшемнің арасын таңдайсың ба?",
                description1: "неғұрлым денеге қонымды болу үшін бір өлшемге кіші модельді таңда.",
                description2: "Неғұрлым кең тұру үшін бір өлшемге үлкен модельді таңда."
            },
            block2: {
                title: "ӨЗ ӨЛШЕМІҢДІ ҚАЛАЙ БІЛУГЕ БОЛАДЫ?",
                description: "Өз өлшеміңді анықтау үшін осы қарапайым кеңестерге құлақ сал. Керемет нәтиже үшін, дұрысы " +
                    "өлшеуді кешке жасау керек. Ұлтанның ұзындығы табан ұзындығынан ұзынырақ екеніне назар аударамыз " +
                    "функционалдық қосымша шамасы 10-15 мм. Өлшемдер кестесінде көрсетілген табан ұзындығына " +
                    "бағдар жасауыңды сұраймыз.",
                option1: "Аяғыңды бір парақ қағазға өкшең қабырғаға тиіп тұратындай етіп қой.",
                option2: "Ең үлкен башпайдың аяқталатын жерін белгіле (саған тағы бір адамның көмегі керек " +
                    "болуы мүмкін), және осы белгіден қабырғаға дейінгі қашықтықты өлше.",
                option3: "Екінші аяғыңды да дәл солай өлше және біздің өлшемдер кестемізбен өлшемді салыстыр, сонда " +
                    "қажет өлшемді табасың.",
            },
            block3: {
                title: "Өлшемі немесе ТҮСІ сай келмеді ме?",
                description: "Ештеңе етпейді, тауарды тегін қайтаруыңа болады."
            }
        },
        goUp: "Үстіге"
    },
    childrenShoes: {
        title: "ӨЛШЕМДІ ТАҢДАУ КЕРЕК ПЕ?",
        anchor1: "СӘБИЛЕР (0-4 ЖАС)",
        anchor2: "БАЛАЛАР (4-8 жас)",
        anchor3: "ЖАСӨСПІРІМДЕР (8-16 жас)",
        anchor4: "ӨЗ ӨЛШЕМІҢДІ ҚАЛАЙ БІЛУГЕ БОЛАДЫ",
        howFindSize: "ӨЗ ӨЛШЕМІҢДІ ҚАЛАЙ БІЛУГЕ БОЛАДЫ",
        table: {
            body: {
                subtitle1: "UK",
                subtitle2: "US",
                subtitle3: "EU",
            },
        },
        group1: {
            title: "0-4 жастағы ҰЛДАР МЕН ҚЫЗДАР",
            bannerTitle: "Сәбилер"
        },
        group2: {
            title: "4-8 жастағы ҰЛДАР МЕН ҚЫЗДАР",
            bannerTitle: "БАЛАЛАР"
        },
        group3: {
            title: "8-16 жастағы ҰЛДАР МЕН ҚЫЗДАР",
            bannerTitle: "ЖАСӨСПІРІМДЕР"
        },
        buy: "Сатып алу",
        slider: {
            title: "Пайдалы кеңес",
            sliderItem1: {
                title: "Ұлтан бойынша өлшемді АНЫҚТАУ",
                description: "Сәбилер мен балаларға арналған аяқ киімде adiFIT өлшегіш ұлтаны болады. Кроссовокадан adiFIT ұлтанын алып шығыңыз"
            },
            sliderItem2: {
                title: "Ұлтанға табанды қойыңдар",
                description: "Баланың adiFIT ұлтанына аяғын қоюын сұра, сонда қажет өлшем оңай анықталады"
            },
            sliderItem3: {
                title: "Ұлтанға табанды қойыңдар",
                description: "Егер табан таңбалаудан шығып кетПЕсе, демек осы өлшем сай келеді."
            }
        }
    },
    childrenClothes: {
        kidTable: {
            headers: {subtitle1: "белгі", subtitle2: "0 АЙ | 56", subtitle3: "0-3 АЙ | 62", subtitle4: "3-6 АЙ | 68", subtitle5: "6-9 АЙ | 74", subtitle6: "9-12 АЙ | 80", subtitle7: "12-18 АЙ | 86", subtitle8: "1-2 жас | 92", subtitle9: "2-3 жас | 98"},
            body: {
                subtitle1: "БОЙ",
                subtitle2: "КЕУДЕ",
                subtitle3: "БЕЛ",
                subtitle4: "МЫҚЫН",
                subtitle5: "Ішкі тігіс ұзындығы",
            }
        },
        childTable: {
            headers: {subtitle1: "белгі", subtitle2: "3-4 жас | 104", subtitle3: "4-5 жас | 110", subtitle4: "5-6 жас | 116", subtitle5: "6-7 жас | 122"},
        },
        teenTable: {
            headers: {subtitle1: "белгі", subtitle2: "7-8 жас | 128", subtitle3: "8-9 жас | 134", subtitle4: "9-10 жас | 140", subtitle5: "10-11 жас | 146", subtitle6: "11-12 жас | 152", subtitle7: "12-13 жас | 158", subtitle8: "13-14 жас | 164", subtitle9: "14-15 жас | 170", subtitle10: "15-16 жас | 176",},
        },
        questions: {
            block2: {
                description: "Сантиметрді ал, өлшем жаса, алған нәтижелерді жазып қой және біздің кестемізден қара, " +
                    "қай өлшем дұрыс келеді екен. Сенің досың немесе құрбың осыған көмектесе алады",
                option1: "Өлшегің келсе, сантиметрді көлденеңінен ұста:",
                option2: "<span>1. Грудь,</span> Кеуденің қатты шығып тұратын бөлігінің айналымы",
                option3: "<span>2. Бел,</span> Белдің ең кіші бөлігінің көлемі",
                option4: "<span>3. Мықын,</span> Екі аяқты бірге қойғанда мықынның ең жалпақ жерінің көлемі.",
                option5: "мына параметрлерді өлшегің келсе, сантиметрді тігінен ұста:",
                option6: "<span>4. Ішкі тігіс бойынша ұзындығы,</span> Бұл қадамдық тігіс сынасынан еденге дейінгі қашықтық",
                option7: "<span>5. Бой,</span> Өкшеден төбеге дейінгі қашықтық. Өлшем алған кезде тік тұру керек."
            }
        }
    },
    womenSwimsuits: {
        title: "әйелдер шомылу киімі",
        table: {
            header: {
                title1: "Өлшемі",
                title2: "Өлшемі<br>KZ",
                title3: "Биркадағы өлшемі<br>(D)",
                title4: "Кеуде<br>(см)",
                title5: "Бел<br>(см)",
                title6: "Мықын<br>(см)",
                title7: "Дене<br>(айналым)<br>(см)",
            }
        },
        tip1: {
            option4: {
                title: "ТҰЛЫПТЫҢ ТІК айналымы",
                description: "Оң жақ иықтағы мойын түбінің нүктесінен бастап өлшеп, дене арқылы тігінен түсіңіз және суретте көрсетілгендей арқа жақтан бастапқы нүктеге қайтып келіңіз."
            }
        },
        tip2: {
            title: "ДҰРЫС ӨЛШЕМДІ ТАҢДАҢЫЗ",
            description1: "Егер мықын мен белдің мәні әртүрлі болып шықса, мықын айналымының мәніне сәйкес келетінін таңдаңыз.",
            description2: "Өлшем сай келмеді ме? Алаңдамаңыз! Қарапайым және тегін қайтару процедурасы 60 күн бойы ашық. Көбірек білу үшін, қайтару шарттарымен танысыңыз."
        }
    },
    womenClothes: {
        title: "ӘЙЕЛДЕР КИІМІ",
        table: {
            header: {
                title1: "Өлшемі",
                title2: "Өлшемі<br>KZ",
                title3: "Немісше өлшем<br>(D)",
                title4: "Кеуде<br>(см)",
                title5: "Бел<br>(см)",
                title6: "Мықын<br>(см)",
            },
            description1: "Кейбір модельдер үшін әртүрлі ұзындық қолжетімді:",
            description2: "long - ұзынша модель қажет болса, осыны таңда",
            description3: "short - қысқаша модель қажет болса, осыны таңда",
            description4: "tall - ортадан жоғары бойға арналған модель керек болса, осыны таңда",
        },
        tip1: {
            title: "ӨЗ<br> ӨЛШЕМІҢДІ ҚАЛАЙ ТАҢДАУ КЕРЕК",
            description: "Өз киім өлшеміңді білу үшін, нұсқаулықты сақтаңыз:",
            option1: {
                title: "КЕУДЕ",
                description: "Өз футболкаңның өлшемін анықтау үшін, кеуденің ең жалпақ бөлігінің айналымын Тігінші сантиметрімен өлшеңіз. Сантиметрді көлденең ұста."
            },
            option2: {
                title: "БЕЛ",
                description: "Тігінші сантиметрін көлденең ұстап, белдің ең жіңішке бөлігінің көлемін өлше."
            },
            option3: {
                title: "мықын",
                description: "Аяқты бірге қойып, мықынның ең жалпақ бөлігінің көлемін өлшеңіз. Тігінші сантиметрін көлденең ұстаңыз."
            }
        },
        tip2: {
            title: "ДҰРЫС<br> ӨЛШЕМДІ ТАҢДАҢЫЗ",
            description1: "Егер кеуде мен бел айналымының мәні әртүрлі өлшемде болып шықса, кеуде айналымының мәніне сәйкес келетінін таңдаңыз",
            description2: "Өлшем сай келмеді ме? Алаңдамаңыз! Қарапайым және тегін қайтару процедурасы 60 күн бойы ашық. Көбірек білу үшін, қайтару шарттарымен танысыңыз."
        }
    },
    womenTrousers: {
        title: "ӘЙЕЛДЕР ШАЛБАРЫ ЖӘНЕ<br> ҚЫСҚА ШАЛБАРЫ",
        table: {
            header: {
                title1: "Өлшемі",
                title2: "Өлшемі<br>KZ",
                title3: "Немісше өлшем<br>(D)",
                title4: "Бел<br>(см)",
                title5: "Мықын<br>(см)",
            }
        },
        tip1: {
            description: "Мына әрекеттермен өз киім өлшеміңді анықтайсың:",
            option4: {
                title: "Ішкі тігіс бойынша ұзындық",
                description: "Мықынның үстіңгі бөлігінен еденге дейінгі қашықтықты суретте көрсетілгендей өлшеңіз"
            }
        }
    },
    manTrousers: {
        title: "ЕРЛЕР АЛБАРЫ ЖӘНЕ<br> ҚЫСҚА ШАЛБАРЫ",
    },
    manClothes: {
        title: "ЕРЛЕР КИІМІ",
    },
    summerShoes: {
        title: "РЕЗЕҢКЕ ШӘРКЕЙ МЕН САНДАЛДАР",
        subtitle1: "<span>Назар аударыңдар! Бұйымның қорабында және биркасында шетелдік өлшемдер көрсетілген</span><br>KZ мен UK, US, FR сәйкестінін кестеден біліңіз.",
        subtitle2: "Ұлтанның ұзындығы 10-15 мм. функционалдық қосымша шамасына табан ұзындығынан үлкенірек.",
        tip: {
            title: "АЯҚТЫҢ ҰШЫНАН ӨКШЕГЕ ДЕЙІН ҰЗЫНДЫҚТЫ ӨЛШЕҢІЗ",
            description1: "Табанды таңертең өлшемеуге кеңес береміз. Әдетте кешке ыстықтан және жүктемеден аяқ аздап ісінеді. Дұрысы табанды кешке өлшеу керек.",
            description2: "Өз аяқ кіиіміңнің өлшемін анықтау үшін нұсқаулықты сақтаңыз:",
            description3: "Бір парақ қағазды бір жағын қабырғаға жақындатып еденге қойыңыз. Өкшені қабырғаға тіреп, аяқты қағаз парағына қойыңыз. Қарындашпен саусақ ұшының сызығын белгілеңіз.",
            description4: "Парақтың шетінен белгіге дейінгі қашықтықты өлшеңіз және кестедегі мәнмен салыстырыңыз.",
        },
        tip1: {
            description1: "Істегеннен айтқан оңай. Егер екі өлшем аралығында алып қойсаңыз, не істеу керек?",
            description2: "Бұл жағдайда үлкенірек өлшемді таңдаған қауіпсіздеу.",
            description3: "Өлшем сай келмеді ме? Алаңдамаңыз! Қарапайым және тегін қайтару процедурасы 60 күн бойы ашық. Көбірек білу үшін, қайтару шарттарымен танысыңыз.",
        },
        table: {
            header: {
                title1: "KZ",
                title2: "FR / EUR",
                title3: "UK",
                title4: "US ЕР",
                title5: "US ӘЙЕЛ",
                title6: "Табан ұзындығы",
            }
        }
    },
    footballBoots: {
        title: "ФУТБОЛ АЯҚ КИІМІН ТАҢДАУ ЖӨНІНДЕГІ БАСШЫЛЫҚ",
        description: "Кестеде табанның ұзындығы көрсетілген. Ұлтанның ұзындығы 10-15 мм. функционалдық қосымша шамасына табан ұзындығынан үлкенірек.",
        anchors: [
            {
                title: 'АЯҚ КИІМНІҢ ӨЛШЕМДІК ТОРЫ',
                link: 'findSize'
            },
            {
                title: 'АЯҚ КИІМНІҢ ТОЛЫҚТЫҒЫ',
                link: 'shoesComfortable'
            },
            {
                title: 'МАТЕРИАЛДАР ЖӘНЕ ҚОНЫМДЫЛЫҚ',
                link: 'materialsAndFit'
            },
            {
                title: 'ФУТБОЛ БУТСЫСЫН ҚАЛАЙ ӨЛШЕУ КЕРЕК',
                link: 'howToFitShoes'
            },
            {
                title: 'ӨЗ ӨЛШЕМІҢДІ ҚАЛАЙ БІЛУ КЕРЕК',
                link: 'howToKnowSize'
            }
        ],
        sizeTableTitle: 'АЯҚ КИІМНІҢ ӨЛШЕМДІК ТОРЫ',
        tabs: {
            title1: "Дюйм",
            title2: "cm"
        },
        table: {
            headers: {
                inch: {
                    title: "Табанның ұзындығы (INCH)"
                },
                centimeter: {
                    title: "Табанның ұзындығы"
                }
            },
            body: {
                inch: {
                    subtitle1: "UK",
                    subtitle2: "EU",
                    subtitle3: "US - Ерлер",
                    subtitle4: "US - Әйелдер",
                    subtitle5: "Табанның ұзындығы (см)",
                },
                centimeter: {
                    subtitle1: "KZ",
                    subtitle2: "EU",
                    subtitle3: "US - Ерлер",
                    subtitle4: "US - Әйелдер",
                    subtitle5: "UK",
                }
            },
            description: "Көбірек көру үшін саусағыңмен бір жағына қарай жылжыт."
        },
        block1: {
            title: "АЯҚ КИІМНІҢ ТОЛЫҚТЫҒЫ",
            description1: "Оңтайлы қонымдылық үшін табанның енін ескер.",
            description2: "X және Copa футбол бутсылары табанның стандарт еніне есептелген. Predator бутсысы стандарт және неғұрлым кең табанға сай келеді."
        },
        block2: {
            title: "МАТЕРИАЛДАР ЖӘНЕ ҚОНЫМДЫЛЫҚ",
            description: "Допты мінсіз сезінуді және жанасу дәлдігін қамтамасыз ету үшін, бутсы табанға тығыз әрі жайлы қонуы керек. Қонымдылыққа үстін, бауы мен қонышын жасағанда қолданылатын материалдар әсер етеді.",
            tips:[
                {
                    title: 'Үстінің материалы',
                    description: "Уақыт өте келе былғары сияқты органикалық материалдан жасалған бутсының үсті созылады. Сонда бутсы біршама кеңейеді. Primeknit сияқты синтетикалық материалдан немесе текстиль тордан жасалған үсті созылмайды дерлік және бастапқы пішінін сақтайды."
                },
                {
                    title: 'Бау',
                    description: "Баусыз футбол бутсысы табанды мықтап бекітеді. Оларды аяқта сезілмейді дерлік. Бау тағылған футбол бутсысы қонымдылықтың тығыздығын реттеуге мүмкіндік береді."
                },
                {
                    title: 'Қоныш',
                    description: "Биік бутсы (қоныш тобықты толық жабады) және аласа бутсы (тһқоныш тобыққа жетпейді немесе дәл келеді) аяқта түрліше сезіледі."
                }
            ]
        },
        block3: {
            title: "ФУТБОЛ БУТСЫСЫН ҚАЛАЙ ӨЛШЕУ КЕРЕК",
            description: "Бутсыны ойын киімімен бірге киіп көр, мысалы: серіппелі ендірмелері бар гетрымен немесе онсыз және сирақты қорғайтын қалқанмен.",
            tips: [
               {
                    title: 'Екі бутсыны да киіп көр',
                    description: "Бір аяқ екіншісінен үлкенірек болуы мүмкін болғандықтан, міндетті түрде екі бутсыны да киіп көр."
                },
                {
                    title: 'Бутсының бауын дұрыстап тақ',
                    description: "Бекіту қысымы мен дәрежесін біркелкі бөліп, бауын тақ және табан аумағында жиналып қалмағанын қара."
                },
                {
                    title: 'Жүріп көр',
                    description: "Бутсының сенің салмағыңа жақсы тұрғанын тексерген маңызды. Үсті мен табанының толық көңіліңнен шыққанына көзіңді жеткіз."
                },
                {
                    title: 'Бутсының ешбір жерді қысып тұрмағанын және тым бос тұрмағанын тексер',
                    description: "Жайлы қонымды киілу кенет қимыл ауыстыру кезінде табанды бір жерде ұстауға көмектеседі."
                }
            ]
        },
        block4: {
            title: "ӨЛШЕМІ немесе ТҮСІ сай келмеді ме?",
            description: "Ештеңе етпейді, тауарды тегін қайтаруыңа болады."
        },
        goUp: "Үстіге"
    }
}
