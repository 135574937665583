//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "ShoesTable",
    props: {
        isInch: {
            type: Boolean,
            default: true,
        }
    },
    data: function() {
        return {
            tableData: {
                head: {
                    inch: {
                        tableCellData: [this.$t("sizeTable.shoes.table.headers.inch.title"), "8.7\"", "8.9\"", "9.0\"", "9.2\"", "9.4\"", "9.5\"", "9.7\"", "9.8\"", "10.0\"", "10.2\"", "10.4\"", "10.5\"", "10.7\"", "10.9\"", "11.0\"", "11.2\"", "11.3\"", "11.5\"", "11.7\"", "11.9\"", "12.0\"", "12.2\"", "12.4\"", "12.7\"", "13.0\"", "13.3\"", "13.7\"", "14.0\""],
                        CellsClasses: "",
                    },
                    centimeter: {
                        tableCellData: [this.$t("sizeTable.shoes.table.headers.centimeter.title"), "22.1 см", "22.5 см", "22.9 см", "23.3 см", "23.8 см", "24.2 см", "24.6 см", "25 см", "25.5 см", "26 см", "26.3 см", "26.7 см", "27.1 см", "27.6 см", "28 см", "28.4 см", "28.8 см", "29.3 см", "29.7 см", "30.1 см", "30.5 см", "31 см", "31.4 см", "32.2 см", "33.1 см", "33.9 см", "34.8 см", "35.6 см"],
                        CellsClasses: "",
                    }
                },
                body: {
                    inch: [
                        {
                            tableCellData: [this.$t("sizeTable.shoes.table.body.inch.subtitle1"), "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "13.5", "14", "14.5", "15", "16", "17", "18", "19"],
                            CellsClasses: "",
                        },
                        {
                            tableCellData: [this.$t("sizeTable.shoes.table.body.inch.subtitle2"), "36", "36 2/3", "37 1/3", "38", "38 2/3", "39 1/3", "40", "40 2/3", "41 1/3", "42", "42 2/3", "43 1/3", "44", "44 2/3", "45 1/3", "46", "46 2/3", "47 1/3", "48", "48 2/3", "49 1/3", "50", "50 2/3", "51 1/3", "52 2/3", "53 1/3", "54 2/3", "55 2/3"],
                            CellsClasses: "",
                        },
                        {
                            tableCellData: [this.$t("sizeTable.shoes.table.body.inch.subtitle3"), "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "13.5", "14", "14.5", "15", "16", "17", "18", "19", "20"],
                            CellsClasses: "",
                        },
                        {
                            tableCellData: [this.$t("sizeTable.shoes.table.body.inch.subtitle4"), "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "13.5", "14", "14.5", "15", "15.5", "--", "--", "--", "--", "--", "--"],
                            CellsClasses: "",
                        },
                        {
                            tableCellData: [this.$t("sizeTable.shoes.table.body.inch.subtitle5"), "22.1 см", "22.5 см", "22.9 см", "23.3 см", "23.8 см", "24.2 см", "24.6 см", "25.0 см", "25.5 см", "25.9 см", "26.3 см", "26.7 см", "27.1 см", "27.6 см", "28.0 см", "28.4 см", "28.8 см", "29.3 см", "29.7 см", "30.1 см", "30.5 см", "31.0 см", "31.4 см", "31.8 см", "32.6 см", "33.5 см", "34.3 см", "35.2 см"],
                            CellsClasses: "",
                        },
                    ],
                    centimeter: [
                        {
                            tableCellData: [this.$t("sizeTable.shoes.table.body.centimeter.subtitle1"), "35.5 KZ", "36 KZ", "36.5 KZ", "37 KZ", "37.5 KZ", "38 KZ", "38.5 KZ", "39 KZ", "40 KZ", "40.5 KZ", "41 KZ", "42 KZ", "42.5 KZ", "43 KZ", "44 KZ", "44.5 KZ", "45 KZ", "46 KZ", "46.5 KZ", "47 KZ", "48 KZ", "49 KZ", "49.5 KZ", "50 KZ", "51 KZ", "52 KZ", "53 KZ", "54 KZ"],
                            CellsClasses: "bold",
                        },
                        {
                            tableCellData: [this.$t("sizeTable.shoes.table.body.centimeter.subtitle5"), "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "13.5", "14", "14.5", "15", "15.5", "16", "17", "18"],
                            CellsClasses: "bold",
                        },
                        {
                            tableCellData: [this.$t("sizeTable.shoes.table.body.centimeter.subtitle2"), "36", "36 2/3", "37 1/3", "38", "38 2/3", "39 1/3", "40", "40 2/3", "41 1/3", "42", "42 2/3", "43 1/3", "44", "44 2/3", "45 1/3", "46", "46 2/3", "47 1/3", "48", "48 2/3", "49 1/3", "50", "50 2/3", "51 1/3", "52", "52 2/3", "53 1/3", "54 2/3"],
                            CellsClasses: "",
                        },
                        {
                            tableCellData: [this.$t("sizeTable.shoes.table.body.centimeter.subtitle3"), "--", "--", "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "13.5", "14", "14.5", "15", "15.5", "16", "16.5", "17", "17.5"],
                            CellsClasses: "",
                        },
                        {
                            tableCellData: [this.$t("sizeTable.shoes.table.body.centimeter.subtitle4"), "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "13.5", "14", "14.5", "15", "15.5", "--", "--", "--", "--", "--", "--"],
                            CellsClasses: "",
                        },
                    ]
                }
            }
        };
    },
    methods: {},
    computed: {
        tableHeadData() {
            return this.tableData.head.centimeter;
        },
        tableHeadDataInch() {
            return this.tableData.head.inch;
        },
        tableBodyData() {
            return this.tableData.body.centimeter;
        },
        tableBodyDataInch() {
            return this.tableData.body.inch;
        },
        isMobile() {
            return this.$breakpoint.mobileDown;
        },
        windowWidth() {
            return document.documentElement.clientWidth;
        },
        isTable() {
            return this.$breakpoint.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

};
