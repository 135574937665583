//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import ResponsiveVideo from "@/components/common/ResponsiveVideo";
import CommonMedia from "@/components/common/CommonMedia";
import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";
import CommonCountdown from "~/components/common/CommonCountdown.vue";
export default {
    name: "CountdownTimerMain",
    components: {
        CommonCountdown,
        CommonMedia,
        ResponsiveVideo,
        ResponsiveImage
    },
    data() {
        return {
            dateNow: 0,
        }
    },

    mixins: [widgetLayoutMixin],

    computed: {
        timerTime() {
            let time = _get(this, 'innerData.countdownTime', 0)
            return time > 0 ? time*1000 : 0
        }
    },
    methods: {
        promotionViewed(isVisible, entry) {
            let creative = _get(this, "innerData.title", "");
            creative = creative !== "" ? creative : _get(this, "innerData.subtitle", "");
            creative = creative !== "" ? creative : _get(this, "innerData.media.title", "");
            isVisible && this.$ga.viewPromotion([
                {
                    name: this.widgetInfo.name,
                    id: this.widgetInfo.id,
                    creative: creative,
                    position: 1
                }
            ]);
        },
        promotionClicked(title) {
            let creative = title && title !== "" ? title : _get(this, "innerData.title", "");
            creative = creative !== "" ? creative : _get(this, "innerData.subtitle", "");
            creative = creative !== "" ? creative : _get(this, "innerData.media.title", "");
            this.$ga.clickPromotion([
                {
                    name: this.widgetInfo.name,
                    id: this.widgetInfo.id,
                    creative: creative,
                    position: 1
                }
            ]);
        },
        onMouseUp(e, title) {
            if (e.button === 1) {
                this.promotionClicked(title);
            }
        },
    },
    mounted() {
        this.dateNow = Date.now()
        setInterval(function (){
            this.dateNow = Date.now()
        }.bind(this), 1000)
    }
};
