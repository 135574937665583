export  default  new Vue({
    computed: {
        /////////// mobileSmall //////////////
        mobileSmall() {
            return this.$mq === "mobileSmall" && !this.mobile
        },
        mobileSmallUp() {
            return this.$mq === "mobileSmall" || this.mobileUp
        },

        /////////// mobile //////////////
        mobile() {
            return this.$mq === "mobile" && !this.tabletSmall
        },
        mobileDown() {
            return this.mobile || this.mobileSmall
        },
        mobileUp() {
            return this.$mq === "mobile" || this.tabletSmallUp
        },

        /////////// tabletSmall //////////////
        tabletSmall() {
            return this.$mq === "tabletSmall" && !this.tablet
        },
        tabletSmallDown() {
            return this.tabletSmall || this.mobileDown
        },
        tabletSmallUp() {
            return this.$mq === "tabletSmall" || this.tabletUp
        },

        /////////// tablet //////////////
        tablet() {
            return this.$mq === "tablet" && !this.tabletLandscape
        },
        tabletDown() {
            return this.tablet || this.tabletSmallDown
        },
        tabletUp() {
            return this.$mq === "tablet" || this.tabletLandscapeUp
        },

        /////////// tabletLandscape //////////////
        tabletLandscape() {
            return this.$mq === "tabletLandscape" && !this.laptopSmall
        },
        tabletLandscapeDown() {
            return this.tabletLandscape || this.tabletDown
        },
        tabletLandscapeUp() {
            return this.$mq === "tabletLandscape"  || this.laptopSmallUp
        },

        /////////// laptopSmall //////////////
        laptopSmall() {
            return this.$mq === "laptopSmall" && !this.laptop
        },
        laptopSmallDown() {
            return this.laptopSmallUp || this.tabletLandscapeDown
        },
        laptopSmallUp() {
            return this.$mq === "laptopSmall" || this.laptopUp
        },

        /////////// laptop //////////////
        laptop() {
            return this.$mq === "laptop" && !this.desktop
        },
        laptopDown() {
            return this.laptop || this.laptopSmallDown
        },
        laptopUp() {
            return this.$mq === "laptop" || this.desktopUp
        },

        /////////// desktop //////////////
        desktop() {
            return this.$mq === "desktop" && !this.desktopBig
        },
        desktopDown() {
            return this.desktop|| this.laptopDown
        },
        desktopUp() {
            return this.$mq === "desktop" || this.desktopBig
        },


        /////////// desktopBig //////////////
        desktopBig() {
            return this.$mq === "desktopBig"
        },

        ////////// isMacOs /////////////////
        isMacOs() {
            return window.navigator.userAgent.indexOf("Macintosh") !== -1;
        }
    }
})
