//
//
//
//
//
//
//
//
//
//
//
//
//

import icons from "~/components/common/buttons/components/icons";

export default {
    name: "ShowHideBtn",
    props: {
        close: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ''
        },

    },
    computed: {
        icon() {
            return this.close ? icons['eyeClose'] : icons['eye']
        }
    },
    methods: {
        click() {
            this.$emit('click');
        }
    }
}
