import Vue from "vue";
import VueGtag from "vue-gtag"

const gtagObjName = 'dataLayer'
export default function ({app, store, route, $cookies, gtag}, inject) {
    inject('ga', new Vue({
        data: function () {
            return {
                productsViewedOnScroll: [],
                sendScrolledProductsTimeout: null,
                startSendProductsFromIndex: 0,
                userIdSetted: false,
                sentProductViewedEventProductIds: []
            }
        },
        computed: {},
        methods: {
            setUserId(id) {
                if (!id || id === '') {
                    return
                }
                !this.userIdSetted && this.$gtag.config({
                    'GA_MEASUREMENT_ID': {
                        'user_id': id
                    }
                });
                this.userIdSetted = true
            },
            getListName(listName = '', productId = null, del = false) {
                if (productId) {
                    const getCookieList = () => {
                        const list = $cookies.get(`ListName_${productId}`);
                        del && $cookies.remove(`ListName_${productId}`);
                        return list;
                    }

                    if (document.referrer)
                        if (window.location.hostname !== new URL(document.referrer).hostname)
                            return "external_referal";
                        else
                            return getCookieList() || "page_reload";
                    else
                        return getCookieList() || "direct";
                }
                const href = $nuxt.$route.path;
                return href.indexOf("cart") !== -1
                    ? 'Cart'
                    : href.indexOf("favorites") !== -1
                        ? "wishlist"
                        : (listName && listName !== '') ?
                            listName
                            : (href).substr(1).replace('/', '_')
            },
            onProductInListClick(productId, position, listName) {
                $cookies.set(`ListName_${productId}`, this.getListName(listName))
                const date = new Date();
                date.setTime(date.getTime() + (86400000));
                $cookies.set(`ItemPosition_${productId}`, position, {
                    expires: date
                });

            },

            mapProductsInfoAsImpressions(products) {
                return products.map((el) => {
                    return Object.assign({}, el, {
                        category: el.category.join('/'),
                        list: this.getListName(el.list)
                    })
                })
            },
            mapProductsInfoAsItems(products) {
                return products.map((el) => {
                    let stepInfo = el.step ? {
                        step: el.step,
                        option: el.option
                    } : {};
                    return Object.assign({
                        'item_name': el.name,
                        'item_id': el.id,
                        'price': el.price,
                        'item_brand': el.brand,
                        'item_variant': el.variant,
                        'item_list_name': this.getListName(el.list),
                        'index': el.position || 1,
                        'quantity': _get(el, 'quantity', 1)

                    }, el.category.reduce((acc, c, i) => {
                        acc[`item_category${i > 0 ? `_${i}` : ''}`] = c;
                        return acc;
                    }, {}), stepInfo)
                })
            },
            sendViewedOnScrollProduct(product) {
                this.productsViewedOnScroll.push(product)
                this.sendViewedProducts()
            },
            send() {
                if (!process.browser) {
                    return
                }
                window[gtagObjName] && window[gtagObjName].push(...arguments)
            },
            sendViewedProducts() {
                this.sendScrolledProductsTimeout && clearTimeout(this.sendScrolledProductsTimeout)
                this.sendScrolledProductsTimeout = setTimeout(() => {
                    const productsToSend = this.productsViewedOnScroll.slice(this.startSendProductsFromIndex);
                    if (!productsToSend.length > 0) {
                        return
                    }
                    this.send({
                        "event": "view_item_list",
                        "ecommerce": {
                            "impressions": this.mapProductsInfoAsImpressions(productsToSend),
                            "items": this.mapProductsInfoAsItems(productsToSend)
                        },
                    })
                    this.startSendProductsFromIndex += productsToSend.length
                }, 100)
            },
            sendProductClick(info) {
                info.list = this.getListName(info.list)
                this.send(this.createProductClickOrOpenObj([info], 'select_item', 'click', info.list))
            },
            sendProductPageOpen(info) {
                if (!process.browser || this.sentProductViewedEventProductIds.includes(info.id)) {
                    return
                }
                const list = this.getListName('', info.id);
                info.list = list
                info.position = $cookies.get(`ItemPosition_${info.id}`)
                this.send(this.createProductClickOrOpenObj([info], 'view_item', 'detail', {list}))
                this.sentProductViewedEventProductIds.push(info.id)
            },
            productAddedToCart(info) {
                const list = this.getListName('', info.id, true);
                info.list = list
                const objToSend = this.createProductCartActions([info], 'addToCart', 'add', {list});
                objToSend.ecommerce.currencyCode = 'KZT';
                this.send(objToSend)
            },
            productDeletedFromCart(info) {
                this.send(this.createProductCartActions([info], 'remove_from_cart', 'remove'))
            },
            createProductClickOrOpenObj(products, eventName, ecommercePropName, actionField = {'list': this.getListName()}) {
                return {
                    "event": eventName,
                    "ecommerce": {
                        [ecommercePropName]: {
                            'actionField': actionField,
                            'products': products.map(info => ({
                                name: info.name,
                                id: info.id,
                                price: info.price,
                                brand: info.brand,
                                category: info.category,
                                variant: info.variant,
                                position: info.position || 1,
                                list: this.getListName(info.list),
                            }))
                        },
                        "items": this.mapProductsInfoAsItems(products)
                    },
                }
            },

            createProductCartActions(products, eventName, ecommercePropName, actionField = {'list': this.getListName()}) {
                return {
                    "event": eventName,
                    "ecommerce": {
                        [ecommercePropName]: {
                            'products': products.map(info => ({
                                name: info.name,
                                id: info.id,
                                price: info.price,
                                brand: info.brand,
                                category: info.category[0],
                                variant: info.variant,
                                quantity: info.quantity || 1,
                            })
                            )
                        },
                        "items": this.mapProductsInfoAsItems(products)
                    },
                }
            },

            sendCheckoutStep(stepInfo, products, listName = '', eventName = 'checkout') {
                const obj = this.createProductClickOrOpenObj(products.map((info, index) => ({
                    name: info.product.displayName,
                    id: info.product.id,
                    price: info.product.price.sale,
                    brand: info.product.division,
                    category: info.product.productPath || [],
                    variant: 'adidas',
                    position: index + 1,
                    quantity: info.quantity,
                    list: listName,
                    step: stepInfo.step,
                    option: stepInfo.option,
                })), eventName, 'checkout', stepInfo);
                obj.step = stepInfo.step;
                obj.option = stepInfo.option;
                this.send(obj)
            },
            sendPurchase(purchaseInfo, positions) {
                let products = positions.map((info, index) => {
                    const promocodes = _get(info, 'promoCode', [])
                    return {
                        name: info.product.displayName,
                        id: info.product.id,
                        price: info.product.price.sale,
                        brand: info.product.division,
                        category: info.product.productPath || [],
                        variant: 'adidas',
                        position: index + 1,
                        quantity: info.quantity,
                        coupon: (Array.isArray(promocodes) ? promocodes : []).map(el => el.code).join(', ')
                    }
                });
                this.send({
                    "event": 'purchase',
                    "ecommerce": {
                        'purchase': {
                            'actionField': {
                                'id': purchaseInfo.id,
                                'revenue': purchaseInfo.revenue,
                                'coupon': purchaseInfo.coupon
                            },
                            'products': products.map(info => ({
                                name: info.name,
                                id: info.id,
                                price: info.price,
                                brand: info.brand,
                                category: info.category,
                                variant: info.variant,
                                quantity: _get(info, 'quantity', 1),
                                coupon: _get(info, 'coupon', ''),
                            })),
                            'transaction_id': purchaseInfo.id,
                            'affiliation': 'Online Store',
                            'value': purchaseInfo.revenue,
                            'tax': '',
                            'shipping': purchaseInfo.commission,
                            'currency': 'KZT',
                            'coupon': purchaseInfo.coupon,
                            "items": products.map((el) => {
                                return Object.assign({
                                    'item_name': el.name,
                                    'item_id': el.id,
                                    'price': el.price,
                                    'item_brand': el.brand,
                                    'item_variant': el.variant,
                                    'quantity': _get(el, 'quantity', 1),
                                    'item_coupon': _get(el, 'coupon', '')

                                }, el.category.reduce((acc, c, i) => {
                                    acc[`item_category${i > 0 ? `_${i}` : ''}`] = c;
                                    return acc;
                                }, {}))
                            })
                        }
                    },
                })
            },
            viewPromotion(promotionInfo) {
                this.send({
                    'event': 'view_promotion',
                    'ecommerce': {
                        'promoView': {
                            'promotions': promotionInfo.map((el, index) => ({
                                id: el.id,
                                name: el.name,
                                creative: el.creative,
                                position: _get(el, 'position', index + 1)
                            }))
                        },
                        'items': promotionInfo.map((el, index) => ({
                            'promotion_id': el.id,
                            'promotion_name': el.name,
                            'creative_name': el.creative,
                            'creative_slot': _get(el, 'position', index + 1),
                            'index': _get(el, 'position', index + 1),
                            'quantity': '1'
                        }))
                    }
                })
            },
            clickPromotion(promotionInfo) {
                this.send({
                    'event': 'select_promotion',
                    'ecommerce': {
                        'promoClick': {
                            'promotions': promotionInfo.map((el, index) => ({
                                id: el.id,
                                name: el.name,
                                creative: el.creative,
                                position: `${el.name} || ${_get(el, 'position', index + 1)}`
                            }))
                        },
                        'items': promotionInfo.map((el, index) => ({
                            'promotion_id': el.id,
                            'promotion_name': el.name,
                            'creative_name': el.creative,
                            'creative_slot': _get(el, 'position', index + 1),
                            'index': _get(el, 'position', index + 1),
                            'quantity': '1'
                        }))
                    }
                })
            },
            sendSimpleEvent(event, type) {
                const sendObj = {
                    event
                }
                type && (sendObj['type'] = type)
                this.send(sendObj)
            },

        }
    }))

}
