export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
        required: {
            type: Boolean,
            required: true,
        },

    },
    data: function () {
        return {
            val: JSON.parse(JSON.stringify(this.field.selectedValue === undefined ? null : this.field.selectedValue)),
        }
    },
    computed: {
        filled: function (){
            return _get(this, 'field.state.filled', false)
        },
        listItems:function () {
            return _get(this, 'field.data.items', [])
        },
        hasListItems:function () {
            return this.listItems.length > 0
        },
        dataLoading:function () {
            return this.field.data.loading
        },
        fieldIsDisabled: function () {
            const state = _get(this.field, 'state', {});
            return state.hasOwnProperty('manualChange') &&  (!state.manualChange || !state.autoChange)
        },
        maxLength: function () {
            return _get(this, 'field.view.inputLength.max', null)
        },
        setAutoFocus: function () {
            return _get(this, 'field.view.autofocus', false)
        },
        showAddition: function () {
            return _get(this, 'field.view.showAdditionLabel', true)
        },
    },
    watch:{
        'val':{
            handler(newVal){
                this.$emit('valueChange', newVal)
            }
        },
        'field.selectedValue':{
            deep: true,
            handler(newVal, oldVal){
                if(JSON.stringify(newVal === undefined ? null : newVal) !== JSON.stringify(this.val === undefined ? null : this.val)) {
                    this.val = JSON.parse(JSON.stringify(newVal === undefined ? null : newVal));
                }
            }
        }
    },
    methods: {
        updateVal(value){
            this.val = value
        },
        onFocus() {
            this.$emit('focus')
        },
        onBlur() {
            this.$emit('blur');
        }
    },
}
