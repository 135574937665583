//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SizeTable from "@/components/popups/SizeTable/Index.vue";
import { mapGetters } from "vuex";
import WaitListSubscribe from "@/components/popups/WaitList/subscribe";
import bellIcon from "@/assets/img/icons/bell-icon.svg";

export default {
    name: "ProductSizes",
    data() {
        return {
            completedSizeTableId: ["size-k_shoes", "size-ki_shoes", "size-kg_5-15y", "size-kb_5-16y", "size-ki_1-4y", "size-shoes",
                "size_tops_woman", "size_tops_man", "size_swimming_man", "size_bottoms_man", "size_swimming_woman", "size_bottoms_woman",
                "size_tops_woman_dz", "size_tops_woman_nc", "size-97", "size-adult-slides", "size-football_boots_adults"],
            bellIcon: bellIcon,
            subscribeUnavailableSizesId: "waitlist_form"
        };
    },

    props: {
        product: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            chosenSize: "productView/getChosenSize",
        }),
        unavailableSizes() {
            return this.product?.sizes?.filter(size => !size.isAvailable);
        },
        sizeAlarm() {
            return this.chosenSize.alarm;
        },
        showAlarm() {
            return this.sizeAlarm && (this.$breakpoint.desktop || this.$breakpoint.laptop);
        },
        isButtonBuy() {
            return this.showAlarm && this.chosenSize.buy;
        },
        productHasSizes() {
            return _get(this, "product.sizes.length", 0) > 0;
        },
        showAnimation() {
            return this.sizeAlarm && this.isButtonBuy;
        },
        showSizeTableButton() {
            return this.completedSizeTableId.includes(this.product.sizeChart);
        },
        availableSizes() {
            return this.product?.sizes?.filter(size => size.hasOwnProperty('isAvailable') && size.isAvailable);
        },
        maxLabelLength() {
            let largest = "";
            for (let i = 0; i < this.product.sizes.length; i++) {
                if (this.product.sizes[i]['title'].length > largest.length) {
                    largest = this.product.sizes[i]['title'];
                }
            }
            return largest.length;
        },
        gridClassName() {
            if (this.maxLabelLength > 18) {
                return 'col-1';
            } else if (this.maxLabelLength > 12) {
                return 'col-2';
            } else if (this.maxLabelLength > 7) {
                return 'col-3';
            } else {
                return 'col-4';
            }
        },
    },

    methods: {
        openSizeTable() {
            this.$popups.open({
                component: SizeTable,
                props: {
                    sizeChart: this.product.sizeChart
                }
            });
        },
        choseSize($event, size) {
            if ($event.target?.classList.contains("disabled")) {
                return;
            }
            if (size.hasOwnProperty("isAvailable") && size.isAvailable === false) {
                this.renderSubscribeSizePopup($event, size);
                return;
            }
            if (_get(size, "balance", 0) <= 5) {
                size.alarm = this.$t("productCard.onWarehouse") + ": " + size.balance;
            } else {
                size.alarm = "";
            }
            this.$store.commit("productView/SET_CHOSEN_SIZE", size);
        },

        renderSubscribeSizePopup($event, size) {
            if ($event.target) {
                $event.target.classList.add("disabled");
            }
            this.$store.dispatch(`forms/loadFormByIdWithData`, {
                formId: this.subscribeUnavailableSizesId
            }).then(() => {
                this.$emit("formLoaded");
                $event.target.classList.remove("disabled");
                this.$popups.open({
                    component: WaitListSubscribe,
                    props: {
                        unavailableSizes: this.unavailableSizes,
                        selectedPropSize: size,
                        formId: this.subscribeUnavailableSizesId
                    }
                });
            });
        }
    },

    mounted() {
        this.$store.commit("productView/SET_CHOSEN_SIZE", {});
        if (this.availableSizes && this.availableSizes.length === 1) {
            this.choseSize(false,this.availableSizes[0]);
        }
    }
};
