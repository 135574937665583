export default  {
    data: function () {
        return {
            fieldTypesWithoutLabel: ['radio', 'checkbox', 'hidden', 'date']
        }
    },
    computed:{
        showInfoBlock: function () {
            return this.hasDescription ||
                    (this.showError && this.showErrorText) ||
                    this.hasPermanentErrorMessage
        },
        fieldType() {
            return  _get(this, 'row.view.fieldType', this.defaultFieldName);
        },
        formFieldType() {
            return (this.formTypeNameToComponentMap.hasOwnProperty(this.fieldType) ? this.formTypeNameToComponentMap[this.fieldType] : this.defaultFieldName);
        },
        canBeValidatedOnStart () {
            return !['checkbox'].includes(this.fieldType.toLowerCase())
        },
        valueProp() {
            return this.row.hasOwnProperty('valueProp') ? this.row.valueProp : 'id'
        },
        halfShow() {
            return this.row.hasOwnProperty('halfShow') && this.row.halfShow
        },
        isHidden() {
            return this.formFieldType === this.hiddenType
        },
        showRow: function () {
            return !this.isHidden && this.row &&  (!this.isDepended || (this.isDepended && this.parentFieldsFilledRight));
        },
        _showRow: function () {
            return this.showRow
        },
        activeState: function () {
            return this.focused || this.filled
        },
        showLabel: function () {
            return this.hasLabel && !this.fieldTypesWithoutLabel.includes(this.fieldType.toLocaleLowerCase())
        },
        rowIsDisabled: function () {
            return !this.manualChange || !this.autoChange
        },
        showErrorUi() {
            return this.showError && !this.fieldTypesWithoutLabel.includes(this.fieldType.toLocaleLowerCase())
        },
        showSuccessUi() {
            return this.showSuccess && !this.fieldTypesWithoutLabel.includes(this.fieldType.toLocaleLowerCase())
        },
        validationStateIconCanBeShowed(){
            return !this.rowIsDisabled
        },
        isDate(){
            return this.fieldType.toLocaleLowerCase() === 'date'
        },
        permanentErrorMessage(){
            return _get(this, 'row.error.permanentText', '')
        },
        hasPermanentErrorMessage (){
            return this.permanentErrorMessage !== ''
        }
    },
    watch:{
        'showRow':{
            handler: function (newVal, oldVal) {
                if (!oldVal && newVal) {
                    this.filled && this.validate(true);
                    this.loadData();
                }
            }
        }
    },
}
