export default {
    title: 'Вход в профиль',
    titleForm: 'Войти',
    titleCreate: 'Создать аккаунт',
    forgotPass: 'Забыл пароль?',
    formRowEmail: {
        label: 'Email',
        errorRequired: 'Поле Email должно быть заполнено',
        errorEmail: 'Введенный Email имеет неправильный формат'
    },
    formRowPass: {
        label: 'Пароль',
        errorRequired: 'Поле Пароль должно быть заполнено',
        errorLength: 'Длина поля Пароль должна быть больше 7 символов',
        info: 'Пароль должен быть больше 8 знаков'
    },
    formText: 'Нажимая кнопку  «ВОЙТИ», я даю ТОО «АДИДАС» свое <a href="https://media.adidas.kz/personal_data_accept.pdf" target="_blank">согласие на обработку персональных данных и получение информационных рассылок</a> и соглашаюсь с <a href="https://media.adidas.kz/personal_data_policy.pdf" target="_blank">Политикой по обработке персональных данных</a>',
    confidencePolitic: 'Политикой конфиденциальности adidas',
    or: 'или',
    createText: {
        top: 'Присоединяйся к программе лояльности  ADIDAS UNIVERSE:',
        b1: 'Собирай баллы и получай скидку до 20%, которой ты можешь воспользоваться как на сайте, так и в розничных магазина и дисконт-центрах',
        b2: 'Получай специальные условия и дополнительные бонусы во время акций',
        b3: 'Первым узнавай о специальных предложениях и сезонных распродажах',
        b4: 'Присоединяйся к ADIDAS UNIVERSE прямо сейчас и начинай накапливать баллы и увеличивать свою персональную скидку. Открой для себя лучшее от adidas.',
        bottom2: 'Хочешь узнать больше об ADIDAS UNIVERSE?',
    },
    buttonLogin: 'Войти',
    buttonRegistry: 'Регистрация',
    buttonPhone: 'Телефон',
    buttonFb: 'Facebook',
    buttonGoogle: 'Google',
    popupPhone: {
        title: 'Вход по мобильному номеру',
        inputPlaceholderPhone: 'Мобильный телефон',
        inputPlaceholderCode: 'Код подтверждения',
        codeSendToNumber: 'Код подтверждения отправлен на номер',
        buttonSend: 'Отправить код',
        buttonConfirm: 'Подтвердить',
        buttonBreak: 'Отменить',
        resend: 'Отправить еще раз',
        errorRequired: 'Поле должно быть заполнено',
        errorPhone: 'Телефон введен неверно',
        errorCode: 'Неправильный код подтверждения',
        errorCodeLength: 'Длина кода подтверждения должна составлять {length} символы(ов)'
    },
    popupForgotPass: {
        title: 'Забыл пароль',
        subtitle: 'Укажи свой email, и мы отправим на него ссылку для восстановления пароля.',
        textBold: 'Ссылка для восстановления пароля отправлена',
        textEmail: 'Проверь E-mail',
        buttonClearPass: 'Сбросить пароль',
        buttonClose: 'Закрыть'
    },

    newPassword: {
        title: 'Создай новый пароль',
        subtitle: 'Введи пароль. После изменения пароля ты останешься в системе.'
    }
}
