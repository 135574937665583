//
//
//
//
//
//
//
//
//
//
//
//
//


import ProductAddedToCart from "@/components/popups/ProductAddedToCart/index";
import {mapGetters} from "vuex";
import ProductBuyError from "../ProductBuyError";
import addToWishlist from '~/assets/js/eSputnik/addToWishlistEvent';

export default {
    name: "ProductBuyFavorite",
    components: {ProductBuyError},
    props: {
        product: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            toCartStatus: 0,
            buyErrorMessage: ''
        }
    },
    computed: {
        ...mapGetters({
            chosenSize: 'productView/getChosenSize',
            // toCartStatus: 'cart/getToCartStatus',
            isFavorite: 'favorite/isProductFavorite',
        }),

        textBtn() {
            switch (this.toCartStatus) {
                case 2:
                    return this.$t('productCard.added')
                case 1:
                    return this.$t('productCard.addLoading')
                default:
                    return this.$t('productCard.addToCart')
            }
        },
        addingProduct() {
            return this.toCartStatus === 1;
        },
        addedProduct() {
            return this.toCartStatus === 2;
        },
        isProductFavorite() {
            return this.isFavorite(this.product.id);
        },
        productHasSizes() {
            return _get(this, 'product.sizes.length', 0) > 0
        },
        isDesktop() {
            return this.$breakpoint.desktop || this.$breakpoint.laptop;
        },
        locale() {
            return this.$i18n.locale === 'ru' ? 'ru' : 'kz'
        },
    },
    methods: {
        buy() {
            if (this.chosenSize.id) {
                this.toCartStatus = 1;
                this.$store.dispatch('cart/addToCart', this.chosenSize.id).then((response) => {
                    this.toCartStatus = 2;
                    this.$store.commit(`cart/${types.SET_LAST_ADDED}`, response.data.lastAddedPosition[0]);
                    this.$store.commit(`cart/${types.SET_CART_TOTAL}`, response.data.totalPrices);
                    this.$store.commit(`cart/${types.SET_PREORDER_ID}`, response.data.id);
                    this.$store.commit(`cart/${types.UPDATE_CART_TOTAL}`, response.data.positionsCount);
                    this.$gtag('event', 'add_to_cart', {
                        value: this.product.price.sale,
                        locale: this.locale,
                        items: [{
                            id: response.data.lastAddedPosition[0].tradeOfferId,
                            google_business_vertical: 'retail'
                        }]
                    });
                    this.$ga.productAddedToCart({
                        name: this.product.displayName,
                        id: this.product.id,
                        price: this.product.price.sale,
                        brand: this.product.division,
                        category: this.product.productPath || [],
                        variant: 'adidas',
                        position: 1
                    });
                    this.$criteo.addToCart(this.product.id, this.product.price.sale, 1)
                    window.fbq && window.fbq('track', 'AddToCart',{
                        content_type: 'product',
                        value: this.product.price.sale,
                        currency: 'KZT',
                        content_ids: [this.product.id],
                    });
                })
                .catch((e) => {
                    if (_get(e, 'response.status', null) === 422) {
                        this.buyErrorMessage = _get(e, 'response.data.error', '')
                    }
                })
                .finally(()=> {
                    this.toCartStatus = 0;
                })
            } else {
                if(this.isDesktop) {
                    this.$store.commit('productView/SET_CHOSEN_SIZE', {});
                    setTimeout(() => {
                        this.$store.commit('productView/SET_CHOSEN_SIZE', {
                            alarm: this.$t('productCard.chooseYourSize'),
                            buy: true
                        });
                    }, 0)
                }else {
                    this.$root.$emit("showSizePopup");
                }
            }
        },
        async toggleFavorite() {
            if (this.isProductFavorite) {
                await this.$store.dispatch('favorite/removeFromFavorite', this.product.id)
            } else {
                await this.$store.dispatch('favorite/addToFavorite', this.product.id);
                addToWishlist(this.product);

                this.$gtag('event', 'add_to_wishlist', {
                    value: this.product.price.sale,
                    locale: this.locale,
                    items: this.product.sizes.map(i => {
                        return {
                            id: i.id,
                            google_business_vertical: 'retail'
                        }
                    })
                });
                this.$ga.sendSimpleEvent('add_to_wishlist');
                setTimeout(()=> {
                    window.fbq && window.fbq('track', 'AddToWishlist',{
                        content_type: 'product',
                        value: this.product.price.sale,
                        currency: 'KZT',
                        content_ids: [this.product.id],
                    });
                })
            }
        },
    },
    watch: {
        toCartStatus(val) {
            if (val === 2) {
                this.$popups.close();
                this.$popups.open({
                    component: ProductAddedToCart,
                    props: {
                        product: this.product
                    },
                })
            }
        },
        'chosenSize.id': {
            handler (newVal, oldVal){
                if(newVal !== oldVal) {
                    this.buyErrorMessage = ''
                }
            }

        }
    }
}
