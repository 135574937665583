//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "ProductColors",
    components: {
        ResponsiveImage: () => import("@/components/common/ResponsiveImage")
    },
    props: {
        product: {
            type: Object,
            default: ()=>{}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        productColors(){
            return _get(this, 'product.colors', [])
        },
        showProductColors() {
            return this.productColors.length >= 1;
        }
    },
    methods: {
        imageUrl(image) {
            return ImageHelper.getMediaStructure(
                image.id,
                AppConfig.image.fileExtensions.productCard,
                _get(image, 'title', ''),
                _get(image, 'alt', ''),
                ImageSizes.cardThumbnail, 70)
        }
    }
}
