export default {
    title: 'Тіркеу',
    inputPhone: 'Сайтта тіркелу үшін, телефон нөміріңді раста',
    codeToPhone: 'Растау коды нөмірге жіберілді',
    confirmedPhone: {
        number: 'Нөмір',
        success: 'сәтті',
        confirmed: 'расталды'
    },
    inputAnotherNumber: 'Басқа телефон нөмірін енгізу',
    fieldsTitle: {
        personalData: 'Дербес деректер',
        birthDate: 'Туған күні',
        gender: 'Жынысы',
        loginData: 'Аккаунтқа кіруге арналған деректер'
    },
    more14Years: 'Иә, менің жасым 14-тен асқан',
    goCaption: {
        text: 'Мен «ЖАЛҒАСТЫРУ» түймесін басқанда «АДИДАС» ЖШС-ға өз <a href="https://media.adidas.kz/personal_data_accept_kz.pdf" target="_blank">дербес деректерді өңдеуге және ақпараттық таратпалар алуға келісімімді беремін</a> және <a href="https://media.adidas.kz/personal_data_policy_kz.pdf" target="_blank">Дербес деректерді өңдеу жөніндегі саясатпен</a> келісемін',
        text01: 'Мен «ЖАЛҒАСТЫРУ» түймесін басқанда',
        text02: 'Creators Club Бейілділік бағдарламасының ережелерімен келісемін',
        text03: 'және менің дербес деректерімді өңдеуге мынаған сәйкес келісімімді беремін',
        text04: 'adidas құпиялылық саясаты.'
    },
    buttons: {
        sendCode: 'Кодты жіберу',
        confirm: 'Растау',
        go: 'Жалғастыру'
    },
    resend:'Қайтадан жіберу',
    enterNewPhone: 'Басқа телефон нөмірін жазу',
    moreAdidas: {
        text01: 'ADIDAS UNIVERSE жайлы көбірек білгің келе ме?',
        text02: 'Бейілділік бағдарламасы жайлы әрі қарай оқы'
    },
    passDescription: 'Құпиясөз кем дегенде {length} белгіден тұруы тиіс'
}
