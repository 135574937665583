
import icons from "./components/icons";
import common from "./mixin/common";

export default {
    name: "MainButton",
    mixins: [common],
    props: {
        // Set button color style (relevant for Main type): dark | light
        colorStyle: {
            type: String,
            default: 'dark'
        },
        hexColors: {
            type: Object,
            default: () => { return {} }
        },

        // Set button to loading mode
        loading: {
            type: Boolean,
            default: false
        },
        loaded: {
            type: Boolean,
            default: false
        },
    },
    render: function (createElement, {data, props, children, listeners}) {
        const isLink = props.to && props.to.trim();
        const wrapperTag = isLink ? 'nuxt-link' : 'button';

        let style = {
            background: '#000000',
            color: '#ffffff',
        };
        let colorStyle = props.colorStyle
        if (props.hexColors.background && props.hexColors.color) {
            style = props.hexColors;
            colorStyle = 'hex';
        } else if (props.colorStyle === 'light') {
            style = {
                background: '#ffffff',
                color: '#000000',
            }
        }

        const iconElement = createElement('div', {
            domProps: {innerHTML: icons['longArrowRight']},
            class: 'button-main__icon',
            style: {fill: style.color}
        });
        const loadedElement = createElement('div', {
            domProps: {innerHTML: icons['check']},
            class: 'button-main__loaded',
            style: {stroke: style.color}
        });
        const loaderElement = createElement('div', {
            domProps: {innerHTML: icons['loader']},
            class: 'button-main__loader',
            style: {fill: style.color}
        });
        const textElement = createElement('div', {class: 'button-main__text'}, children);
        const button = createElement(wrapperTag, {
            class: [
                'button-main__btn',
                {'button-main__btn--disabled': props.disabled},
                {'button-main__btn--loading': props.loading},
                {'button-main__btn--loaded': props.loaded},
            ],
            style: style,
            attrs: {
                to: isLink && props.to,
                disabled: !isLink && props.disabled,
                type: props.buttonType,
                'aria-label': props.ariaLabel
            }
        }, [
            textElement,
            props.loaded ? loadedElement : props.loading ? loaderElement : iconElement
        ]);
        return createElement('div', {
            class: [
                'button-main',
                data.staticClass,
                `button-main--style-${colorStyle}`,
            ],
            ...data,
            style: {borderColor: style.background},
        }, [button]);
    }
}
