const requestsList = {};

export const state = () => ({
    list: [],
    info: {},
    cartTotal: {},
    positionsCount: 0,
    notifications: [],
    promocode: {},
    promocodeData: {},
    promoCodes: [],
    orderTags: [],

    toCartStatus: 0, // 0 - notAdd, 1 - loading, 2 - loaded
    lastAdded: {},

    paymentsMethod: [],
    activePaymentId: null,
    activePaymentData: null,
    checkoutMaxStep: 1,

    preOrderId: null,
    preOrderInfo: null,

    preOrderDelivery: {},

    checkoutCustomerAddressId: 'none',
    checkoutCustomerAddressInfo: null,
    checkoutDeliveryType: null,
    checkoutDeliveryName: null,

    orderTracker: {}
});

export const getters = {
    getList: ({ list }) => list,
    getCartTotal: ({ cartTotal }) => cartTotal,
    getPromocode: ({ promocode }) => promocode,
    getPromocodeData: ({ promocodeData }) => promocodeData,
    getPromoCodes: ({ promoCodes }) => promoCodes,
    getOrderTags: ({ orderTags }) => orderTags,
    getNotifications: ({ notifications }) => notifications,
    getCartLength: ({ positionsCount }) => positionsCount,

    getPreOrderId: ({ preOrderId }) => preOrderId,
    getPreOrderInfo: ({ preOrderInfo }) => preOrderInfo,

    getToCartStatus: ({ toCartStatus }) => toCartStatus,
    getOrderTracker: ({ orderTracker }) => orderTracker,
    getLastAdded: ({ lastAdded }) => lastAdded,
    getCartCount: ({ positionsCount }) => positionsCount,
    getCustomerAddressId: ({ preOrderInfo }) => _get(preOrderInfo, 'deliveryAddress.customerAddressId', false),
    getCheckoutCustomerAddressId: ({ checkoutCustomerAddressId }) => checkoutCustomerAddressId,
    getCheckoutCustomerAddressInfo: ({ checkoutCustomerAddressInfo }) => checkoutCustomerAddressInfo,
    getCheckoutDeliveryType: ({ checkoutDeliveryType }) => checkoutDeliveryType,
    getCheckoutDeliveryName: ({ checkoutDeliveryName }) => checkoutDeliveryName,

    getCheckoutMaxStep: ({ checkoutMaxStep }) => checkoutMaxStep,
    getPaymentsMethod: ({ paymentsMethod }) => paymentsMethod,
    getActivePaymentId: ({ activePaymentId }) => activePaymentId,
    getActivePaymentData: ({ activePaymentData }) => activePaymentData,
    isShowApprovePaymentBtn: ({
                                  paymentsMethod,
                                  activePaymentId
                              }) => paymentsMethod.length && activePaymentId ? activePaymentId === 'delivery-service' : false,
};

export const mutations = {
    [types.SET_CART_LIST](state, data) {
        state.list = data;
    },
    [types.SET_CART_INFO](state, data) {
        state.info = data;
    },
    [types.SET_CART_TOTAL](state, data) {
        state.cartTotal = data;
    },
    [types.SET_PROMOCODE_INFO](state, data) {
        state.promocode = data;
    },
    [types.SET_PROMOCODE_DATA](state, data) {
        state.promoCodes = data;
    },
    [types.SET_ORDER_TAGS](state, data) {
        state.orderTags = data;
    },
    [types.SET_ITEM_COUNT](state, params) {
        state.list.find(i => i.id === params.id).count = params.count;
    },
    [types.ADD_NOTIFICATION](state, item) {
        state.notifications.push(item);
    },
    [types.CLEAR_NOTIFICATION](state, onlyMain = false) {
        if (onlyMain) {
            state.notifications = state.notifications.filter(i => i.type !== 'main');
        } else {
            state.notifications = [];
        }
    },
    [types.REMOVE_ITEM](state, id) {
        state.list.splice(state.list.findIndex(i => i.id === id), 1);
    },
    [types.UPDATE_CART_TOTAL](state, data) {
        state.positionsCount = data;
    },


    [types.SET_CART_ADDED_FLAG](state, data) {
        state.toCartStatus = data;
    },
    [types.SET_ORDER_TRACKER](state, data) {
        state.orderTracker = data;
    },
    [types.SET_LAST_ADDED](state, data) {
        state.lastAdded = data;
    },
    [types.SET_PREORDER_ID](state, data) {
        state.preOrderId = data;
    },
    [types.SET_PREORDER_INFO](state, data) {
        state.preOrderInfo = data;
    },

    [types.SET_PAY_METHODS_LIST](state, data) {
        state.paymentsMethod = data;
    },
    [types.SET_CHECKOUT_MAX_STEP](state, data) {
        state.checkoutMaxStep = data;
    },
    [types.SET_ACTIVE_PAY_METHOD](state, data) {
        state.activePaymentId = data;
    },
    [types.SET_PAY_DATA](state, data) {
        state.activePaymentData = data;
    },

    [types.SET_CHECKOUT_CUSTOMER_ADDRESS_ID](state, data) {
        state.checkoutCustomerAddressId = data;
    },
    [types.SET_CHECKOUT_CUSTOMER_ADDRESS_INFO](state, data) {
        state.checkoutCustomerAddressInfo = data;
    },
    [types.SET_CHECKOUT_DELIVERY_TYPE](state, data) {
        state.checkoutDeliveryType = data;
    },
    [types.SET_CHECKOUT_DELIVERY_NAME](state, data) {
        state.checkoutDeliveryName = data;
    },
};

export const actions = {
    async getCart({ commit, dispatch }) {
        requestsList.getCart = this.$axios.CancelToken.source();
        await this.$axios.get(`${ API.main }/order/pre-order`, {
            params: {
                expand: AppConfig.store.loadCartExpand.join(',')
            },
            cancelToken: requestsList.getCart.token
        }).then(function(response) {
            dispatch('updateCartState', response.data);
            commit(types.SET_PREORDER_ID, response.data.id);
        });
    },
    async updateCount({ dispatch }, params) {
        await this.$axios.get(`${ API.main }/order/positions/change-quantity`, {
            params: {
                expand: AppConfig.store.loadCartExpand.join(','),
                tradeOfferId: params.id,
                newQuantity: params.count
            }
        }).then(function(response) {
            dispatch('updateCartState', response.data);
        });
    },
    async removeFromCart({ dispatch }, id) {
        return this.$axios.get(`${ API.main }/order/positions/remove`, {
            params: {
                expand: AppConfig.store.loadCartExpand.join(','),
                tradeOfferId: id,
            }
        }).then(async function(response) {
            dispatch('updateCartState', response.data);
            dispatch('actualizeCart');
            return response.data;
        });

    },

    async addToCart({ commit, dispatch }, id) {
        commit(types.SET_CART_ADDED_FLAG, 1);
        return this.$axios.get(`${ API.main }/order/positions/add`, {
            params: {
                expand: AppConfig.store.addedToCartExpand.join(','),
                tradeOfferId: id
            }
        });
    },

    async updateCartState({ commit, dispatch }, data) {
        commit(types.SET_CART_LIST, data.positions);
        commit(types.SET_CART_TOTAL, data.totalPrices);
        commit(types.UPDATE_CART_TOTAL, data.positionsCount);
        commit(types.SET_PROMOCODE_DATA, data.promoCode);
        commit(types.SET_ORDER_TAGS, data.tags);
    },

    async addToFavorite({ commit, state, dispatch }, data) {
        let notification = {
            title: this.$i18n.t('cart.notification.updateCartTitle.title'),
            text: this.$i18n.t('cart.notification.updateCartTitle.text', { name: data.name })
        };
        commit(types.ADD_NOTIFICATION, notification);
        dispatch('removeFromCart', data.id);
    },
    async addPromocode({ commit, dispatch }, code) {
        await this.$axios.post(`${ API.main }/order/promo-code/add`, {
            "code": code
        }).then(async (response) => {
            await dispatch('actualizeCart', true);
            await dispatch('getCart');
            commit(types.SET_PROMOCODE_INFO, { value: code, valid: true, clearInput: true });
        }).catch(async (err) => {
            commit(types.SET_PROMOCODE_INFO, {
                value: code,
                valid: false,
                error: err.response.data.PromoCodeForm.code[0]
            });
        });
    },
    async removePromocode({ commit, dispatch }, id) {
        await this.$axios.post(`${ API.main }/order/promo-code/remove?id=${ id }`).then(async (response) => {
            await dispatch('actualizeCart', true);
            await dispatch('getCart');
        }).catch(async (err) => {
            commit(types.SET_PROMOCODE_INFO, {
                value: code,
                valid: false,
                error: err.response.data.PromoCodeForm.code[0]
            });
        });
    },

    async loadPaymentMethods({ commit, dispatch }) {
        await this.$axios.get(`${ API.main }/payment/api/type`).then(async function(response) {
            if (response.data?.length) {
                commit(types.SET_PAY_METHODS_LIST, response.data);
                await dispatch('setActivePaymentMethod', {
                    id: response.data[0].id,
                    method: response.data[0].id === 'delivery-service' ? 'pod' : 'card'
                });
            }

        });
    },
    async setActivePaymentMethod({ commit, dispatch }, { id, method }) {
        await this.$axios.post(`${ API.main }/order/payment/save`, {
            "payment_system_id": id,
            "payment_method": method
        }).then((response) => {
            commit(types.SET_ACTIVE_PAY_METHOD, id);
            commit(types.SET_PAY_DATA, response.data);
            dispatch('getPreOrder');
        });
    },

    async getPreOrder({ commit, dispatch }) {
        await this.$axios.get(`${ API.main }/order/pre-order`, {
            params: {
                expand: AppConfig.store.loadOrderConfirmation.join(',')
            }
        }).then(function(response) {
            dispatch('updateCartState', response.data);
            commit(types.SET_PREORDER_INFO, response.data);
            commit(types.SET_PREORDER_ID, response.data.id);
        });
    },

    async actualizeCart({ commit, dispatch }, redirectToCart = false) {
        await this.$axios.get(`${ API.main }/order/actualize`).then(function(response) {
            commit(types.CLEAR_NOTIFICATION, true);
        }).catch(function(error) {
            dispatch('setNotification', { response: error.response, redirectToCart: redirectToCart });
        });
    },
    setNotification({ commit }, { response, redirectToCart }) {
        commit(types.CLEAR_NOTIFICATION, true);
        let cartErrors = _get(response, 'data["cart-page"]', []);
        if (cartErrors.length > 0) {
            cartErrors.forEach(notification => {
                commit(types.ADD_NOTIFICATION, { text: notification, type: 'main' });
            });
            if (redirectToCart) {
                $nuxt.$router.push('/cart');
            }
        }
    },
    clearNotification({ commit }) {
        commit(types.CLEAR_NOTIFICATION);
    }
};
