//
//
//
//
//
//
//
//

import mainLayout from './layouts/Main'
import widgetMixin from "../../mixins/widgetMixin";
export default {
    name: "Subscribe",
    components: {
        mainLayout
    },
    mixins: [widgetMixin],
}
