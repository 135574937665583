//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import HalykInfo from "~/components/common/halykInfo/index.vue";

export default {
    name: "CartInfo",
    components: {HalykInfo},
    props: {
        cart: {
            type: Object,
            required: true,
        }
    },
    computed: {
        ...mapGetters({
            totalPrices: 'cart/getCartTotal',
            cartCount: 'cart/getCartLength',
        }),
        infoRows() {
            return [
                {
                    label: this.$t('productCard.totalProduct'),
                    value: this.cartCount,
                    type: 'info',
                },
                {
                    label: this.$t('productCard.costProduct'),
                    value: this.totalPrices.subtotalLabel,
                    totalRow: true,
                    type: 'info',
                },
                {
                    label: this.$t('productCard.totalSum'),
                    value: this.totalPrices.totalLabel,
                    totalRow: true,
                    type: 'total',
                },
            ]
        },
        isTabletDown() {
            return this.$breakpoint.tabletLandscapeDown;
        }
    },
    methods: {
        closePopup() {
            this.$popups.dismiss()
        }
    }
}
