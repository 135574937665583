export default {
    title: 'Профильге кіру',
    titleForm: 'Кіру',
    titleCreate: 'Аккаунт құру',
    forgotPass: 'Құпиясөзді ұмыттың ба?',
    formRowEmail: {
        label: 'Email',
        errorRequired: 'Email жолы толтырылуы керек',
        errorEmail: 'Енгізілген Email форматы қате'
    },
    formRowPass: {
        label: 'Құпиясөз',
        errorRequired: 'Құпиясөз жолы толтырылуы керек',
        errorLength: 'Құпиясөз жолының ұзындығы 7 символдан асуы керек',
        info: 'Құпиясөз 8 белгіден көп болуы керек'
    },
    formText: 'Мен «КІРУ» түймесін басқанда «АДИДАС» ЖШС-ға өзімнің <a href="https://media.adidas.kz/personal_data_accept_kz.pdf" target="_blank">дербес деректерді өңдеуге және ақпараттық таратпаларды таратуға келісімімді</a> беремін және <a href="https://media.adidas.kz/personal_data_policy_kz.pdf" target="_blank">Дербес деректерді өңдеу жөніндегі саясатпен</a> келісемін',
    confidencePolitic: 'adidas құпиялылық саясатымен',
    or: 'немесе',
    createText: {
        top: 'ADIDAS UNIVERSE бейілділік бағдарламасына қосыл:',
        b1: 'Ұпай жина және 20% дейін жеңілдік ал, оны сайтта да, бөлшек дүкендер мен дисконт-орталықтарда да қолдануыңа болады',
        b2: 'Акция кезінде арнайы шарттарға және қосымша бонустарға ие бол',
        b3: 'Арнайы ұсыныстар мен маусымдық сатылымдар жайлы бірінші боп біл',
        b4: 'Дәл қазір Присоединяйся к ADIDAS UNIVERSE-ке қосыл да, ұпай жинай баста және дербес жеңілдігің өсе береді. Өзің үшін adidas-тың кереметін аш.',
        bottom2: 'ADIDAS UNIVERSE туралы көбірек білгің келе ме?',
    },
    buttonLogin: 'Кіру',
    buttonRegistry: 'Тіркелу',
    buttonPhone: 'Телефон',
    buttonFb: 'Facebook',
    buttonGoogle: 'Google',
    popupPhone: {
        title: 'Ұялы нөмірмен кіру',
        inputPlaceholderPhone: 'Ұялы телефон',
        inputPlaceholderCode: 'Растау коды',
        codeSendToNumber: 'Растау коды нөмірге жіберілді',
        buttonSend: 'Кодты жіберу',
        buttonConfirm: 'Растау',
        buttonBreak: 'Доғару',
        resend: 'Тағы да жіберу',
        errorRequired: 'Жол толтырылуы керек',
        errorPhone: 'Телефон қате жазылды',
        errorCode: 'Растау коды қате',
        errorCodeLength: 'Растау кодының ұзындығы {length} символдан тұруы керек'
    },
    popupForgotPass: {
        title: 'Құпиясөзді ұмыттым',
        subtitle: 'Өз email-ыңды жаз, ал біз оған құпиясөзді қалпына келтіруге арналған сілтемені жібереміз.',
        textBold: 'Құпиясөзді қалпына келтіруге арналған сілтеме жіберілді',
        textEmail: 'E-mail-ды тексер',
        buttonClearPass: 'Құпиясөзді қалпына келтіру',
        buttonClose: 'Жабу'
    },

    newPassword: {
        title: 'Жаңа құпиясөз жаса',
        subtitle: 'Құпиясөзді жаз. Құпиясөзді өзгерткеннен кейін сен жүйеде қала бересің.'
    }
}
