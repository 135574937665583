const requestsList = {}

export const state = () => ({
    showLangPopup: false,
    footerData: {
        desktop: [],
        mobile: []
    },
    facebookAppKey: '',
    googleAppKey: '',
    apiLangPoint: 'kk',
});

export const getters = {
    getFooterColumns: ({footerData}) => footerData?.desktop || [],
    getFooterMobileColumns: ({footerData}) => footerData?.mobile || [],
    showLangPopup: ({showLangPopup}) => showLangPopup,
    facebookAppKey: ({facebookAppKey}) =>facebookAppKey,
    googleAppKey: ({googleAppKey}) =>googleAppKey,
    getApiLangPoint: ({apiLangPoint}) =>apiLangPoint,

};

export const actions = {
    async fetchFooterData({commit}, lang) {
        try {
            requestsList.fetchFooterData = this.$axios.CancelToken.source()
            await this.$axios.$get(`${API.main}/footer`, {
                cancelToken: requestsList.fetchFooterData.token
            }).then((data) => {
                commit(types.SET_FOOTER_COLUMNS, data);
            });
        } catch (err) {
        }
    },
    setLocaleCookie({}, locale) {
        this.$cookies.set('prevPreferenceLang', this.$i18n.locale);
        this.$cookies.set('preferenceLang', locale);
        const langIso = Helpers.getLocaleIso(this.$i18n, locale);
        if (langIso) {
            this.$axios.defaults.headers['Accept-Language'] = langIso;
        }
    },
};

export const mutations = {
    [types.SET_LANG_POPUP_SHOWING_STATE] (state, data) {
        state.showLangPopup = data
    },
    [types.SET_FOOTER_COLUMNS] (state, data) {
        state.footerData = data
    },
    [types.SET_FB_API_KEY](state, data) {
        state.facebookAppKey = data
    },
    [types.SET_GOOGLE_API_KEY](state, data) {
        state.googleAppKey = data
    },
    [types.SET_LANG_POINT](state, data) {
        state.apiLangPoint = data
    },
};
