//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "@/plugins/VPopup/popup";
import ProductPrice from "@/components/pages/productCard/ProductContent/Components/ProductPrice/index";
import ResponsiveImage from "@/components/common/ResponsiveImage";
import ProductInfo from "@/components/popups/ProductAddedToCart/Components/ProductInfo";
import CartInfo from "@/components/popups/ProductAddedToCart/Components/CartInfo";
import {mapGetters} from "vuex";
import statusCartEvent from "~/assets/js/eSputnik/statusCartEvent";

export default {
    name: "ProductAddedToCart",
    components: {CartInfo, ProductInfo, ResponsiveImage, ProductPrice, VPopup},
    props: {
        product: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            cartInfo: {}
        }
    },
    computed: {
        ...mapGetters({
            chosenSize: 'cart/getLastAdded',
            totalPrices: 'cart/getCartTotal',
            cartCount: 'cart/getCartLength',
            cartList: 'cart/getList',
        }),
    },
    watch: {
        '$route.path': {
            handler(newVal,oldValue) {
                newVal !== oldValue && this.$popups.dismiss()
            }
        },
        cartList(newVal, oldVal){
            if (newVal && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                statusCartEvent(newVal, 1);
            }
        },
    },
}
