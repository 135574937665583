//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import flagIcon from "~/assets/img/icons/flag-icon-kz.svg";

export default {
    name: "FooterBottomRowLocalLocation",
    data() {
        return {
            flagIcon
        };
    }
};
