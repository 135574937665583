import esEvent from "~/assets/js/eSputnik/esEvent";

export default function(ctx) {
    ctx.app.router.afterEach((to, from) => {
        /*
        ** Fire a page view on each route change
        */
        // eSputnik event
        esEvent("PageView", {
            "PageView": {}
        });
    });


}
