export default {
    orderThank: 'Рақмет, өтініміңіз жіберілді',
    subscribeThank: 'РАХМЕТ, YEEZY БАР БОЛСА ХАБАРЛАЙМЫЗ',
    subscribeBtnText: 'САТЫП АЛУ',
    confirmSize:{
        title: 'Таңдалған өлшемді тексеріңіз',
        subtitle: 'Таңдалған өлшемді растағаннан кейін өзгертуге немесе дүкенде ауыстыруға болмайды',
        changeSize: 'Өлшемді өзгерту',
        confirmSize: 'Растау',
    },
    sizePlaceholder: 'Өлшемді таңда',
    apply: 'Өтінім жіберу',
    subscribe: 'Ақпараттық бюллетеньге жазылу',
    returnConditionsTitle: 'Қайтару шарттары',
    returnSaleConditionsTitle: 'САТЫП АЛУ ШАРТТАРЫ',
    popupPhone: {
        title: 'Телефон нөмірін растау',
        inputPlaceholderPhone: 'Ұялы телефон',
        inputPlaceholderCode: 'Растау коды',
        codeSendToNumber: 'Растау коды нөмірге жіберілді',
        buttonSend: 'Кодты жіберу',
        buttonConfirm: 'Растау',
        buttonBreak: 'Доғару',
        resend: 'Қайтадан жіберу',
        errorRequired: 'Жол толтырылуы керек',
        errorPhone: 'Телефон қате жазылған',
        errorCode: 'Растау коды қате',
        errorCodeLength: 'Растау кодының ұзындығы {length} символдан тұруы тиіс'
    },
    fieldsTitle: {
        personalData: 'Дербес деректер',
        birthDate: 'Туған күн',
        gender: 'Жыныс',
        loginData: 'Аккаунтқа кіруге арналған деректер'
    },
    chooseYourSize: 'Өз өлшеміңді таңда',
    confirmPhone: 'Телефон нөмірін растаңыз',
    hasRequest: 'Қатысуға өтінім беріп қойғансыз',
}
