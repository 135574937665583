import esEvent from "./esEvent";

export default function productImpressionsEvent(data) {
    sendEvent(data);

    function sendEvent(data) {
        const eventBody = {
            "ProductImpression": data,
        };
        esEvent("ProductImpressions", eventBody);
    }
}


