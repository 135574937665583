import { appConfig } from "~/utils/config";
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            "metaTags": 'dynamicPage/metaTags',
        }),
        priceValidUntil() {
            let today = new Date();
            let month = today.getMonth() + 1;
            let year = today.getFullYear();
            let lastDayDate = new Date(year, month, 0);
            return `${year}-${month < 10 ? '0'+month : month}-${lastDayDate.getDate()}`
        },
        catalogStructuredData() {
            let url = this.metaTags.find(el => el.property === 'og:url');
            return {
                type: "application/ld+json",
                json: {
                    "@context": "https://schema.org/",
                    "@type": "ItemList",
                    "url": `${PARAMS.baseUrl}${this.$route.fullPath}`,
                    "numberOfItems": this.products.length,
                    "itemListElement": this.listStructuredData,
                }
            }
        },
        listStructuredData() {
            let list = [];
            this.products.forEach((productData, index) => {
                let productHasSizes = Boolean(productData?.sizes?.filter(size => size.hasOwnProperty('isAvailable') && size.isAvailable).length) && productData.isAvailable
                list.push({
                    "@type": "ListItem",
                    "position": index+1,
                    "item": {
                        "@type": "Product",
                        "name": `${productData?.displayName}`,
                        "url": `${productData?.url?.absolute}`,
                        "image": `${AppConfig.image.mediaUrl}/w_600/q_90/${productData.imagesMainList?.plp?.id}.WebP`,
                        "brand": `adidas ${ productData?.division || "" }`,
                        "offers": {
                            "@type": "Offer",
                            "priceCurrency": "KZT",
                            "price": productData.price.sale,
                            "sku": `${productData?.article}`,
                            "availability": productHasSizes ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
                            "priceValidUntil": `${this.priceValidUntil}`
                        }
                    }
                })
            })
            return list;
        },
    },
};
