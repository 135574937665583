//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "DesktopMenuItem",
    props: {
        menuData: {
            required: true,
            type: Object
        }
    },
    components: {
        MenuColumn: () => import("./components/MenuColumn")
    },
    computed: {
        selectedColumnName: function () {
            return this.menuData.id
        },
        columnsToShow: function () {
            return this.menuData.columns
        }
    },
    created() {
    }
}
