import esEvent from '~/assets/js/eSputnik/esEvent';

export default function customerDataEvent(data) {
    const eventName = 'CustomerData';

    if (typeof data !== 'object' || !data.email) {
        console.warn('Wrong data type', eventName, data);
    }


    const eventBody = {
        'user_email': data.email,
    };
    if (data.firstName) {
        eventBody['user_name'] = data.firstName;
    }
    if (data?.phoneNumber) {
        eventBody['user_phone'] = (data?.phoneNumber).slice(1);
    }
    if (data.gender) {
        eventBody['user_name'] = data.firstName;
    }

    sendEvent({
        [eventName]: eventBody
    });

    function sendEvent(eventBody) {
        esEvent(eventName, eventBody);
    }
}
