//
//
//
//
//
//
//
//
//

export default {
    name: "YeezyInfo",
    props: {
        product: {
            type: Object,
            required: true
        }
    }
}
