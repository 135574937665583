//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
    name: "Clear",
    middleware: ['fetch-dynamic-page-data'],
    computed: {
        ...mapGetters({
            localeApi: 'core/getApiLangPoint',
        }),
        route() {
            return this.$route.name;
        },
        localeApiIso() {
            return Helpers.getLocaleIso(this.$i18n,this.localeApi);
        }
    },
    mounted() {
        setTimeout(async () => {
            await this.$i18n.setLocale(this.localeApi);

            this.$cookies.set('responseLang', this.localeApiIso);
            this.$cookies.set('preferenceLang', this.localeApi);
            this.$cookies.set('responseSetLang', this.localeApiIso);
            this.$axios.defaults.headers['Accept-Language'] = this.localeApiIso;
        }, 0);
    }
};
