import Vue from "vue";
import {createHash}  from "crypto";

export default function ({app, store, route, $cookies, gtag}, inject) {
    inject('criteo', new Vue({
        data: function () {
            return {
            }
        },
        computed: {
            isGuest() {
                return store.getters['user/isGuest'];
            },
            userInfo() {
                return store.getters['user/getUserInfo'];
            },
            lang() {
                return app.i18n.localeProperties.code
            },
            criteoPartnerId(){
                return this.lang === 'ru' ? PARAMS.criteo_id_ru : PARAMS.criteo_id_kz
            },
            emailHash() {
                return !this.isGuest && this.userInfo?.email ? createHash('sha256').update(this.userInfo?.email).digest('hex') : null;
            },
            deviceType() {
                return navigator ? /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d" : null;
            },
            setAccount() {
                return { event: "setAccount", account: this.criteoPartnerId};
            },
            setEmail() {
                return  { event: "setEmail", email: this.emailHash, hash_method: "sha256" }
            },
            setSiteType() {
                return { event: "setSiteType", type: this.deviceType}
            },
        },
        methods: {
            simpleEvent(eventName) {
                if(this.isGuest) {
                    return false
                }
                window.criteo_q = window.criteo_q || [];
                window.criteo_q.push(
                    this.setAccount,
                    this.setEmail,
                    this.setSiteType,
                    { event: eventName }
                );
                this.logEvent(eventName, { event: eventName });
            },
            viewList(items, category, keywords) {
                if(this.isGuest) {
                    return false
                }
                let listEvent = {
                    event: "viewList",
                    item: items,
                    category: category,
                }
                if (keywords) {
                    listEvent.keywords = keywords
                }
                window.criteo_q = window.criteo_q || [];
                window.criteo_q.push(
                    this.setAccount,
                    this.setEmail,
                    this.setSiteType,
                    listEvent
                )
                this.logEvent('viewList', listEvent);
            },
            productView(productId) {
                if(this.isGuest) {
                    return false
                }
                window.criteo_q = window.criteo_q || [];
                window.criteo_q.push(
                    this.setAccount,
                    this.setEmail,
                    this.setSiteType,
                    { event: "viewItem", item: productId.toString() }
                )
                this.logEvent('viewItem',{ event: "viewItem", item: productId.toString() });
            },
            addToCart(productId, price, quantity) {
                if(this.isGuest) {
                    return false
                }
                window.criteo_q = window.criteo_q || [];
                window.criteo_q.push(
                    this.setAccount,
                    this.setEmail,
                    this.setSiteType,
                    { event: "addToCart", item: [
                        {id: productId.toString(), price: price.toString(), quantity: quantity.toString() }
                    ]}
                )

                this.logEvent('addToCart',
                    { event: "addToCart", item: [
                            {id: productId.toString(), price: price.toString(), quantity: quantity.toString() }
                        ]}
                );
            },
            cartTag(cartList) {
                if(this.isGuest) {
                    return false
                }
                window.criteo_q = window.criteo_q || [];
                window.criteo_q.push(
                    this.setAccount,
                    this.setEmail,
                    this.setSiteType,
                    { event: "viewBasket", item: cartList}
                )
                this.logEvent('viewBasket',{ event: "viewBasket", item: cartList});
            },
            salesTag(orderNumber, orderList) {
                if(this.isGuest) {
                    return false
                }
                window.criteo_q = window.criteo_q || [];
                window.criteo_q.push(
                    this.setAccount,
                    this.setEmail,
                    this.setSiteType,
                    { event: "trackTransaction", id: orderNumber, item: orderList}
                )
                this.logEvent('trackTransaction',{ event: "trackTransaction", id: orderNumber, item: orderList});
            },
            logEvent(eventName, data) {
                console.log(`%c criteo event: %c ${eventName}`,
                    'background: white; color: black;',
                    'background: black; color: white;',
                    [
                        this.setAccount,
                        this.setEmail,
                        this.setSiteType,
                        data
                    ]);
            }
        }
    }))

}
