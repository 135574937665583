//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var VueScrollTo = require('vue-scrollto');
export default {
    name: "SlideCarousel",
    props: {
        showArrow: {
            type: Boolean,
            default: true
        },
        showDots: {
            type: Boolean,
            default: false
        },
        topPosition: {
            type: String,
            default: 'calc(50% + 13px)'
        },
        smallArrow: {
            type: Boolean,
            default: false
        },
        longArrow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            carousel: null,
            blockAnimate: false,
            choosePage: null,
            carouselWidth: 0,
            scrollLeft: 0,
            activeSlide: 0,
            slideCount: 0,
            padLeft: 0,
            slides: [],
            stopScrollTimer: null,
            scrollDirective: 'right'
        }
    },
    computed: {
        id() {
            return 'math'
        },
        showPrevBtn() {
            return this.scrollLeft > 0
        },
        showNextBtn() {
            return this.scrollLeft < this.maxScroll;
        },
        options() {
            return  {
                container: this.$refs.carousel,
                easing: 'ease-in-out',
                lazy: false,
                force: true,
                cancelable: true,
                x: true,
                y: false
            };
        },

        fullWidth(){
            let w = 0
            if (this.slideCount) {
                this.slides.forEach(i => { w += i.clientWidth })
            }
            return w;
        },
        slideWidth() {
            return this.slideCount ? this.slides[0].clientWidth : 1;
        },
        maxScroll() {
            return this.fullWidth - this.carouselWidth;
        },
        slidePerPage() {
            return Math.floor(this.carouselWidth / this.slideWidth ) < 2 && this.showDots ? 2 : Math.floor(this.carouselWidth / this.slideWidth );
        },
        totalPages() {
            return Math.ceil( this.slideCount / this.slidePerPage );
        },
        activePage() {
            return this.choosePage || this.activeMathPage;
        },
        activeMathPage() {
            let calc = Math.ceil( this.activeSlide / this.slidePerPage + 1 )
            return calc <= this.totalPages ? calc : this.totalPages;
        },
        isClient() {
            return process.client && document.querySelector(`.carousel-${this.id} .carousel__content`)
        },
        pageWidth () {
            return this.slideWidth * this.slidePerPage
        }
    },
    methods: {
        moveContent() {
            this.scrollLeft = this.carousel.scrollLeft - this.padLeft;
            this.activeSlide = Math.ceil(this.scrollLeft / this.slideWidth);
            if(this.scrollLeft !== this.maxScroll && this.$breakpoint.tabletDown) {
                clearTimeout(this.stopScrollTimer);
                this.stopScrollTimer = setTimeout(function()
                {
                    if(this.activePage === this.totalPages && this.scrollDirective === 'right') {
                        this.goToPage(this.totalPages)
                    } else {
                        const totalScroll = (this.scrollLeft / this.pageWidth) + 1;
                        const scrollOnCurrentPage = totalScroll - Math.trunc(totalScroll);
                        let page
                        if (0.3 < scrollOnCurrentPage && scrollOnCurrentPage < 0.7) {
                            page = this.scrollDirective === 'right' ? Math.ceil(totalScroll) : Math.floor(totalScroll)
                        } else {
                            page = Math.round(totalScroll)
                        }
                        this.goToSlide((page - 1) * this.slidePerPage)
                    }
                }.bind(this),300);
            }
        },
        showNext() {
            let index = this.activeSlide + this.slidePerPage
            VueScrollTo.scrollTo(this.slides[index], 500, this.options);
        },
        showPrev() {
            let index = this.activeSlide - this.slidePerPage;
            VueScrollTo.scrollTo(this.slides[index >= 0 ? index : 0], 500, this.options);
        },
        goToPage(i) {
            this.choosePage = i;
            let index = (i - 1)*this.slidePerPage;
            VueScrollTo.scrollTo(this.slides[index >= 0 ? index : 0], 500, this.options);
            setTimeout(function (){
                this.choosePage = null
            }.bind(this), 550)
        },

        goToSlide(index) {
            VueScrollTo.scrollTo(this.slides[index >= 0 ? index : 0], 300, this.options);
        },

        initCarousel() {
            this.slides = [];
            this.carousel = this.$refs.carousel
            this.carouselWidth = this.carousel ? this.carousel.clientWidth : 0;
            this.slides = this.carousel ? this.carousel?.children : null;
            this.slideCount = this.slides ?  this.slides.length : 0;
            this.padLeft = this.slides ? this.slides[0].offsetLeft : 0;

            if (this.carousel && this.slideCount <= 0) {
                setTimeout(function (){
                    this.initCarousel();
                }.bind(this), 1000)
            }
        }
    },
    mounted(){
        this.$nextTick(function () {
            this.initCarousel();
        });
        this.$refs.carousel.addEventListener( 'scroll', this.moveContent );
    },
    beforeDestroy(){
        this.$refs.carousel.removeEventListener( 'scroll', this.moveContent );
        this.stopScrollTimer && clearTimeout(this.stopScrollTimer);
    },
    watch: {
        scrollLeft: {
            handler(newValue, oldValue) {
                this.scrollDirective = newValue >= oldValue ? 'right' : 'left'
            }
        }
    }
}
