//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FilterMobilePopup
    from "@/components/pages/store/StoreFilter/Components/FilterMobile/Components/FilterMobilePopup/index";
import {mapGetters} from "vuex";
import arrow from '~/assets/img/icons/FilterDropdown.svg?raw';

export default {
    name: "FilterMobileSort",
    components: {FilterMobilePopup},
    data() {
        return {
            openPopup: false,
            arrowIcon: arrow,
        }
    },
    computed: {
        ...mapGetters({
            sortRules: 'catalogStore/getSortingLinks',
        }),
        selected() {
            return this.sortRules.find(i => i.selected);
        },
        closeLink() {
            return '/man'
        },
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            } else  {
                return false;
            }
        },
        catalogRoute() {
            return this.$route.path
        },
        selectedSort() {
            return this.sortRules.find(i => i.selected)
        },
        textBtn() {
            return _get(this, 'selectedSort.title', '').length > 0 && this.$breakpoint.tabletSmallUp ? this.selectedSort.title : this.$t('store.sorting');
        },
    },
    methods: {
        togglePopup() {
            this.openPopup = !this.openPopup;

            if (this.openPopup) {
                this.body && this.body.classList.add('v-popup-open');
                this.$breakpoint.isMacOs && this.body.classList.add('isMacOs');
                this.$liveChat && this.$liveChat.hide();
            } else  {
                this.body && this.body.classList.remove('v-popup-open');
                this.$liveChat && this.$liveChat.show();
            }
        },
        isSelected(url) {
            return this.$route.path.includes(url)
        },
        updateSorting(id) {
            this.togglePopup();
        }
    }
}
