const  requestsList = {};
export const state = () => ({
    userData: {},
    adidasUniverse: {},
    lastOrder: {},
    orderHistory: [],
    orderHistoryInfo: {},
    coupons: [],
    openTalkMe: false,
    savedCodeResponseForRegister: null,
    loadUserDataFlag: false,
    loginMethod: null,
});

export const getters = {
    isGuest: ({ userData }) => _get(userData, 'isGuest', true),
    isLoadUserData: ({ loadUserDataFlag }) => loadUserDataFlag,
    isSubscribed: ({ userData }) => userData.isSubscribed,
    getUserInfo: ({ userData }) => userData.customer,
    getUniverse: ({ adidasUniverse }) => adidasUniverse,
    getCoupons: ({ coupons }) => coupons,
    getLastOrder: ({ lastOrder }) => lastOrder,
    getOrdersList: ({ orderHistory }) => orderHistory,
    hasMoreOrders: ({ orderHistoryInfo }) => orderHistoryInfo.currentPage < orderHistoryInfo.pageCount,
    getOpenTalkMeStatus: ({ openTalkMe }) => openTalkMe,
    getSavedCodeResponseForRegister: ({ savedCodeResponseForRegister }) => savedCodeResponseForRegister,
    getLoginMethod: ({ loginMethod }) => loginMethod,
};

export const actions = {
    async fetchUserData ({commit, dispatch}, lang) {
        requestsList.fetchUserData = this.$axios.CancelToken.source()
        await this.$axios.get(`${API.main}/user`, {
            cancelToken: requestsList.fetchUserData.token
        }).then(async (response) => {
            commit(types.SET_USER_DATA, response.data);
            commit(types.SET_LOAD_USER_DATA_FLAG, true);
            this.$ga.setUserId(_get(response, 'data.id', ''))
            if (this.$router.history.current.path !== '/confirmation') {
                await dispatch('getUserData', '', {root:true});
            }
        }).catch((e) => {
            return {}
        });
    },

    async loadAdidasUniverse ({commit, dispatch}) {
        await this.$axios.get(`${API.main}/crm/status`).then(async (response) => {
            commit(types.SET_ADIDAS_UNIVERSE, response.data);
        }).catch((e) => {
            return {}
        });
    },

    async loadCoupons ({commit, dispatch}) {
        await this.$axios.get(`${API.main}/account/promocode`).then(async (response) => {
            commit(types.SET_COUPONS, response.data);
        }).catch((e) => {
            return {}
        });
    },

    async loadLastOrder ({commit}) {
        await this.$axios.get(`${API.main}/order`, {
            params: {
                expand: AppConfig.store.loadOrder.join(','),
                perPage: 1
            }
        }).then(async (response) => {
            commit(types.SET_LAST_ORDER, response.data.items[0]);
        }).catch((e) => {
            return {}
        });
    },

    async loadOrderHistory ({state, commit}, removeOld = false) {
        if (removeOld) {
            commit(types.CLEAR_ORDER_HISTORY)
        }
        await this.$axios.get(`${API.main}/order`, {
            params: {
                expand: AppConfig.store.loadOrder.join(','),
                perPage: 5,
                page: state.orderHistoryInfo.currentPage ? state.orderHistoryInfo.currentPage + 1 : 1
            }
        }).then(async (response) => {
            commit(types.LOAD_ORDER_HISTORY, response.data);
        }).catch((e) => {
            return {}
        });
    },

    async changeSubscribeValue({state, commit}, flag) {
        await this.$axios.post(`${API.main}/account/preference/subscription`, {
            "subscription_approval_flag": flag
        }).then((response) => {
            commit(types.SET_SUBSCRIBE_FLAG, flag);
        })
    },

    async logoutUser({dispatch,commit}, redirectUrl = '/') {
        await this.$axios.post(`${API.main}/customer/logout`, {}).then(async (response) => {
            await dispatch('getLocalizeData', '', {root:true});
            await dispatch('fetchUserData');
            commit(types.CLEAR_ORDER_HISTORY)
            window.$nuxt.$router.push(redirectUrl);
        })
    }
};

export const mutations = {
    [types.SET_USER_DATA] (state, data) {
        state.userData = data
    },
    [types.SET_LOAD_USER_DATA_FLAG] (state, data) {
        state.loadUserDataFlag = data
    },
    [types.SET_SUBSCRIBE_FLAG] (state, data) {
        state.userData.isSubscribed = data
    },
    [types.SET_ADIDAS_UNIVERSE] (state, data) {
        state.adidasUniverse = data
    },
    [types.SET_COUPONS] (state, data) {
        state.coupons = data
    },
    [types.SET_LAST_ORDER] (state, data) {
        state.lastOrder = data
    },
    [types.SET_LOGIN_METHOD] (state, data) {
        state.loginMethod = data
    },
    [types.CLEAR_USER_INFO] (state) {
        state.adidasUniverse = {};
        state.lastOrder = {};
        state.orderHistory = [];
        state.orderHistoryInfo = {};
        state.coupons = [];
    },
    [types.LOAD_ORDER_HISTORY] (state, data) {
        data.items.forEach(i => state.orderHistory.push(i))
        state.orderHistory.push();
        state.orderHistoryInfo = data._meta;
    },
    [types.CLEAR_ORDER_HISTORY] (state) {
        state.orderHistory = [];
        state.orderHistoryInfo = {};
    },
    [types.SET_TALK_ME_STATUS] (state, data) {
        state.openTalkMe = data
    },
    [types.SAVE_CODE_FOR_REGISTER] (state, data) {
        state.savedCodeResponseForRegister = data
    },
    [types.CLEAR_SAVED_CODE_FOR_REGISTER] (state, data) {
        state.savedCodeResponseForRegister = null
    },
};
