//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";
import MaskInput from "./components/MaskInput";

export default {
    name: "Phone",
    mixins: [fieldMixin],
    components: { MaskInput },

    computed: {
        mask() {
            return this.fieldIsDisabled ? AppConfig.inputMask.kz.disabled : AppConfig.inputMask.kz.enabled;
        }
    },
};
