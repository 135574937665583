import esEvent from "~/assets/js/eSputnik/esEvent";

export default function searchRequestEvent(data) {
    const eventName = "SearchRequest";

    if (typeof data !== "object" && data.hasOwnProperty("search")) {
        console.error("Wrong data type", eventName, data);
        return;
    }
    const eventBodyObj = {
        search: data.search,
    };
    if (data.hasOwnProperty("isFound")) {
        eventBodyObj.isFound = data.isFound;
    }

    sendEvent({
        [eventName]: eventBodyObj
    });

    function sendEvent(eventBody) {
        esEvent(eventName, eventBody);
    }
}
