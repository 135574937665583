//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';

export default {
    name: "HeaderSearch",
    components: {
        SearchInput: () => import( "~/components/pages/search/SearchInput")
    },
    data() {
        return {
            searchInput: ''
        }
    },
    computed: {
        ...mapGetters({
            getGlobalSearch: 'search/getGlobalSearch'
        }),
    },
    watch: {
        getGlobalSearch(val) {
            this.searchInput = val;
        }
    },
}
