//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
export default {
    name: "HeaderIconContainer",
    components: {
        HeaderSearch: () => import("./HeaderSearch"),
        HeaderIconBag: () => import("./HeaderIconBag"),
        HeaderIconFavorite: () => import("./HeaderIconFavorite")
    },
    computed: {
        ...mapGetters({
            'isGuest': 'user/isGuest'
        }),
        accountLink() {
            return this.isGuest ? '/login' : '/account'
        },
    },
}
