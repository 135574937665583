//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";
import RadioDeliveryItem from "@/components/common/LazyFormRow/components/components/RadioDeliveryItem";

export default {
    name: "AutoSelect",
    mixins: [fieldMixin],
    components: {RadioDeliveryItem},
    computed: {
        addressListItems() {
            return this.listItems.filter(el => el.type === 'address');
        },
    },
    methods: {
        setNewVal(item) {
            this.updateVal(item)
        },
        autoSelect() {
            let property = typeof this.val === 'object' ? this.val[this.field.valueProp] : this.val;
            if (this.val !== '' && this.listItems.length && this.listItems.find(i=>i[this.field.valueProp] === property) ) {
                this.setNewVal(this.listItems.find(i=>i[this.field.valueProp] === property));
            } else {
                this.setNewVal(this.listItems.find(el => el.type === 'address'));
            }
        },
    },
    watch: {
        listItems: {
            handler: function (newVal, oldVal) {
                if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                    this.autoSelect();
                }
            },
        },
    }
}
