export default {
    title: 'Тапсырысты ресімдеу',

    head: {
        cart: 'Себет',
        delivery: 'Жеткізу',
        payment: 'Төлем',
        thankYou: 'Тапсырыс ресімделді',
        questions: 'Сұрақтар',
        workHours: '10:00-ден 19:00 дейін',
        workDays: 'демалыссыз',
    },

    sidebar: {
        statusTitle: 'Тапсырыс мәртебесі',
    },

    delivery: {
        title: 'Жеткізу мекенжайын таңда',
        titleType: 'Жеткізу тәсілін таңда',
        toPayment: 'Ресімдеуді жалғастыру',
        city: 'Елді мекен',
        deliveryTypeLabel: 'Жеткізу тәсілі',
        priceInfo: 'Жеткізу бағасы мен мерзімі жеткізу тәсіліне байланысты. Келесі қадамда осы ақпаратты нақтылай аласың.',
        addressTitle: 'Жеткізу мекенжайы',
        addressInfo: 'Біз байланыс деректерін саған тапсырыс туралы ақпарат беру үшін ғана қолданамыз.',
        warehouseTitle: 'Бөлімше мекенжайы',
        warehouseInfo: 'Тапсырысты алып кетуге ыңғайлы мекенжайды нақтылауыңды сұраймыз немесе ең жақын бөлімшені таңда',
        onMap: 'картада',
        wontSubscribe: 'adidas жаңа тауарлары, акциялар мен жаңалықтары туралы ақпарат алғым келеді.',
        confidentiality: 'Мен <a href="/gdpr" target="_blank">Құпиялылық саясатын</a> және <a href="/rules" target="_blank">сату шарттарын</a> қабылдаймын',
        loginNotification: 'Тапсырысты жылдам ресімдеу үшін аккаунтқа кір',
        rulesTitle: 'Жеткізу шарттары',
        typeFreeDelivery1: '50000 ₸ басталатын тапсырыс бергенде жеткізу тегін',
        typeFreeDelivery2: 'Тапсырыс сомасы бойынша шектеусіз тегін жеткізу',
        turnThis: 'Мына жерден алып кет',
        readMore1: 'Толығырақ',
        readMore2: 'Қайыру',
    },

    payment: {
        title: 'Төлем және тапсырысты растау',
        choosePaymentType: 'Ыңғайлы төлем тәсілін таңда',
        approveOrder: 'Тапсырысты растау',

        or: 'немесе',
        buy: 'Ақысын төлеу',
        cartNumber: 'Карточка нөмірі',
        expireLabel: 'АА/ЖЖ',
        expireInfo: 'Жарамдылық мерзімі',
        cvvInfo: 'CVV2/CVC2 коды',
        cvvPopover: 'Кері жағындағы 3-мәнді код',

        sale: {
            paymentByCard: '(қосымша 5% жеңілдік)'
        },
        btnTitle: "Ақысын төлеу",
        partPay: {
            count: 'Төлемдер саны:',
            paying: 'Ай сайынғы төлем:',
            info: 'Егер сенде бөліп төлеуді ресімдеу бойынша сұрақтар туындаса, HalykBank 7111 жедел желісіне хабарлас',
            btn: 'Бөліп сатып алу',
        }
    },

    confirmation: {
        title: 'Сенің тапсырысың сәтті ресімделді',
        orderNumber: 'Тапсырыс нөмірі',
        welcomeText: 'Сәлем, {name}, adidas-тан сатып алғаныңа алғыс! Жуық арада email-ға <b>{email}</b> растау жібереміз.',
        deliveryDateTitle: 'Сенің тапсырысың жеткізіледі:',
        deliveryInfoMsg: 'Веб-сайтта болжалды жеткізу күні бар. Тапсырыс жіберілгеннен кейін SMS арқылы жіберілетін трек нөмірін пайдаланып, жеткізудің нақты күнін курьерлік қызметтің веб-сайтында тексеруге болады.',
        infoOrder: 'Тапсырысың қоймадан жөнелтілген кезде email-ға хат және СМС келеді.',
        askTitle: 'Бірдеңе дұрыс болмады ма?',
        askText: 'Сұрақтар қалды ма? Жауапты <a href="/help">Көмек бөлімінен</a> ізде',
        deliveryDate: 'Жеткізу кезеңі:',
    }
}
