//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";
import HalykBank from '~/assets/img/icons/HalykBank.svg?raw'
import halyk_adv_1 from '~/assets/img/icons/halyk_adv_1.svg?raw'
import halyk_adv_2 from '~/assets/img/icons/halyk_adv_2.svg?raw'
import halyk_adv_3 from '~/assets/img/icons/halyk_adv_3.svg?raw'
import halyk_adv_4 from '~/assets/img/icons/halyk_adv_4.svg?raw'

export default {
    name: "HalykPartPay",
    components: {VPopup},
    data() {
        return {
            HalykBank: HalykBank,
        }
    },
    computed: {
        advantageList() {
            return [
                {
                    icon: halyk_adv_1,
                    text: this.$t('common.halykPart.advantages.item1')
                },
                {
                    icon: halyk_adv_2,
                    text: this.$t('common.halykPart.advantages.item2')
                },
                {
                    icon: halyk_adv_3,
                    text: this.$t('common.halykPart.advantages.item3')
                },
                {
                    icon: halyk_adv_4,
                    text: this.$t('common.halykPart.advantages.item4')
                },
            ]
        }
    },
    methods: {
        closePopup() {
            this.$popups.close();
            this.$router.push(this.$router.history.current.path);
        }
    }
}
