//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex"
export default {
    name: "ResponsiveImage",
    props: {
        classList: {
            type: String,
            default: 'image'
        },
        media: {
            type: Object,
            required: true
        },
        noLazy: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            imageIndex: 1
        }
    },
    computed: {
        ...mapGetters({
            getCurrentImageCount: 'getCurrentImageCount',
            pageH1: 'dynamicPage/pageH1',
        }),
        imgSrc() {
            return _get(this, 'media.src.desktop[\'2x\']', _get(this, 'media.src.desktop[\'1x\']', ''));
        },
        desktop() {
            return this.media.src.desktop['1x'] !== this.media.src.desktop['2x'] && this.media.src.desktop['2x'].trim() !== '' ? (`${this.media.src.desktop['1x']} 1x, ${this.media.src.desktop['2x']} 2x`) : this.media.src.desktop['1x']
        },
        tablet() {
            return this.media.src.tablet['1x'] !== this.media.src.tablet['2x'] && this.media.src.tablet['2x'].trim() !== '' ? (`${this.media.src.tablet['1x']} 1x, ${this.media.src.tablet['2x']} 2x`) : this.media.src.tablet['1x']
        },
        mobile() {
            return this.media.src.mobile['1x'] !== this.media.src.mobile['2x'] && this.media.src.mobile['2x'].trim() !== '' ? (`${this.media.src.mobile['1x']} 1x, ${this.media.src.mobile['2x']} 2x`) : this.media.src.mobile['1x']
        },
        title (){
            const title = _get(this, 'media.title', '');
            return title !== ''? title : `${this.pageH1} ${this.imageIndex}`
        },
        alt (){
            const alt = _get(this, 'media.alt', '');
            return alt !== ''? alt : `${this.pageH1} ${this.imageIndex}`
        },
        isTabletUp() {
            return this.$breakpoint.tabletSmallUp;
        },
        imgTypeLink() {
            if  (this.$breakpoint.mobileDown) {
                return {
                    srcset: this.mobile,
                    src: _get(this, 'media.src.mobile[\'1x\']', '')
                }
            } else if (this.$breakpoint.tabletSmallUp && this.$breakpoint.tabletDown) {
                return {
                    srcset: this.tablet,
                    src: _get(this, 'media.src.tablet[\'1x\']', '')
                }
            } else {
                return {
                    srcset: this.desktop,
                    src: _get(this, 'media.src.desktop[\'1x\']', '')
                }
            }
        }
    },
    mounted() {
        this.imageIndex = this.getCurrentImageCount
        this.$store.commit('INCREASE_IMAGE_COUNT')
    }
}
