import { render, staticRenderFns } from "./LanguageSwitch.vue?vue&type=template&id=ac3e2afe&lang=html&"
import script from "./LanguageSwitch.vue?vue&type=script&lang=js&"
export * from "./LanguageSwitch.vue?vue&type=script&lang=js&"
import style0 from "./LanguageSwitch.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonText: require('/opt/atlassian/pipelines/agent/build/components/common/CommonText.vue').default})
