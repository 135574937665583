//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import SectionProduct from "@/components/common/section/SectionProduct";

export default {
    name: "ProductWidgets",
    computed: {
        ...mapGetters({
            routeInfo: 'dynamicPage/getRouteInfo',
            macroPageStructure: 'dynamicPage/getMacroPageStructure',
            microPageStructure: 'dynamicPage/getMicroPageStructure',
            pageType: 'dynamicPage/pageType'
        }),

        filteredWidgetStructure () {
            return this.sortedWidgetStructureWithData.filter((block)=>{
                const componentForWidgetExist = Object.prototype.hasOwnProperty.call(this.$options.components, block.name);
                const isWidget = _get(block, 'type', 'widget') === 'widget';
                let canBeShowen = componentForWidgetExist && isWidget;
                if (isWidget){
                    const dataFilled = !Array.isArray(_get(block, 'data', []));
                    canBeShowen = canBeShowen && dataFilled
                }
                return canBeShowen
            })
        },

        sortedWidgetStructureWithData() {
            return ([].concat(this.microPageStructure)).sort(function (a, b) {
                return a.orderOnPage - b.orderOnPage
            })
        },
    },
    components: {
        SectionProduct,
        "widget_main_page_banner": () => import("@/widgets/mainHomeBanner/Index"), // Задача "Основной баннер"
        "widget_keyword_component": () => import("@/widgets/storeSubcategories/Index"), // Задача "Виджет "Популярно сейчас""
        "content_product_categories": () => import("@/widgets/forWhom/Index"),  // Задача "Виджет подборок"
        "widget_visual_navigation": () => import("@/widgets/categoriesTabs/Index"),  // Задача "Виджет "Для кого выбираешь?""
        "content_teaser_carousel": () => import("@/widgets/compilationCarousel/Index"),  // Задача "Карусель подборок"
        "widget_teaser_card": () => import("@/widgets/compilationCard/Index"),  // "Виджет подборок (Teaser Card)"
        "widget_bestsellers": () => import( "@/widgets/productCarousel/Index"),  // Виджет "хиты продаж"
        "widget_novelty": () => import( "@/widgets/productCarousel/Index"),  // Виджет "Новинки"
        "widget_we_recommend": () => import( "@/widgets/productCarousel/Index"),  // Виджет "Мы рекомендуем"
        "widget_recently_viewed": () => import( "@/widgets/productCarousel/Index"),  // Виджет "недавно просмотренные"
        "widget_choice_other_buyers": () => import( "@/widgets/productCarousel/Index"),  // Виджет "Выбор других покупателей"
        "content_teaser_small": () => import( "@/widgets/teaserCardSmall/Index"),  // Виджет "Teaser card small" https://imgur.com/xRZScyE
        "widget_keyword_component_main": () => import( "@/widgets/storeSubcategories/Index"),  // Виджет "Популярно сейчас для главной"
        "widget_personal_recommendation": () => import( "@/widgets/esRecommendation/Index"),  // Виджет "Рекомендации eSputnik"
    }
}
