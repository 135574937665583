import { render, staticRenderFns } from "./BnplInfo.vue?vue&type=template&id=423a3084&scoped=true&"
import script from "./BnplInfo.vue?vue&type=script&lang=js&"
export * from "./BnplInfo.vue?vue&type=script&lang=js&"
import style0 from "./BnplInfo.vue?vue&type=style&index=0&id=423a3084&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "423a3084",
  null
  
)

export default component.exports