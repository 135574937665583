//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";

export default {
    name: "HomeSeoMain",
    mixins: [widgetLayoutMixin],
    computed: {
        cards() {
            return _get(this, 'innerData.items', [])
        },
        showAllInOneColumns() {
            return this.$breakpoint.tabletDown
        }
    }
}
