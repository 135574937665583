import esEvent from '~/assets/js/eSputnik/esEvent';

export default function productPageEvent(data, selectedSize) {
    const eventName = 'ProductPage';

    if (typeof data !== 'object' && typeof selectedSize !== 'object') {
        console.warn('Wrong data type', eventName, data, selectedSize);
    }

    const selectedProductId = (selectedSize && selectedSize.id) ? selectedSize.id : data?.sizes?.[0]?.id;
    if (selectedProductId) {
        generateDataEvent();
    } else {
        console.warn('No product ID', eventName, selectedProductId);
    }

    function generateDataEvent() {
        const eventBody = {
            'productKey': ''+selectedProductId,
            'price': ''+data?.price?.sale,
            'isInStock': 1,
            'other_tags': {
                about: data.about,
                article: data.article,
                badges: data.badges,
                baseInfo: data.baseInfo,
                careInstructions: data.careInstructions,
                color: data.color,
                colors: data.colors,
                defaultColor: data.defaultColor,
                displayName: data.displayName,
                division: data.division,
                id: data.id,
                imagesFullList: data.imagesFullList,
                prices: data.price,
                productPath: data.productPath,
                sizeChart: data.sizeChart,
                sizes: data.sizes,
                url: data.url,
                vendorCode: data.vendorCode
            }
        };

        sendEvent({
            [eventName]: eventBody
        });
    }

    function sendEvent(eventBody) {
        esEvent(eventName, eventBody);
    }
}
