//
//
//
//


import ResponsiveImage from "@/components/common/ResponsiveImage";
import ResponsiveVideo from "@/components/common/ResponsiveVideo";
export default {
name: "CommonMedia",
    components: {ResponsiveVideo, ResponsiveImage},
    props: {
        media: {
            type: Object,
            required: true
        },
        videoLoop: {
            type: Boolean,
            default: true
        },
        noLazy: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        componentType() {
            return this.media.type === 'video' ? 'ResponsiveVideo' : 'ResponsiveImage';
        }
    }
}
