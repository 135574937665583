export default {
    title: 'Каталог',
    sorting: 'Сұрыптау',

    filter: {
        title: 'Сүзгі',
        moreFilters: 'Көбірек сүзгі',
        fewerFilters: 'Азырақ сүзгі',
        appliedTitle: 'Қолданылған сүзгілер',
    },

    forYouQuery: 'Сенің сұратымың бойынша:',

    // SEARCH
    titleSearch: 'Іздеу нәтижесі',

    // PRODUCT CARD
    titleProduct: 'Тауар'
}
