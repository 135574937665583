
export  default {
    props: {
        widgetInfo: {
            type: Object,
            required: true
        },
        isFirst: {
            type: Boolean,
            required: false
        }
    },
    data: function () {
        return {
            innerData: JSON.parse(JSON.stringify(_get(this, 'widgetInfo.data', {})))
        }
    },
}

