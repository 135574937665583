export default {
    data() {
        return {
            currentWindowWidth: 0,
            breakpoints: {
                mobileSmall: 375,
                mobile: 640,
                tabletSmall: 768,
                tablet: 1024,
                tabletLandscape: 1120,
                laptopSmall: 1280,
                laptop: 1440,
                desktop: 1600
            }
        }
    },
    computed: {
        /////////// mobileSmall //////////////
        mobileSmall() {
            return this.currentWindowWidth < this.breakpoints.mobileSmall;
        },
        mobileSmallUp() {
            return true
        },

        /////////// mobile //////////////
        mobile() {
            return this.currentWindowWidth < this.breakpoints.mobile && this.currentWindowWidth >= this.breakpoints.mobileSmall;
        },
        mobileDown() {
            return this.mobile || this.mobileSmall;
        },
        mobileUp() {
            return this.currentWindowWidth > this.breakpoints.mobileSmall;
        },

        /////////// tabletSmall //////////////
        tabletSmall() {
            return this.currentWindowWidth >= this.breakpoints.mobile && this.currentWindowWidth < this.breakpoints.tabletSmall;
        },
        tabletSmallDown() {
            return this.tabletSmall || this.mobileDown;
        },
        tabletSmallUp() {
            return this.currentWindowWidth > this.breakpoints.mobile;
        },

        /////////// tablet //////////////
        tablet() {
            return this.currentWindowWidth >= this.breakpoints.tabletSmall && this.currentWindowWidth < this.breakpoints.tablet;
        },
        tabletDown() {
            return this.tablet || this.tabletSmallDown;
        },
        tabletUp() {
            return this.currentWindowWidth > this.breakpoints.tabletSmall;
        },

        /////////// tabletLandscape //////////////
        tabletLandscape() {
            return this.currentWindowWidth >= this.breakpoints.tablet && this.currentWindowWidth < this.breakpoints.tabletLandscape;
        },
        tabletLandscapeDown() {
            return this.currentWindowWidth <= this.breakpoints.tabletLandscape;
        },
        tabletLandscapeUp() {
            return this.currentWindowWidth > this.breakpoints.tablet;
        },

        /////////// laptopSmall //////////////
        laptopSmall() {
            return this.currentWindowWidth >= this.breakpoints.tabletLandscape && this.currentWindowWidth < this.breakpoints.laptopSmall;
        },
        laptopSmallDown() {
            return this.laptopSmallUp || this.tabletLandscapeDown;
        },
        laptopSmallUp() {
            return this.currentWindowWidth > this.breakpoints.tabletLandscape;
        },

        /////////// laptop //////////////
        laptop() {
            return this.currentWindowWidth > this.breakpoints.laptopSmall && this.currentWindowWidth < this.breakpoints.laptop;
        },
        laptopDown() {
            return this.laptop || this.laptopSmallDown;
        },
        laptopUp() {
            return this.currentWindowWidth >= this.breakpoints.laptopSmall;
        },

        /////////// desktop //////////////
        desktop() {
            return this.currentWindowWidth >= this.breakpoints.laptop && this.currentWindowWidth < this.breakpoints.desktop;
        },
        desktopDown() {
            return this.desktop || this.laptopDown;
        },
        desktopUp() {
            return this.currentWindowWidth >= this.breakpoints.laptop;
        },


        /////////// desktopBig //////////////
        desktopBig() {
            return this.currentWindowWidth >= this.breakpoints.desktop;
        },

        ////////// isMacOs /////////////////
        isMacOs() {
            if (process.client) {
                return window.navigator.userAgent.indexOf('Macintosh') !== -1;
            }
            return false;
        }
    },
    methods: {
        calculateWindowWidth() {
            this.currentWindowWidth = window.innerWidth;
        }
    },
    mounted() {
        if(process.browser) {
            this.calculateWindowWidth();
            window && window.addEventListener('resize', this.calculateWindowWidth);
        }
    },
};
