import types from '~/store/mutation-types';
import getServerCachedApiData from '~/utils/ServerApiCache/getServerCachedApiData';
import { NodeApiCacheExpiring } from '~/utils/config';

export const state = () => ({
    list: {}
});

export const getters = {
    getFormById: ({ list }) => (formId) => (_get(list, formId, {})),
    getAllFormFieldsData: ({ list }) => (formId) => {
        return _get(list, `${ formId }.fields`, []).reduce((acc, item) => {
            _set(acc, `${ item.formName }.${ item.name }`, item.value);
            return acc;
        }, {});
    },
    getAllFormFieldsDataWithoutGrouping: ({ list }, getters) => (formName) => {
        return Object.values(getters.getAllFormFieldsData(formName)).reduce((acc, fields) => {
            for (let key in fields) {
                acc[key] = fields[key];
            }
            return acc;
        }, {});
    },
    getFormFieldsDataThatNeedToBeSend: ({ list }) => (formId) => {
        return _get(list, `${ formId }.fields`, []).reduce((acc, item) => {
            if (item.send) {
                _set(acc, `${ item.formName }.${ item.name }`, item.value);
            }
            return acc;
        }, {});
    },

    getFormFieldData: ({ list }) => (formId, fieldName) => {
        return (list?.[formId]?.fields ?? []).find(el => el.name === fieldName);
    },

    getFormErrorById: ({ list }) => (formId) => {
        return _get(list, `${ formId }.error`, '');
    },
};

export const actions = {
    async loadFormById({ state, dispatch }, formId) {
        await dispatch('loadFormByIdWithData', {
            formId
        });

    },
    async loadFormByIdWithData({ state, commit }, {
        formId,
        data,
        searchQuery = '',
        ctx,
    }) {
        let competedFormId = formId;
        if (formId.indexOf('---') !== -1) {
            // "---unique-form-id" prefix for the same API form name
            competedFormId = formId.slice(0, formId.indexOf('---'));
        }

        let formData = {};
        const formUrl = `${ API.main }/form/${ competedFormId }${ searchQuery ? `?${ searchQuery }` : '' }`;
        const reqData = {
            ...(data || {})
        };
        formData = await this.$axios.post(formUrl, reqData)
            .then((response) => {
                return (response?.data ?? {});
            }).catch((e) => {
                return {};
            });

        let actions = {};
        let methods = {};
        const fields = _get(formData, 'fields', []);
        const actionsInfo = _get(formData, 'action', '');
        const methodsInfo = _get(formData, 'method', '');

        if (typeof actionsInfo === "string") {
            fields.forEach((field) => {
                actions[field.formName] = actionsInfo;
            });
            actions['default'] = actionsInfo;
        } else if (Array.isArray(actionsInfo)) {
            actions['default'] = actionsInfo[0];
        } else {
            actions = actionsInfo;
        }

        if (typeof methodsInfo === "string") {
            fields.forEach((field) => {
                methods[field.formName] = methodsInfo;
            });
            methods['default'] = methodsInfo;
        } else if (Array.isArray(methodsInfo)) {
            methods['default'] = methodsInfo[0];
        } else {
            methods = methodsInfo;
        }
        commit(types.ADD_FORM_TO_LIST, {
            formId,
            formStructure: {
                fields: fields,
                action: actions,
                method: methods,
                error: '',
            }
        });
    },
    setFormErrors({ state, commit, getters }, { formId, formErrors }) {
        const form = getters.getFormById(formId);
        const formFieldsCopy = JSON.parse(JSON.stringify(_get(form, `fields`, [])));

        formFieldsCopy.forEach((field) => {
            const fieldErrors = _get(formErrors, `${ field.formName }[${ field.name }]`, []);
            const errText = Array.isArray(fieldErrors) ? _get(fieldErrors, '[0]', '') : (typeof fieldErrors === 'string' ? fieldErrors : '');
            field.error = Object.assign(
                {},
                field.error,
                {
                    permanentText: errText
                }
            );
        });
        commit(types.UPDATE_FORM_ERROR_BY_ID, {
            formId,
            text: _get(formErrors, 'commonError', '')
        });
        commit(types.UPDATE_FORM_FIELDS_BY_ID, {
            formId,
            newFieldsData: formFieldsCopy
        });


    },
    async sendForm({ state, commit, getters }, { formId, data }) {
        if (!data) {
            data = getters.getFormFieldsDataThatNeedToBeSend(formId);
        }

        const promises = [];
        const actions = _get(state, `list[${ formId }].action`, {});
        const methods = _get(state, `list[${ formId }].method`, {});
        for (const formName in data) {
            const curAction = actions[formName] || actions['default'] || '';
            const curMethod = methods[formName] || methods['default'] || 'post';
            if (curAction && curMethod) {
                const promise = this.$axios({
                    method: curMethod.toLowerCase(),
                    url: `${ API.main }${ curAction }`,
                    data: {
                        [formName]: data[formName]
                    }
                }).then((response) => {
                    return {
                        formName,
                        response
                    };
                }).catch((reason) => {
                    throw ({
                        formName,
                        value: reason
                    });
                });
                promises.push(promise);
            }
        }
        return Promise.allSettled(promises);

    },
};

export const mutations = {
    [types.ADD_FORM_TO_LIST](state, { formId, formStructure }) {
        state.list = {
            ...state.list,
            [formId]: formStructure
        };
    },
    [types.REMOVE_FORM_LIST](state, formId) {
        delete state.list[formId];
    },
    [types.UPDATE_FORM_ERROR_BY_ID](state, { formId, text }) {
        state.list[formId].error = text;
    },
    [types.UPDATE_FORM_FIELDS_BY_ID](state, { formId, newFieldsData }) {
        state.list = Object.assign({},
            state.list,
            {
                [formId]: {
                    ...state.list[formId],
                    fields: JSON.parse(JSON.stringify(newFieldsData)),

                }
            }
        );
    },
    [types.UPDATE_FORM_FIELD_BY_ID](state, { formId, fieldName, newFieldData }) {
        const fields = JSON.parse(JSON.stringify(_get(state, `list[${ formId }].fields`, [])));
        const fieldIndex = fields.findIndex(el => {
            return el.name === fieldName;
        });
        fieldIndex >= 0 && (fields[fieldIndex] = JSON.parse(JSON.stringify(newFieldData)));
        state.list = Object.assign({},
            state.list,
            {
                [formId]: {
                    ...state.list[formId],
                    fields: fields
                }
            }
        );
    },
    [types.UPDATE_FORM_VALUE_BY_ID](state, { formId, fieldName, value, selectedValue }) {
        const fields = JSON.parse(JSON.stringify(_get(state, `list[${ formId }].fields`, [])));
        const fieldIndex = fields.findIndex(el => {
            return el.name === fieldName;
        });
        fieldIndex >= 0 && (fields[fieldIndex].value = value);
        fieldIndex >= 0 && (fields[fieldIndex].selectedValue = selectedValue || value);

        state.list = Object.assign({},
            state.list,
            {
                [formId]: {
                    ...state.list[formId],
                    fields: fields
                }
            }
        );
    },
    [types.ADD_FIELD_TO_FORM](state, { formId, newFieldData }) {
        const fields = JSON.parse(JSON.stringify(_get(state, `list[${ formId }].fields`, [])));
        fields.push(newFieldData);
        state.list = Object.assign({},
            state.list,
            {
                [formId]: {
                    ...state.list[formId],
                    fields: fields
                }
            }
        );
    },
    [types.CLEAR_ALL_FIELDS](state, { formId, exclusionFields }) {
        const fields = JSON.parse(JSON.stringify(_get(state, `list[${ formId }].fields`, [])));
        fields.forEach(i => {
            if (!exclusionFields.includes(i.name)) {
                i.value = '';
                i.selectedValue = '';
                _set(i, 'state.filled', false);
                _set(i, 'state.validated', false);
                _set(i, 'error.permanentText', '');
                _set(i, 'error.text', '');
                _set(i, 'error.show', false);
            }
        });
        state.list = Object.assign({},
            state.list,
            {
                [formId]: {
                    ...state.list[formId],
                    fields: fields
                }
            }
        );
    },

};
