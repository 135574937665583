//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters,mapActions} from 'vuex'

export default {
    name: "HeaderMenuItem",
    components: {
        DesktopMenuItem: () => import("./DesktopMenuItem"),
        TextItem: () => import("./DesktopMenuItem/components/components/components/textItem")
    },
    props: {
        itemInfo: {
            type: Object,
            required: true
        },
    },
    data: function () {
        return {
        }
    },
    computed: {
        key: function (){
            return this.itemInfo.id
        },

        ...mapGetters({
            'activeMenuItemKey': 'header/getActiveMenuItemKey'
        }),
        hovered: function () {
            return this.activeMenuItemKey ===  this.key
        }
    },
    methods: {
        ...mapActions({
            setHoveredItem: 'header/setHoveredItem'
        }),
        onEnter(){
            this.setHoveredItem(this.itemInfo.id);
        },
        onLeave(){
            this.setHoveredItem('');
        }
    }
}
