//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    name: "HeaderIconBag",

    computed: {
        ...mapGetters({
            inCartCount: 'cart/getCartLength',
            cartList: 'cart/getList',
            getCheckoutMaxStep: 'cart/getCheckoutMaxStep',
        }),
        size() {
            return this.$breakpoint.tabletLandscapeUp ? 48 : 44;
        }
    },

    watch:{
        inCartCount(newVal, oldVal){
            if (this.$router.history.current.path !== '/confirmation') {
                this.$store.dispatch('cart/getCart');
            }
        },
    },
}
