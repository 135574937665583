import { debounce, throttle } from "lodash";

const defaultDelay = 150;
const mapStore = new Map();

function getOptions(modifiers) {
    if (!modifiers) {
        return { delay: defaultDelay };
    }
    let delay = Object.keys(modifiers).map(k => parseInt(k)).find(v => !isNaN(v));
    delay = delay || defaultDelay;
    return { delay };
}

function createCallbackFunc(el, { value, arg }, options) {
    let callBack;
    switch (arg) {
        case "debounce":
            callBack = debounce(() => value(el), options.delay);
            break;
        case "throttle":
            callBack = throttle(() => value(el), options.delay);
            break;
        default:
            callBack = debounce(() => value(el), options.delay);
    }
    return callBack;
}

export default {
    bind(el, binding) {
        const callback = createCallbackFunc(el,binding, getOptions(binding.modifiers) );

        if (!mapStore.has(el)) {
            mapStore.set(el, callback);
        }
        window.addEventListener("resize", callback);
    },
    inserted(el, binding) {
    },
    componentUpdated(el, binding) {
    },
    unbind(el, binding) {
        if (mapStore.has(el)) {
            window.removeEventListener("resize", mapStore.get(el));
            mapStore.delete(el);
        }
    },
};
