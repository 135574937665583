//
//
//
//

import mainLayout from "./layouts/Main";
import SectionContainer from "@/components/common/section/SectionContainer";
import widgetMixin from "@/mixins/widgetMixin";

export default {
    name: "VideoWidget",

    components: {
        SectionContainer,
        mainLayout
    },

    mixins: [widgetMixin],
}
