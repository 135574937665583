//
//
//
//

import mainLayout from "./layouts/Main";
import widgetMixin from "../../mixins/widgetMixin";
export default {
    name: "YeezySaleWidget",
    components: { mainLayout },
    mixins: [widgetMixin],
}
