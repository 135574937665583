//
//
//
//
//
//
//
//
//
//
//

import YeezyInfo from "../yeezyContent/Components/YeezyInfo";
import YeezySizes from "../yeezyContent/Components/YeezySizes";
import YeezyFormData from "../yeezyContent/Components/YeezyFormData";

export default {
    name: "YeezySidebar",
    components: {
        YeezyFormData,
        YeezySizes,
        'yeezy-info': YeezyInfo
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            hasRequest: false,
            hasRequestSize: ''
        }
    },
    computed: {
        isDesktop() {
            return this.$breakpoint.tabletLandscapeUp
        },
    },
    methods: {
        isAlreadyExist(size) {
            this.hasRequest = true;
            this.hasRequestSize = size;
        }
    }
}
