var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.itemsList.length > 0)?_c('div',{directives:[{name:"resizer",rawName:"v-resizer",value:(_vm.getWindowWidth),expression:"getWindowWidth"}],staticClass:"product-carousel"},[(_vm.computedTitle)?_c('SectionHeader',{attrs:{"title":_vm.computedTitle}}):_vm._e(),_vm._v(" "),_c('slide-carousel',{attrs:{"show-dots":true,"id":("" + (_vm.innerData.id)),"top-position":'117px'}},_vm._l((_vm.itemsList),function(item,index){return _c('item',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
                  callback: function (isVisible, entry) {
                      _vm.productVisibilityChanged(isVisible, entry, item, index+1)
                  },
                  once: true,
                  intersection: {
                    threshold: 0.7,
                  },
                }),expression:"{\n                  callback: (isVisible, entry) => {\n                      productVisibilityChanged(isVisible, entry, item, index+1)\n                  },\n                  once: true,\n                  intersection: {\n                    threshold: 0.7,\n                  },\n                }"}],key:index,attrs:{"item":item,"position":index+1,"widget-name":_vm.widgetInfo.name,"slide-width":_vm.slideWidth,"utm":_vm.getCurrentRequestData.utm || ''}})}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }