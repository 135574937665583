export default {
    Home: "Басты",
    home: "Басты",
    storefinder: "Дүкенді іздеу",
    cart: "Себет",
    "order-tracker": "Тапсырысты бақылау",
    favorites: "Таңдаулы модельдер",
    registration: "Тіркеу",
    login: "Профильге кіру",
}
