//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import HeaderMain from "@/widgets/header/HeaderMain/index";
import SmartAppBanner from "~/widgets/header/SmartAppBanner/index.vue";

export default {
    name: "MainHeader",
    components: {
        SmartAppBanner,
        HeaderMain,
        HeaderUspBottom: () => import("./HeaderUspBottom/index"),
        HeaderUspBar: () => import("./HeaderUspBar/index"),
    },
    data() {
        return {
            uspHeight: 0,
            mainHeight: 80,
            uspBottomClosed: false,
            lastScrollTop: 0,
            needHide: false,
            timeoutForPrevScrollUpdate: null
        }
    },
    computed: {
        ...mapGetters({
            getUspHeaderData: 'header/getUspHeaderData',
            pageType: 'dynamicPage/pageType'
        }),
        uspHeaderOpen() {
            let hiddenRoutes = [];
            let matchLink = hiddenRoutes.filter(item => item === _get(this.$route, 'path',''));
            return this.getUspHeaderData?.length > 0 && !matchLink?.length
        },
        headerHeight() {
            return this.enableFixOnScroll ? this.uspHeight + this.mainHeight : 0
        },
        uspBottomHeight() {
            return `-${_get(this.$refs, 'usp.$el.offsetHeight', 0)}px`
        },
        uspBottomIsOpen() {
            return !this.uspBottomClosed;
        },
        enableFixOnScroll() {
            return !['pdp'].includes(this.pageType)
        },
        routeFull() {
            return this.$route.fullPath
        }
    },
    methods: {
        setUspHeightVariable() {
            const header = document.querySelector('.header');
            header.style.setProperty('--usp-height', this.uspBottomHeight)
        },
        handleWindowScroll() {
            if (typeof window !== "undefined" && window && this.enableFixOnScroll) {
                const st = window.pageYOffset || document.documentElement.scrollTop;
                const scrollToBottom = st > this.lastScrollTop;
                this.needHide = st > 100 && !document.body.classList.contains('v-popup-open') && scrollToBottom;
                this.timeoutForPrevScrollUpdate && clearTimeout(this.timeoutForPrevScrollUpdate);
                this.timeoutForPrevScrollUpdate = setTimeout(() => {
                    this.lastScrollTop = window.pageYOffset || document.documentElement.scrollTop
                }, 100)
            }
        },
        calcUspHeight() {
            if (this.uspHeaderOpen) {
                this.uspHeight = this.$breakpoint.laptopSmallUp ? 40 : 45;
            }
        }
    },
    mounted() {
        this.setUspHeightVariable();
        this.calcUspHeight();
        document.addEventListener('resize', this.calcUspHeight)
    },
    watch: {
        getUspHeaderBottom: {
            handler() {
                this.uspBottomClosed = false;
            },
            deep: true
        },
        routeFull: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.needHide = false;
                    this.calcUspHeight()
                }
            }
        },
        pageType: {
            handler() {
                this.uspBottomClosed = false;
            },
            immediate: true
        }
    }
}
