//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Carousel from "@/widgets/carousel/Index";
import SectionTitle from "@/components/common/section/SectionTitle";
import Item from "./Components/Item";
import CommonBtnText from "@/components/common/buttons/TextBtn";
import SlideCarousel from "@/components/common/slideCarousel/index";
import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";
import SectionHeader from "~/components/common/section/SectionHeader";
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import productImpressionsEvent from "~/assets/js/eSputnik/productImpressionsEvent";

const debouncedEsEvent = debounce(productImpressionsEvent, 500);

export default {
    name: "Main",

    components: {
        SectionHeader,
        SlideCarousel,
        CommonBtnText,
        SectionTitle,
        Carousel,
        Item
    },

    mixins: [widgetLayoutMixin],

    props: {
        slideWidth: {
            type: Array,
            required: true
        },
    },

    data() {
        return {
            settings: {
                dots: true,
                variableWidth: true,
                infinite: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 760,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            },
            activeSlide: 0,
            windowWidth: 0,
            itemsList: [],
            mapItemsList: [],
            visibleList: [],
            startSendProductsFromIndex: 0,
            sendTimeOut: null,
            requestType: "request_main",
        };
    },

    computed: {
        ...mapGetters({
            product: "productView/getProduct",
        }),
        computedTitle() {
            return {
                text: this.widgetInfo?.data?.title || "",
                color: "#000000"
            };
        },
        variantId() {
            return this.getCurrentRequestData?.variantId;
        },
        blockId() {
            return this.getCurrentRequestData?.blockId;
        },
        productAvailableSize() {
            return _get(this, "product.sizes", []).find(i => i.isAvailable === true);
        },
        productFeedId() {
            if (!this.product) {
                return false;
            }
            return this.productAvailableSize?.id || false;
        },
        hasReserveRequestData() {
            const reserveRequestData = this.widgetInfo?.data?.request_additional;
            return Boolean(
                reserveRequestData &&
                typeof reserveRequestData === "object" &&
                reserveRequestData.hasOwnProperty("variantId") &&
                reserveRequestData.hasOwnProperty("utm") &&
                reserveRequestData.hasOwnProperty("blockId")
            );
        },
        getCurrentRequestData() {
            return this.widgetInfo?.data?.[this.requestType] || {};
        }
    },

    methods: {
        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
        },
        productVisibilityChanged(isVisible, entry, productInfo, positionInList) {
            if (isVisible) {
                this.$ga.sendViewedOnScrollProduct({
                    name: productInfo.displayName,
                    id: productInfo.id,
                    price: productInfo.price.sale,
                    brand: productInfo.division,
                    category: productInfo.productPath || [],
                    variant: "adidas",
                    position: positionInList,
                    list: this.widgetInfo.name
                });


                const sizeId = this.mapItemsList.find(i => +i.product_id === +productInfo.id) ? this.mapItemsList.find(i => +i.product_id === +productInfo.id).size_id : productInfo.id;
                this.visibleList.push({
                    product_id: ''+sizeId,
                    container_type: this.variantId,
                });

                this.sendTimeOut && clearTimeout(this.sendTimeOut);
                this.sendTimeOut = setTimeout(() => {
                    const productsToSend = this.visibleList.slice(this.startSendProductsFromIndex);
                    if (!productsToSend.length > 0) {
                        return;
                    }
                    debouncedEsEvent(productsToSend);
                    this.startSendProductsFromIndex += productsToSend.length;
                }, 150);
            }
        },
        async getProductsInfo(ids) {
            if (ids.length <= 0) {
                return;
            }
            this.itemsList = await this.$axios.post(`${ API.catalog }/product/list`,
                ids,
                { params: { expand: AppConfig.store.loadCarouselInfo.join(",") } }
            ).then(function(response) {
                return response.data;
            }).catch((e) => {
                return [];
            });
        },

        getEsRecommendation() {
            if (!(this.variantId && this.blockId)) {
                console.error("No variantId or blockId");
                return;
            }
            this.requestType = "request_main";

            if (this.$cookies.get('sc')) {
                this.requestRecommendationData()
                    .then(products => {
                        if (Array.isArray(products) && products.length) {
                            this.generateSliderItems(products);
                        } else if (this.hasReserveRequestData) {
                            this.requestType = "request_additional";
                            this.requestRecommendationData()
                                .then(products => {
                                    this.generateSliderItems(products);
                                });
                        }
                    })
                    .catch(error => {
                        console.error("-> requestRecommendationData error ", error);
                    });
            } else {
                setTimeout(this.getEsRecommendation, 500);
            }

        },

        requestRecommendationData() {
            const options = {
                method: "POST",
                url: "https://ai.softcube.com/graphql",
                data: {
                    query: `{
                            recommendations {
                                products(
                                    isV2: true,
                                    ${ this.productFeedId ? "products: [\"" + this.productFeedId + "\"]" : "" }
                                    cookie: "${this.$cookies.get('sc')}",
                                    blockId: ${ this.blockId },
                                    languageCode: "${ this.$i18n.locale }"
                                ) {
                                    products {
                                        url: urlWithContent(id: "${ this.variantId }", delimiter: "?")
                                        id
                                        name
                                        isInStock
                                        tags_old_price: tagAsString(tag: "old_price")
                                        product_id: tagAsString(tag: "item_group_id")
                                    }
                                    status
                                }
                            }
                        }`
                }
            };

            return this.$axios
                .request(options)
                .then((response) => response?.data?.data?.recommendations?.products?.products || [])
                .catch(function(error) {
                    console.error(error);
                    return [];
                });
        },

        generateSliderItems(products) {
            this.mapItemsList = products.map(i => {
                return {
                    product_id: +i.product_id,
                    size_id: +i.id,
                };
            });
            this.getProductsInfo(products.map(i => +i.product_id));
        },
    },

    mounted() {
        this.$nextTick(function() {
            this.getWindowWidth();
            setTimeout(() => {
                this.getEsRecommendation();
            });
        });
    },
};
