//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonMedia from "@/components/common/CommonMedia";

export default {
    name: "Item",
    components: {
        CommonMedia
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        checkImage() {
            return _get(this, 'item.media.src.desktop.1x', '').length > 0
        }
    }
}
