//
//
//
//
//
//
//
//
//
//
//
//

import YeezySlider from "./Components/YeezySlider";
import YeezyDescription from "./Components/YeezyDescription";
import YeezyInfo from "./Components/YeezyInfo";

export default {
    name: "YeezyContent",
    components: { YeezyInfo, YeezyDescription, YeezySlider },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    computed: {
        isDesktop() {
            return this.$breakpoint.tabletLandscapeUp;
        }
    },
};
