export default {
    fields: {
        phoneNumber: 'Телефон нөмірі',
        fName: 'Аты',
        sName: 'Тегі',
        mName: 'Әкесінің аты',
        date: 'Күні',
        birthDate: 'Туған күні',
        month: 'Айы',
        year: 'Жылы',
        sex: 'Жынысы',
        male: 'Еркек',
        female: 'Әйел',
        notGender: 'Жауап бергім келмейді',
        email: 'Email',
        pass: 'Құпиясөз',
        mobilePhone: 'Ұялы телефон',
        confirmCode: 'Растау коды',
        city: 'Қала',
        street: 'Көше',
        house: 'Үй / Корпус',
        flat: 'Пәтер',
    },
    errors: {
        enterBirthDate: "Туған күнді енгіз",
        incorrectDate: 'Қате күн',
        maxDate: 'Тапсырысты 14 жастан асқан адамдар ғана ресімдей алады.',
        enterConfirmCode:'Кодты енгіз',
        incorrectConfirmCode:'Қате код',
        enterPhone:'Телефон нөмірін енгіз',
        incorrectPhone:'Телефон нөмірі қате',
        enterEmail:'Электрондық пошта мекенжайын енгіз',
        incorrectEmail:'Электрондық пошта мекенжайының дұрыстығын тексер',
        required: 'Жол {field} толтырылуы тиіс',
        number: 'Жолда {field} тек сандар жазылуы тиіс',
        length: 'Жолдың ұзындығы {field} белгі {length} болуы тиіс',
        maxLength: 'Жолдың ұзындығы {field} белгіден {length} кем болуы тиіс',
        minLength: 'Жолдың ұзындығы {field} белгіден {length} артық болуы тиіс',
        maxValue: 'Жолдың мәні {field} көп дегенде {value} боулы тиіс',
        minValue: 'Жолдың мәні {field} аз дегенле {value} болуы тиіс',
        wrongFormat: 'Жолдың {field} форматы қате',
        onlyCyrillic: 'Тек кириллицамен',
        requiredField: 'Міндетті жол',
        wrongDate: 'Күні қате',
        chooseDeliveryAddress: 'Жеткізу мекенжайын таңда',
        recaptcha: 'Робот емес екеніңізді растауыңызды сұраймыз',
    }
}
