//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonScroll from "@/components/common/CommonScroll";

export default {
    name: "YeezySizes",
    components: {CommonScroll},
    data() {
        return {
            formId: 'yeezy_sale_booking_form',
            open: false,
            value: '',
            openTop: false,
            windowHeight: 0,
            maxOptionsHeight: 300,
            fieldName: 'yeezy_size_id'
        }
    },
    props: {
        sizes: {
            type: Array,
            required: true
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    methods: {
        toggleSelector(){
            this.checkPosition()
            this.open = !this.open
        },
        close(){
            this.open = false
            this.openTop = false
        },
        selectValue(size){
            if(size.id !== this.value?.id) {
                this.$emit('change', size)
                this.value = size
                this.$store.commit(`forms/${types.UPDATE_FORM_VALUE_BY_ID}`, {
                    formId: this.formId,
                    fieldName: this.fieldName,
                    value: size.id
                })
            }
            this.close()
        },
        checkPosition(){
            this.openTop = this.$refs.selector.getBoundingClientRect().top > (this.windowHeight - this.maxOptionsHeight);
        },
        onResize() {
            this.windowHeight = window.innerHeight
        }
    },
    computed: {
        hasSizes() {
            return this.sizes?.length
        }
    },
    mounted() {
        this.onResize()
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
}
