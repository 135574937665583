//
//
//
//
//
//
//
//
//
//
//
//
//

import notFound from "@/components/pages/search/PageError";
import ProductCarousel from "@/widgets/productCarousel/Index";
import esEvent from "~/assets/js/eSputnik/esEvent";

export default {
    name: "NotFound",
    components: { ProductCarousel, notFound },
    data() {
        return {
            widgetData: null
        };
    },
    mounted() {
        const widgetId = "widget_we_recommend__automatic";
        this.$axios.post(`${ API.main }/widgets`, [
            widgetId
        ])
            .then((response) => {
                this.widgetData = { data: response.data[widgetId] };
            });

        // eSputnik event
        esEvent("NotFound");
    }
};
