//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import arrow from "~/assets/img/icons/arrow-left.svg?raw";
import {mapGetters} from "vuex";

export default {
    name: "FiltersSort",
    data() {
        return {
            openList: true,
            arrowIcon: arrow,
        }
    },
    computed: {
        ...mapGetters({
            sortRules: 'catalogStore/getSortingLinks',
        }),
        selected() {
            return this.sortRules.find(i => i.selected);
        },
        closeLink() {
            return '/man'
        },
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            } else  {
                return false;
            }
        },
        catalogRoute() {
            return this.$route.path
        },
        selectedSort() {
            return this.sortRules.find(i => i.selected)
        },
        textBtn() {
            return _get(this, 'selectedSort.title', '').length > 0 && this.$breakpoint.tabletSmallUp ? this.selectedSort.title : this.$t('store.sorting');
        },
    },
    methods: {
        togglePopup() {
            this.openList = !this.openList;
        },
        isSelected(url) {
            return this.$route.path.includes(url)
        },
    }
}
