//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";

export default {
    name: "YeezyConfirmSize",
    components: { VPopup },
    props: {
        popupTitle: {
            type: String,
            default() {
                return this.$t('yeezy.orderThank');
            }
        },
        closeBtnData: {
            type: Object,
            default() {
                return {
                    text: this.$t('common.close'),
                    link: '/',
                };
            }
        }
    },
    methods: {
        closePopup(url) {
            this.$popups.close();
            this.$router.push(url);
        }
    },
};
