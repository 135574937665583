//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Carousel from "@/widgets/carousel/Index";
import SectionTitle from "@/components/common/section/SectionTitle";
import SectionSubtitle from "@/components/common/section/SectionSubtitle";
import Item from "./Components/Item";
import SlideCarousel from "@/components/common/slideCarousel/index";
import widgetLayoutMixin from "../../../mixins/widgetLayoutMixin";
import SectionHeader from "~/components/common/section/SectionHeader";

export default {
    name: "Main",

    components: {
        SectionHeader,
        SlideCarousel,
        SectionSubtitle,
        SectionTitle,
        Carousel,
        Item
    },

    mixins: [widgetLayoutMixin],

    data() {
        return {
            settings: {
                dots: false,
                variableWidth: true,
                infinite: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 1130,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 820,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 590,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                ]
            },
            activeSlide: 0,
            windowWidth: 0,
            topPosition: "calc(50% - 25px)",
            dataToSendInGa: [],
            gaSendTimeout: null,
        };
    },

    methods: {
        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
        },
        hasItemInfo(item) {
            return item.title || item.subTitle;
        },
        oneItemViewed(isVisible, entry, item, position) {
            if (!isVisible) {
                return;
            }
            this.dataToSendInGa.push(Object.assign({}, item, {
                position
            }));
            this.gaSendTimeout && clearTimeout(this.gaSendTimeout);

            this.gaSendTimeout = setTimeout(() => {
                this.$ga.viewPromotion(_get(this, "dataToSendInGa", []).map((el) => {
                    let creative = _get(el, "title", "");
                    creative = creative !== "" ? creative : _get(el, "link.text", "");
                    return {
                        name: this.widgetInfo.name,
                        id: this.widgetInfo.id,
                        creative: creative,
                        position: el.position
                    };
                }));
                this.dataToSendInGa = [];
            }, 50);
        },
        oneItemClicked(itemInfo, position) {
            let creative = _get(itemInfo, "title", "");
            creative = creative !== "" ? creative : _get(itemInfo, "link.text", "");
            this.$ga.clickPromotion([{
                name: this.widgetInfo.name,
                id: this.widgetInfo.id,
                creative: creative,
                position: position
            }]);
        },
        onMouseUp(e, itemInfo, position) {
            if (e.button === 1) {
                this.oneItemClicked(itemInfo, position);
            }
        }
    },

    mounted() {
        this.$nextTick(function() {
            this.getWindowWidth();
        });

    },
};
