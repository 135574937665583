//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KidShoesTable from "./KidShoesTable.vue";
import KidClothesTable from "./KidClothesTable.vue";
import ChildShoesTable from "./ChildShoesTable.vue";
import ChildClothesTable from "./ChildClothesTable.vue";
import TeenShoesTable from "./TeenShoesTable.vue";
import TeenClothesTable from "./TeenClothesTable.vue";
import ResponsiveImage from "../../../../common/ResponsiveImage";
import VueSlickCarousel from "vue-slick-carousel";

export default {
    name: "ChildrenShoesSizeTable",
    props: {
        sizeChart: {
            type: String,
            default: ""
        }
    },
    data: function () {
        return {
            kidIsInch: false,
            childIsInch: false,
            teenIsInch: false,
            shoesImage: StaticImages.sizeTableShoesBlock,
            childrenBanner1: StaticImages.childBanner1,
            childrenBanner2: StaticImages.childBanner2,
            childrenBanner3: StaticImages.childBanner3,
            sliderImage1: StaticImages.childSlider1,
            sliderImage2: StaticImages.childSlider2,
            sliderImage3: StaticImages.childSlider3,
            childrenClothesSize: StaticImages.childrenClothesSize,
            childrenShoesSizes: ["size-k_shoes", "size-ki_shoes"],
            childrenClothesSizes: ["size-kg_5-15y", "size-kb_5-16y", "size-ki_1-4y"]
        }
    },
    computed: {
        windowWidth() {
            return document.documentElement.clientWidth;
        },
        imageSrc() {
            return this.isWomenSwimsuits ? this.swimsuitsImage : this.clothesImage;
        },
        shoesSizes() {
            return this.childrenShoesSizes.includes(this.sizeChart);
        },
        clothesSizes() {
            return this.childrenClothesSizes.includes(this.sizeChart);
        },
        carouselSettings() {
            return {
                arrows: false,
                dots: false,
                centerMode: false,
                infinite: false,
                slidesToShow: 2.5,
                swipe: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2.1
                        }
                    },
                    {
                        breakpoint: 520,
                        settings: {
                            slidesToShow: 1.5
                        }
                    },
                    {
                        breakpoint: 375,
                        settings: {
                            slidesToShow: 1.2
                        }
                    },
                    {
                        breakpoint: 320,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            }
        },
    },
    methods: {
        scrollTo(el) {
            return {
                el: el,
                container: '.v-popup__container__body',
                duration: 300,
                lazy: false,
                easing: 'linear',
                cancelable: true,
                x: false,
                y: true
            }
        },
        scrollToElem() {
            if(this.sizeChart === "size-ki_1-4y" || this.sizeChart === "size-ki_shoes"){
                this.$refs.kid && this.$refs.kid.click();
            }else if(this.sizeChart === "size-kg_5-15y" || this.sizeChart === "size-k_shoes"){
                this.$refs.child && this.$refs.child.click();
            } else if(this.sizeChart === "size-kb_5-16y"){
                this.$refs.teen && this.$refs.teen.click();
            }
        },
        imageConfig(url, title = "", alt = "") {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            }
        },
    },
    components: {
        ResponsiveImage,
        KidShoesTable,
        KidClothesTable,
        ChildShoesTable,
        ChildClothesTable,
        TeenShoesTable,
        TeenClothesTable,
        VueSlickCarousel
    },
    mounted() {
        setTimeout(() => {
            this.scrollToElem();
        }, 100);
    }

}
