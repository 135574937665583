export default {
    notFound: 'Таблица не найдена',
    shoes: {
        title: "МУЖСКИЕ И ЖЕНСКИЕ РАЗМЕРЫ ОБУВИ",
        description: "В таблице указана длина стопы. Длина стельки больше длины стопы на величину функционального припуска 10-15 мм.",
        howFindSize: "КАК УЗНАТЬ СВОЙ РАЗМЕР",
        tabs: {
            title1: "дюймы",
            title2: "cm"
        },
        table: {
            headers: {
                inch: {
                    title: "Длина стопы (INCH)"
                },
                centimeter: {
                    title: "Длина стопы"
                }
            },
            body: {
                inch: {
                    subtitle1: "UK",
                    subtitle2: "EU",
                    subtitle3: "US - Мужчины",
                    subtitle4: "US - Женщины",
                    subtitle5: "Длина стопы (см)",
                },
                centimeter: {
                    subtitle1: "KZ",
                    subtitle2: "EU",
                    subtitle3: "US - Мужчины",
                    subtitle4: "US - Женщины",
                    subtitle5: "UK",
                }
            },
            description: "Прокрути, чтобы увидеть больше"
        },
        questions: {
            block1: {
                title: "выбираешь между двумя размерами?",
                description1: "Для более плотной посадки выбери модель на размер меньше.",
                description2: "Для более свободной посадки выбери модель на размер больше."
            },
            block2: {
                title: "КАК УЗНАТЬ СВОЙ РАЗМЕР?",
                description: "Следуй этим простым советам, чтобы определить свой размер. Для лучшего результата, лучше " +
                    "проводить измерения вечером. Обращаем внимание, что длина стельки больше длины стопы на " +
                    "величину функционального припуска 10-15 мм. Просьба ориентироваться на длину стопы, которая " +
                    "указана в таблице размеров.",
                option1: "Поставь ногу на лист бумаги, чтобы пятка касалась стены.",
                option2: "Отметь место, где заканчивается самый длинный палец (возможно тебе понадобится помощь еще " +
                    "одного человека), и измерь расстояние от этой отметки к стене.",
                option3: "Выполни то же самое с другой ногой и сравни измерения с нашей таблицей размеров, чтобы " +
                    "найти нужный размер.",
            },
            block3: {
                title: "Не подошел размер или ЦВЕТ?",
                description: "Ничего страшного, ты можешь бесплатно вернуть товар."
            }
        },
        goUp: "Вверх"
    },
    childrenShoes: {
        title: "ПОДОБРАТЬ РАЗМЕР?",
        anchor1: "МАЛЫШИ (0-4 ГОДА)",
        anchor2: "ДЕТИ (4-8 лет)",
        anchor3: "ПОДРОСТКИ (8-16 лет)",
        anchor4: "КАК УЗНАТЬ СВОЙ РАЗМЕР",
        howFindSize: "КАК УЗНАТЬ СВОЙ РАЗМЕР",
        table: {
            body: {
                subtitle1: "UK",
                subtitle2: "US",
                subtitle3: "EU",
            },
        },
        group1: {
            title: "МАЛЬЧИКИ И ДЕВОЧКИ 0-4 годы",
            bannerTitle: "Малыши"
        },
        group2: {
            title: "МАЛЬЧИКИ И ДЕВОЧКИ 4-8 лет",
            bannerTitle: "ДЕТИ"
        },
        group3: {
            title: "МАЛЬЧИКИ И ДЕВОЧКИ 8-16 лет",
            bannerTitle: "ПОДРОСТКИ"
        },
        buy: "Купить",
        slider: {
            title: "Полезный совет",
            sliderItem1: {
                title: "ОПРЕДЕЛЕНИЕ размера по стельке",
                description: "В обуви для малышей и детей есть измерительная стелька adiFIT. Выньте стельку adiFIT из кроссовок"
            },
            sliderItem2: {
                title: "Поставьте стопу на стельку",
                description: "Попроси ребенка поставить ногу на стельку adiFIT, чтоб легко определить нужный размер"
            },
            sliderItem3: {
                title: "Поставьте стопу на стельку",
                description: "Если стопа НЕ выходит за маркировку, значит, этот размер подойдет."
            }
        }
    },
    childrenClothes: {
        kidTable: {
            headers: {subtitle1: "метка", subtitle2: "0 МЕСЯЦЕВ | 56", subtitle3: "0-3 МЕСЯЦЕВ | 62", subtitle4: "3-6 МЕСЯЦЕВ | 68", subtitle5: "6-9 МЕСЯЦЕВ | 74", subtitle6: "9-12 МЕСЯЦЕВ | 80", subtitle7: "12-18 МЕСЯЦЕВ | 86", subtitle8: "1-2 года | 92", subtitle9: "2-3 года | 98"},
            body: {
                subtitle1: "РОСТ",
                subtitle2: "ГРУДЬ",
                subtitle3: "ТАЛИЯ",
                subtitle4: "БЕДРА",
                subtitle5: "Длина внутреннего шва",
            }
        },
        childTable: {
            headers: {subtitle1: "метка", subtitle2: "3-4 года | 104", subtitle3: "4-5 года | 110", subtitle4: "5-6 года | 116", subtitle5: "6-7 года | 122"},
        },
        teenTable: {
            headers: {subtitle1: "метка", subtitle2: "7-8 года | 128", subtitle3: "8-9 года | 134", subtitle4: "9-10 года | 140", subtitle5: "10-11 года | 146", subtitle6: "11-12 года | 152", subtitle7: "12-13 года | 158", subtitle8: "13-14 года | 164", subtitle9: "14-15 года | 170", subtitle10: "15-16 года | 176",},
        },
        questions: {
            block2: {
                description: "Возьми сантиметр, сними мерки, запиши получившиеся результаты и посмотри в нашей таблице, " +
                    "какой размер подойдет лучше всего. Твой друг или подруга смогут тебе в этом помочь",
                option1: "Держи сантиметр горизонтально, если хочешь измерить:",
                option2: "<span>1. Грудь,</span> Обхват самой выступающим части груди",
                option3: "<span>2. Талия,</span> Объем самой узкой части талии",
                option4: "<span>3. Бедра,</span> Объем самой широкой части бедер, поставив ноги вместе.",
                option5: "Держи сантиметр вертикально, если хочешь измерить следующие параметры:",
                option6: "<span>4. Длина по внутреннему шву,</span> Это расстояние от клина шагового шва до пола",
                option7: "<span>5. Рост,</span> Расстояние от пяток до макушки. При снятии мерок нужно стоять прямо."
            }
        }
    },
    womenSwimsuits: {
        title: "женские купальники",
        table: {
            header: {
                title1: "Размер",
                title2: "Размер<br>KZ",
                title3: "Размер на бирке<br>(D)",
                title4: "Грудь<br>(см)",
                title5: "Талия<br>(см)",
                title6: "Бедра<br>(см)",
                title7: "Туловище<br>(обхват)<br<br>(см)",
            }
        },
        tip1: {
            option4: {
                title: "ВЕРТИКАЛЬНЫЙ Обхват ТУЛУБА",
                description: "Начните измерять от точки основания шеи на правом плече, спуститесь по прямой через туловище и вернитесь к исходной точке со стороны спины, как показано на рисунке."
            }
        },
        tip2: {
            title: "ВЫБЕРИТЕ ПРАВИЛЬНЫЙ РАЗМЕР",
            description1: "Если значение бедер и талии отвечают различным размерам, выберите тот, которому соответствует значение обхвата бедер.",
            description2: "Размер не подошел? Не волнуйтесь! Простая и бесплатная процедура возврата доступна в течение 60 дней. Ознакомьтесь с условиями возврата, чтобы узнать больше."
        }
    },
    womenClothes: {
        title: "ЖЕНСКАЯ ОДЕЖДА",
        table: {
            header: {
                title1: "Размер",
                title2: "Размер<br>KZ",
                title3: "Немецкий размер<br>(D)",
                title4: "Грудь<br>(см)",
                title5: "Талия<br>(см)",
                title6: "Бедра<br>(см)",
            },
            description1: "Для некоторых моделей доступна разная длина:",
            description2: "long - выбери, если хочешь удлиненную модель",
            description3: "short - выбери, если хочешь укороченную модель",
            description4: "tall - выбери, если нужно, чтобы модель была рассчитана на рост выше среднего",
        },
        tip1: {
            title: "КАК ПОДОБРАТЬ СВОЙ<br> РАЗМЕР",
            description: "Следуйте инструкции, чтобы узнать свой размер одежды:",
            option1: {
                title: "ГРУДЬ",
                description: "Чтобы определить свой размер футболки, измерьте Портновским сантиметром обхват самой широкой части груди. Держите сантиметр горизонтально."
            },
            option2: {
                title: "ТАЛИЯ",
                description: "Измерьте объем самой узкой части талии, держа портновский сантиметр горизонтально."
            },
            option3: {
                title: "бедра",
                description: "Поставьте ноги вместе и измерьте объем самой широкой части бедер. Держите портновский сантиметр горизонтально."
            }
        },
        tip2: {
            title: "ВЫБЕРИТЕ ПРАВИЛЬНЫЙ<br> РАЗМЕР",
            description1: "Если значение обхвата груди и талии отвечают различным размерам, выберите тот, которому соответствует значение обхвата груди",
            description2: "Размер не подошел? Не волнуйтесь! Простая и бесплатная процедура возврата доступна в течение 60 дней. Ознакомьтесь с условиями возврата, чтобы узнать больше."
        }
    },
    womenTrousers: {
        title: "ЖЕНСКИЕ БРЮКИ И<br> ШОРТЫ",
        table: {
            header: {
                title1: "Размер",
                title2: "Размер<br>KZ",
                title3: "Немецкий размер<br>(D)",
                title4: "Талия<br>(см)",
                title5: "Бедра<br>(см)",
            }
        },
        tip1: {
            description: "Следующие действия, чтобы определить свой размер одежды:",
            option4: {
                title: "Длина по внутреннему шву",
                description: "Измерьте расстояние от верхней части бедер к полу, как показано на рисунке"
            }
        }
    },
    manTrousers: {
        title: "МУЖСКИЕ БРЮКИ И<br> ШОРТЫ",
    },
    manClothes: {
        title: "МУЖСКАЯ ОДЕЖДА",
    },
    summerShoes: {
        title: "СЛАНЦЫ И САНДАЛИИ",
        subtitle1: "<span>Внимание! На коробке и бирке изделия указаны иностранные размеры</span><br>Узнай соответствие KZ c UK, US, FR по таблице.",
        subtitle2: "Длина стельки больше длины стопы на величину функционального припуска 10-15 мм.",
        tip: {
            title: "ИЗМЕРЬТЕ ДЛИНУ ОТ НОСКА ДО ПЯТКИ",
            description1: "Мы не рекомендуем измерять стопу утром. Обычно ноги слегка отекают к вечеру из-за жары и нагрузок. Лучше всего измерять стопу вечером.",
            description2: "Следуйте инструкции, чтобы определить свой размер обуви:",
            description3: "Положите лист бумаги на пол одной стороной к стене. Поставьте ногу на лист бумаги пяткой к стене. Карандашом отметьте линию кончиков пальцев.",
            description4: "Измерьте расстояние от края листа до отметки и сопоставьте со значением в таблице.",
        },
        tip1: {
            description1: "Легче сказать, чем сделать. Что делать, если Вы оказались в промежутке между двумя размерами?",
            description2: "В этом случае безопаснее выбрать больший размер.",
            description3: "Размер не подошел? Не переживайте! Простая и бесплатная процедура возврата доступна в течение 60 дней. Ознакомьтесь с условиями возврата , чтобы узнать больше.",
        },
        table: {
            header: {
                title1: "KZ",
                title2: "FR / EUR",
                title3: "UK",
                title4: "US МУЖ",
                title5: "US ЖЕН",
                title6: "Длина стопы",
            }
        }
    },
    footballBoots: {
        title: "РУКОВОДСТВО ПО ВЫБОРУ ФУТБОЛЬНОЙ ОБУВИ",
        description: "В таблице указана длина стопы. Длина стельки больше длины стопы на величину функционального припуска 10-15 мм.",
        anchors: [
            {
                title: 'РАЗМЕРНАЯ СЕТКА ОБУВИ',
                link: 'findSize'
            },
            {
                title: 'ПОЛНОТА ОБУВИ',
                link: 'shoesComfortable'
            },
            {
                title: 'МАТЕРИАЛЫ И ПОСАДКА',
                link: 'materialsAndFit'
            },
            {
                title: 'КАК ПРИМЕРИТЬ ФУТБОЛЬНЫЕ БУТСЫ',
                link: 'howToFitShoes'
            },
            {
                title: 'КАК УЗНАТЬ СВОЙ РАЗМЕР',
                link: 'howToKnowSize'
            }
        ],
        sizeTableTitle: 'РАЗМЕРНАЯ СЕТКА ОБУВИ',
        tabs: {
            title1: "Дюймы",
            title2: "cm"
        },
        table: {
            headers: {
                inch: {
                    title: "Длина стопы (INCH)"
                },
                centimeter: {
                    title: "Длина стопы"
                }
            },
            body: {
                inch: {
                    subtitle1: "UK",
                    subtitle2: "EU",
                    subtitle3: "US - Мужчины",
                    subtitle4: "US - Женщины",
                    subtitle5: "Длина стопы (см)",
                },
                centimeter: {
                    subtitle1: "KZ",
                    subtitle2: "EU",
                    subtitle3: "US - Мужчины",
                    subtitle4: "US - Женщины",
                    subtitle5: "UK",
                }
            },
            description: "Проведи пальцем в сторону, чтобы увидеть больше."
        },
        block1: {
            title: "ПОЛНОТА ОБУВИ",
            description1: "Для оптимальной посадки учитывай ширину стопы.",
            description2: "Футбольные бутсы X и Copa рассчитаны на стандартную ширину стопы. Бутсы Predator подойдут на стандартную и более широкую стопу."
        },
        block2: {
            title: "МАТЕРИАЛЫ И ПОСАДКА",
            description: "Чтобы обеспечить идеальное чувство мяча и точность касания, бутсы должны плотно и комфортно облегать стопу. На посадку влияют материалы, используемые при создании верха, шнуровки и голенища.",
            tips:[
                {
                    title: 'Материалы верха',
                    description: "Со временем верх бутс, изготовленный из такого органического материала, как кожа, будет растягиваться. И тогда бутсы станут более свободными. Верх из синтетических материалов, таких как Primeknit или текстильных сеток, практически не растягивается и сохраняет первоначальную форму."
                },
                {
                    title: 'Шнуровка',
                    description: "Футбольные бутсы без шнурков надежно фиксируют стопу. Они практически не ощущаются на ноге. Футбольные бутсы со шнуровкой позволяют регулировать плотность посадки."
                },
                {
                    title: 'Голенище',
                    description: "Высокие бутсы (голенище закрывает всю щиколотку) и низкие бутсы (голенище не достигает или ровно по щиколотку) по-разному ощущаются на ноге."
                }
            ]
        },
        block3: {
            title: "КАК ПРИМЕРИТЬ ФУТБОЛЬНЫЕ БУТСЫ",
            description: "Примерь бутсы вместе с игровой экипировкой, например: гетрами с амортизирующими вставками или без них и щитками с защитой голеностопа.",
            tips: [
               {
                    title: 'Примерь обе бутсы',
                    description: "Так как одна нога может быть больше другой, обязательно примерь обе бутсы."
                },
                {
                    title: 'Правильно зашнуруй бутсы',
                    description: "Зашнуруй, равномерно распределив давление и степень фиксации, и следи, чтобы в области голеностопа не было сборок."
                },
                {
                    title: 'Пройдись',
                    description: "Важно проверить, что бутсы хорошо сидят под твоим весом. Также убедись, что тебя полностью устраивают верх и подошва."
                },
                {
                    title: 'Проверь, чтобы бутсы нигде не давили и не сидели слишком свободно',
                    description: "Комфортная облегающая посадка помогает удерживать стопу на месте во время резкой смены движений."
                }
            ]
        },
        block4: {
            title: "Не подошел РАЗМЕР или ЦВЕТ?",
            description: "Ничего страшного, ты можешь бесплатно вернуть товар."
        },
        goUp: "Вверх"
    }
}
