//
//
//
//
//
//
//
//
//
//

import BnplPartPay from "~/components/popups/BnplPartPay.vue"

export default {
    name: "BnplInfo",
    props: {
        product: {
            type: Object,
            default: () => { return {} }
        }
    },
    components: {BnplPartPay},
    data() {
        return {
            monthCount: 4,
        }
    },
    computed: {
        textInfo() {
            return this.isCheckout ? this.$t('productCard.halykPartTitleWithTerm') : this.$t('productCard.halykPartTitle')
        },
        bnpl() {
            if (Array.isArray(this.product?.wobblers)) {
                return this.product.wobblers.find(el => el.type === 'bnpl') || false;
            } else {
                return false;
            }
        },
        sumPerMonth() {
            return Math.round(this.product?.price?.sale / this.monthCount * 100) / 100 || 0;
        }
    },
    methods: {
        openBnplInfo() {
            this.$popups.close();
            this.$popups.open({
                component: BnplPartPay
            })
        }
    }
}
