import { mapGetters } from 'vuex';
import Bus from "@/plugins/VPopup/utils/bus";
import CookiesPopup from "~/components/popups/CookiesPopup";

export default {
    data() {
        return {
            isCookiesPopUpOpened: false,
            cookiesFormId: 'cookies_form',
            cookiesPopupCookieSatus: false,
            cookiesIntervalShow: null,
            cookiesStorageKey: 'canShowCookies',
            cookiesLocalStorageKey: "cookiesAgreementDate",
        }
    },

    watch: {
        routePath: {
            handler() {
                clearTimeout(this.cookiesIntervalShow)
                this.canShowCookiesPopup();
            }
        }
    },

    computed: {
        ...mapGetters({
            pageType: 'dynamicPage/pageType',
            openedFilters: 'catalogStore/getOpenedFilters',
            mobileMenuOpened: 'header/getMobileMenuStatus',
            openTalkMe: 'user/getOpenTalkMeStatus',
            isSubscribed: 'user/isSubscribed',
        }),
        routePath() {
            return this.$route.fullPath.split('/')
        },
        blockedRoutes() {
            return [
                'account', 'help', 'time-out', 'favorites',
                'cart', 'delivery', 'payment', 'confirmation',
                'login', 'registration'
            ]
        },

        inBlockedPage() {
            return this.routePath.length && !!this.blockedRoutes.find(i => i === this.routePath[1]);
        },
    },

    methods: {
        isTimingEnd() {
            const localStorageCookiesDate = localStorage.getItem(this.cookiesLocalStorageKey);
            const delayToShowInMs = 2592000000; // 30 days

            if (localStorageCookiesDate) {
                const currentDelay = Date.now() - +localStorageCookiesDate;
                return currentDelay > delayToShowInMs;
            }
            return true;
        },
        canShowCookiesPopup() {
            if (!process.browser) {
                return
            }
            if (!this.inBlockedPage && !(this.route === 'dynamicPage' && this.pageType === 'pdp')) {
                this.cookiesIntervalShow = setTimeout(function () {
                    if (!this.isCookiesPopUpOpened && !this.openedFilters.length && !this.mobileMenuOpened && !this.openTalkMe && !this.isSubscribed && this.isTimingEnd()) {
                        this.showCookiesPopup();
                    }
                }.bind(this), 0)
            }
        },
        async showCookiesPopup() {

            this.$popups.close();
            this.$popups.open({
                component: CookiesPopup,
                props: {
                    formId: this.cookiesFormId,
                    source: 'pop_up',
                    title: this.$t('common.cookiePopup.title'),
                    description: this.$t('common.cookiePopup.description'),
                    button: this.$t('common.cookiePopup.button'),
                    localStorageKey: this.cookiesLocalStorageKey,
                }
            })
        }
    },

    created() {
        if (!process.browser) {
            return
        }

        Bus.$on('opened', data => {
            this.isCookiesPopUpOpened = true
        });
        Bus.$on('closed', data => {
            this.isCookiesPopUpOpened = false
        });
        Bus.$on('destroyed', data => {
            this.isCookiesPopUpOpened = false
        });
    },
}
