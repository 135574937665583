export default {
    props: {
        isInch: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        tableHeadData() {
            return this.isInch ? this.tableData.head.inch : this.tableData.head.centimeter;
        },
        tableBodyInchData() {
            return this.isInch ? this.tableData.body.inch : this.tableData.body.centimeter;
        },
        tableBodyData() {
            return this.tableData.body;
        },
        isMobile() {
            return this.$breakpoint.mobileDown;
        },
        windowWidth() {
            return document.documentElement.clientWidth;
        },
        isTable() {
            return this.$breakpoint.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 678) + "px";
        }
    }
}
