//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";
import SectionTitle from "@/components/common/section/SectionTitle";
import Item from "./Components/Item";

export default {
    name: "ImageCollectionMain",
    components: {
        SectionTitle,
        Item,
    },
    mixins: [widgetLayoutMixin],
    data: function () {
        return {
            gaSendTimeout: null,
            dataToSendInGa: []
        }
    },
    computed: {},
    methods: {
        getClassIndex(index) {
            return `n${ index % 4 + 1 }`;
        },

        oneCardViewed(isVisible, entry, item, position) {
            if(!isVisible){
                return
            }
            this.dataToSendInGa.push(Object.assign({},item, {
                position
            }));
            this.gaSendTimeout && clearTimeout(this.gaSendTimeout);
            this.gaSendTimeout = setTimeout(()=>{
                this.$ga.viewPromotion(_get(this, 'dataToSendInGa', []).map((el) => {
                    let creative = _get(el, 'title', '');
                    creative = creative !== '' ? creative : _get(el, 'title', '');
                    return {
                        name: this.widgetInfo.name,
                        id: this.widgetInfo.id,
                        creative: creative,
                        position: el.position
                    }
                }))
                this.dataToSendInGa = []
            },50)
        },
        oneItemClicked(itemInfo, position) {
            let creative = _get(itemInfo, "title", "");
            creative = creative !== "" ? creative : _get(itemInfo, "link.text", "");
            this.$ga.clickPromotion([{
                name: this.widgetInfo.name,
                id: this.widgetInfo.id,
                creative: creative,
                position: position
            }]);
        },
        onMouseUp(e, itemInfo, position) {
            if (e.button === 1) {
                this.oneItemClicked(itemInfo, position);
            }
        }
    }
};
