//
//
//
//
//
//
//

import HalykPartPay from "~/components/popups/HalykPartPay.vue"
import halykLogo from '~/assets/img/icons/HalykEpay.svg';
export default {
    name: "HalykInfo",
    props: {
        isCheckout: {
            type: Boolean,
            default: false
        }
    },
    components: {HalykPartPay},
    data() {
        return {
            halykLogo: halykLogo,
        }
    },
    computed: {
        textInfo() {
            return this.isCheckout ? this.$t('productCard.halykPartTitleWithTerm') : this.$t('productCard.halykPartTitle')
        }
    },
    methods: {
        openHalykInfo() {
            this.$popups.close();
            this.$popups.open({
                component: HalykPartPay
            })
        }
    }
}
