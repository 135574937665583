export default {
    // SUBSCRIBE
    subscribe: {
        buttonText: 'Таратпаға жазыл',
        mainText: 'Жаңалыққа жазыл және -10% жеңілдік ал'
    },

    // LOGIN AND CART
    loginAndCart: {
        myAccount: 'Менің аккаунтым',
        login: 'Кіру',
        cart: 'Себет',
        toTop: 'Үстіге'
    },

    // FOOTER
    footer: {
        followUs: 'БІЗДІ БАҚЫЛАП ОТЫР',
        legalInfo: 'Заңгерлік ақпарат',
        privacyData: 'Дербес деректерді өңдеу',
        rules: 'Шарттар',
        rights: '"АДИДАС" ЖШС. Барлық құқықтар қорғалған.'
    }
}
