//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import closeIcon from '~/assets/img/icons/closeMenu.svg?raw';
export default {
    name: "HeaderMobileMenu",
    components: {
        MobileMenuCategories: () => import("@/widgets/header/HeaderMain/components/HeaderMobileMenu/components/MobileMenuCategories/index"),
        MobileMenuItems: () => import("@/widgets/header/HeaderMain/components/HeaderMobileMenu/components/MobileMenuItems/index"),
        MobileMenuColumns: () => import("@/widgets/header/HeaderMain/components/HeaderMobileMenu/components/MobileMenuColumns/index")
    },
    data() {
        return {
            closeIcon: closeIcon,
            showedNow: false
        }
    },
    computed: {
        ...mapGetters({
            menu: 'header/getMenuMobile',
            activeCategory: 'header/getMobileMenuActiveCategory',
            activeColumn: 'header/getMobileMenuActiveColumn',
            showMenu: 'header/getMobileMenuStatus'
        }),
        hasActiveCategory() {
            return !!this.activeCategory;
        },
        hasActiveColumn() {
            return this.hasActiveCategory && this.activeColumn
        },
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            } else {
                return false
            }
        }
    },
    methods: {
        hideMenu() {
            this.showedNow = false;
            this.body && this.body.classList.remove('v-popup-open');
            setTimeout(function () {
                this.$liveChat && this.$liveChat.show();
                this.$store.dispatch('header/setMobileStatus', false);
            }.bind(this), 600);
        }
    },
    watch: {
        showMenu(val) {
            setTimeout(() => {
                this.showedNow = val
            })
        },
        $route ( newVal, oldVal) {
            if (this.showMenu && newVal !== oldVal) {
                this.hideMenu()
            }
        }
    },
}
