export default {
    search: 'Іздеу',
    language: 'қазақ',
    currentLanguage: 'қазақша',
    siteCounty: 'Қазақстан',
    siteCountyFlag: 'Қазақстанның Туы',
    subscribe: 'Жазылу',
    subscribeTo: 'Таратпаға жазылу',
    pasteEmail: 'Е-mail жаз',
    aboutUs: 'Біз туралы',
    deliveryAndPayment: 'Жеткізу және төлеу',
    returns: 'Қайтару',
    contacts: 'Байланыс деректері',
    privacyPolicy: 'Құпиялылық саясаты'
}
