//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import StoreListItem from "@/components/pages/store/StoreListItem/index";

export default {
    name: "FeatureProductWidget",


    data: function () {
        return {
            gaSendTimeout: null,
            dataToSendInGa: []
        }
    },
    components: {
        StoreListItem,
    },

    computed: {
        ...mapGetters({
            routeInfo: "dynamicPage/getRouteInfo",
            getCurrentPage: "catalogStore/getCurrentPage",
            getPerPageCount: "catalogStore/getPerPageCount",
            widgetsDataLoading: "dynamicPage/getWidgetsDataLoading",
            pageLoading: "dynamicPage/getDynamicPageLoading",
            macroPageStructure: "dynamicPage/getMacroPageStructure",
            microPageStructure: "dynamicPage/getMicroPageStructure",
            pageType: "dynamicPage/pageType"
        }),

        hasFeatureWidget() {
            return this.microPageStructure.filter((block) => {
                return block.name === "widget_featured_product_card";
            });
        },

    },
    methods: {
        productVisibilityChanged(isVisible, entry, productInfo, list, positionInList) {
            if (isVisible) {
                this.$ga.sendViewedOnScrollProduct({
                    name: productInfo.displayName,
                    id: productInfo.id,
                    price: productInfo.price.sale,
                    brand: productInfo.division,
                    category: productInfo.productPath || [],
                    variant: "adidas",
                    position: positionInList,
                    list: list
                });
            }
        }
    }

};
