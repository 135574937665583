//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "SummerShoesTable",
    data: function () {
        return {
            tableHeadData: [
                this.$t('sizeTable.summerShoes.table.header.title1'),
                this.$t('sizeTable.summerShoes.table.header.title3'),
                this.$t('sizeTable.summerShoes.table.header.title2'),
                this.$t('sizeTable.summerShoes.table.header.title4'),
                this.$t('sizeTable.summerShoes.table.header.title5'),
                this.$t('sizeTable.summerShoes.table.header.title6')
            ],
            tableBodyData: [
                [
                    "35",
                    "3.5",
                    "36",
                    "4",
                    "5",
                    "22.1 см",
                ],
                [
                    "35.5",
                    "4",
                    "36 2/3",
                    "4.5",
                    "5.5",
                    "22.5 см",
                ],
                [
                    "36",
                    "4.5",
                    "37 1/3",
                    "5",
                    "6",
                    "22.9 см",
                ],
                [
                    "36.5",
                    "5",
                    "38",
                    "5.5",
                    "6.5",
                    "23.3 см",
                ],
                [
                    "37",
                    "5.5",
                    "38 2/3",
                    "6",
                    "7",
                    "23.8 см",
                ],
                [
                    "38",
                    "6",
                    "39 1/3",
                    "6.5",
                    "7.5",
                    "24.2 см",
                ],
                [
                    "38.5",
                    "6.5",
                    "40",
                    "7",
                    "8",
                    "24.6 см",
                ],
                [
                    "39",
                    "7",
                    "40 2/3",
                    "7.5",
                    "8.5",
                    "25 см",
                ],
                [
                    "40",
                    "7.5",
                    "41 1/3",
                    "8",
                    "9",
                    "25.5 см",
                ],
                [
                    "40.5",
                    "8",
                    "42",
                    "8.5",
                    "9.5",
                    "25.9 см",
                ],
                [
                    "41",
                    "8.5",
                    "42 2/3",
                    "9",
                    "10",
                    "26.3 см",
                ],
                [
                    "42",
                    "9",
                    "43 1/3",
                    "9.5",
                    "10.5",
                    "26.7 см",
                ],
                [
                    "42.5",
                    "9.5",
                    "44",
                    "10",
                    "11",
                    "27.1 см",
                ],

                [
                    "43",
                    "10",
                    "44 2/3",
                    "10.5",
                    "11.5",
                    "27.6 см",
                ],


                [
                    "44",
                    "10.5",
                    "45 1/3",
                    "11",
                    "12",
                    "28 см",
                ],
                [
                    "44.5",
                    "11",
                    "46",
                    "11.5",
                    "12.5",
                    "28.4 см",
                ],
                [
                    "45",
                    "11.5",
                    "46 2/3",
                    "12",
                    "13",
                    "28.8 см",
                ],
                [
                    "46",
                    "12",
                    "47 1/3",
                    "12.5",
                    "13.5",
                    "29.3 см",
                ],

                [
                    "46.5",
                    "12.5",
                    "48",
                    "13",
                    "14",
                    "29.7 см",
                ],
                [
                    "47",
                    "13",
                    "48 2/3",
                    "13.5",
                    "14.5",
                    "30.1 см",
                ],
                [
                    "48",
                    "13.5",
                    "49 1/3",
                    "14",
                    "15",
                    "30.5 см",
                ],
                [
                    "49",
                    "14",
                    "50",
                    "14.5",
                    "15.5",
                    "31 см",
                ],
                [
                    "49.5",
                    "14.5",
                    "50 2/3",
                    "15",
                    "-",
                    "31.4 см",
                ],
                [
                    "50",
                    "15",
                    "51 1/3",
                    "16",
                    "-",
                    "31.8 см",
                ],
                [
                    "51",
                    "16",
                    "52 2/3",
                    "17",
                    "-",
                    "32.6 см",
                ],
                [
                    "52",
                    "17",
                    "53 1/3",
                    "18",
                    "-",
                    "33.5 см",
                ],
                [
                    "53",
                    "18",
                    "54 2/3",
                    "19",
                    "-",
                    "34.3 см",
                ],
                [
                    "54",
                    "19",
                    "55 2/3",
                    "20",
                    "-",
                    "35.2 см",
                ],
            ]
        }
    }
}
