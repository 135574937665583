import './assets/style/wrapper.scss'
import Bus from './utils/bus'
import { cloneDeep } from 'lodash'
const PopupCmp = async () => {
    return await import('./popup')
};


export default {
    name: 'wrapper',
    data() {
        return {
            popups: [],
            prevPopups: []
        }
    },
    mounted() {
        if (typeof document !== 'undefined') {
            document.body.addEventListener('keyup', this.handleEscapeKey);
        }
    },
    destroyed() {
        if (typeof document !== 'undefined') {
            document.body.removeEventListener('keyup', this.handleEscapeKey);
        }
    },
    created() {
        Bus.$on('new', options => {
            const defaults = {
                title: '',
                className: '',
                dismissable: true,
                isTop: false,
                isBottom: false,
                isLeft: false,
                isRight: false,
                isTopTablet: false,
                isBottomTablet: false,
                isScroll: false,
                escapable: false,
                onClose() {
                },
                onDismiss() {
                }
            };

            let instance = {};
            let rendered;
            if (options.component.template) {
                rendered = false
            } else {
                rendered = !!_get(options, 'component.components.VPopup', null)
            }

            const usedAsFullComponent = rendered
            instance = {
                isVPopup: usedAsFullComponent,
                options: options,
            };

            rendered = null;
            this.popups.push(instance);

            Bus.$emit('opened', {
                index: this.$last,
                instance
            });
            this.body && this.body.classList.add('v-popup-open');
            this.$breakpoint.isMacOs && this.body.classList.add('isMacOs');

        });

        Bus.$on('close', payload => {
            let index = null;

            if (payload.data && payload.data.$index)
                index = payload.data.$index;

            if (index === null)
                index = this.$last;

            this.close(payload.data, index, payload.saveState);
        });

        Bus.$on('dismiss', index => {
            if (index === null)
                index = this.$last;

            this.dismiss(index);
        });
    },
    methods: {
        splice(index = null) {

            if (!this.popups.length || index === -1)
                return;

            if (index === null)
                this.popups.pop();
            else
                this.popups.splice(index, 1);

            if (!this.popups.length) {
                this.body && this.body.classList.remove('v-popup-open');
                Bus.$emit('destroyed');
            }
        },

        doClose(index) {
            if (!this.popups.length)
                return;

            if (!this.popups[index])
                return;
            this.splice(index);

        },

        close(data = null, index = null, savePrevState = false, restorePrevState = true) {
            if (this.popups.length === 0)
                return;

            let localIndex = index;

            if (index && typeof index === 'function') {
                localIndex = index(data, this.popups);
            }

            if (typeof localIndex !== 'number')
                localIndex = this.$last;

            savePrevState && (this.prevPopups = cloneDeep(this.popups));

            Bus.$emit('closed', {
                index: localIndex,
                instance: this.popups[index],
                data
            });

            if (localIndex !== false && this.popups[localIndex]) {
                if (_get(this, `popups[${localIndex}].options.onClose`, ()=> true)(data) === false){
                    return
                }
            }
            this.doClose(localIndex);




        },
        restorePrevState(prevPopups = []){
            if ( !(this.prevPopups.length > 0) || !(prevPopups.length > 0) ) {
                return
            }
            this.popups = prevPopups ? prevPopups : this.prevPopups;
            this.clearPrevState()
        },
        clearPrevState () {
            this.prevPopups = []
        },

        dismiss(index = null) {
            let localIndex = index;

            if (index && typeof index === 'function')
                localIndex = index(this.$last);

            if (typeof localIndex !== 'number')
                localIndex = this.$last;

            if (_get(this, `popups[${localIndex}].options.onDismiss`, ()=> true)() === false)
                return;

            Bus.$emit('dismissed', {
                index: localIndex,
                instance: this.popups[localIndex]
            });

            this.doClose(localIndex);
        },

        handleTabKey(e) {
            if (e.keyCode === 9 && this.popups.length) {
                e.preventDefault()
            }
        },

        handleEscapeKey(e) {
            if (e.keyCode === 27 && this.popups.length) {
                if (!this.popups.length)
                    return;
                if (this.current.options.escapable)
                    this.dismiss();
            }
        },
        onClose(dismissable, index, restorePrevState = true) {
            const prevPopups = this.prevPopups;
            dismissable && this.dismiss(index)
            restorePrevState && this.restorePrevState(prevPopups);
            this.clearPrevState()
        }
    },
    computed: {
        current() {
            return this.popups[this.$last];
        },
        $last() {
            return this.popups.length - 1;
        },
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            }
        },
    },
    render(h) {
        if (!this.popups.length) {
            return null
        }

        let popups = this.popups.map((modal, index) => {
            let modalComponent;

            if (modal.isVPopup) {
                modalComponent = h(modal.options.component, {
                    props: Object.assign({}, {vPopup: Object.assign({disabled: index != this.$last})}, modal.options.props),
                    on: Object.assign({},modal.options.on,{
                        close: () => {
                            this.onClose(_get(modal, 'options.dismissable', true), index)
                        }
                    })
                })
            } else {
                modalComponent = h(PopupCmp, {
                    props: Object.assign(modal.options, {disabled: index != this.$last}),
                    on: Object.assign({},modal.options.on,{
                        close: () => {
                            this.onClose(_get(modal, 'options.dismissable', true), index)
                        }
                    })
                }, [
                    h(modal.options.component, {
                        props: modal.options.props
                    })
                ])
            }
            return h('div', {
                class: ['v-popup__mask', {'v-popup__mask--disabled': index != this.$last}],
                key: index
            }, [
                modalComponent
            ])
        });

        return h('div', {
            class: 'v-popup__wrapper',
        }, [popups])
    }
};
