//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "HeaderIconMenu",
    computed: {
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            } else {
                return false
            }
        }
    },
    methods: {
        onClick() {
            this.$emit('click');
            this.$liveChat && this.$liveChat.hide();
            this.body && this.body.classList.add('v-popup-open');
            this.$breakpoint.isMacOs && this.body.classList.add('isMacOs');
            this.$store.commit('header/SET_ACTIVE_MOBILE_MENU', true);
        }
    }
}
