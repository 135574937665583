//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderStaticLanguage from "./HeaderStaticLanguage";
import {mapGetters} from "vuex";
import SubscribePopup from "@/components/popups/SubscribePopup/index";

export default {
    name: "HeaderStatic",

    components: {HeaderStaticLanguage},
    props: {
        items: {
            type: Array,
            default: () => ([])
        },
        disable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            formId: 'subscription_form'
        }
    },
    computed: {
        ...mapGetters({
            isGuest: 'user/isGuest',
            userInfo: 'user/getUserInfo'
        }),
        loginLink() {
            return !this.isGuest ? { url: '/account', title: `${this.$t('account.welcomeHead.hi')}, ${_get(this, 'userInfo.firstName', '')}` } : { url: '/login', title: this.$t('common.login') };
        }
    },
    methods: {
        async openSubscribePopup() {
            await this.$store.dispatch(`forms/loadFormByIdWithData`, {formId: this.formId, data: {source: 'footer'}});
            this.$popups.close();
            this.$popups.open({
                component: SubscribePopup,
                props: {
                    formId: this.formId,
                    source: 'footer'
                }
            })
        }
    }
}
