export default {
    search: 'Поиск',
    language: 'русский',
    currentLanguage: 'русский',
    siteCounty: 'Казахстан',
    siteCountyFlag: 'Флаг Казахстана',
    subscribe: 'Подписаться',
    subscribeTo: 'Подписаться на рассылку',
    pasteEmail: 'Введи e-mail',
    aboutUs: 'О нас',
    deliveryAndPayment: 'Доставка и оплата',
    returns: 'Возврат',
    contacts: 'Контакты',
    privacyPolicy: 'Политика конфиденциальности'
}
