//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex"
import ProductSlider from "@/components/pages/productCard/ProductContent/Components/ProductSlider/index";
import Breadcrumbs from "@/components/common/Breadcrumbs"
import ProductBullets from "@/components/pages/productCard/ProductContent/Components/ProductBullets/index"
import ProductCare from "@/components/pages/productCard/ProductContent/Components/ProductCare/index"
import ProductDescription from "@/components/pages/productCard/ProductContent/Components/ProductDescription/index";
import ProductWidgets from "@/components/pages/productCard/ProductContent/Components/ProductWidgets/index";
import ProductChooseSize from "@/components/pages/productCard/ProductContent/Components/ProductChooseSize/index";
import ProductMobileBottom
    from "@/components/pages/productCard/ProductContent/Components/ProductMobileBottom/index";
import ProductMobileTop from "@/components/pages/productCard/ProductContent/Components/ProductMobileTop/index";
import ProductNavigations from "@/components/pages/productCard/ProductContent/Components/ProductNavigations/index";
import ProductColors from "@/components/pages/productCard/ProductContent/Components/ProductColors/index";

export default {
    name: "ProductContent",
    components: {
        ProductColors,
        ProductNavigations,
        ProductMobileTop,
        ProductMobileBottom,
        ProductChooseSize,
        ProductWidgets,
        ProductDescription,
        Breadcrumbs,
        ProductSlider,
        ProductBullets,
        ProductCare,
    },
    props: {
        product: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            fadeInFlag: true,
        }
    },
    computed: {
        ...mapGetters({
            breadcrumbs: 'dynamicPage/breadcrumbsData',
            pageType: 'dynamicPage/pageType',
            widgetsDataLoading: 'dynamicPage/getWidgetsDataLoading'
        }),
        isTabletDown() {
            return this.$breakpoint.tabletDown;
        }
    },
    mounted() {
        if(process.browser) {
            this.fadeInFlag = true
        }
    },
    watch:{
        product:{
            handler(newVal, oldVal){
                let newValId = _get(newVal, 'id', null),
                    oldValId = _get(oldVal, 'id', null);
                if(!!newVal && newValId !== oldValId) {
                    this.$ga.sendProductPageOpen({
                        name: this.product.displayName,
                        id: this.product.id,
                        price: this.product.price.sale,
                        brand: this.product.division,
                        category: this.product.productPath || [],
                        variant: 'adidas',
                    })
                }
            },
            immediate: true
        }
    }
}
