export default {
    save:'Сохранить',
    choseLang: 'Изменить язык',
    edit:'Редактировать',
    noResults: 'Нет результатов',
    region: 'обл.',
    district: 'р.',
    warehouse: 'Отделение',
    seeAll: 'Смотреть все',
    clear: 'Очистить',
    clearAll: 'Очистить все',
    apply: 'Применить',
    show: 'Показать',
    hide: 'Скрыть',
    back: 'Назад',
    toCart: 'Смотреть корзину',
    toCheckout: 'Оформить',
    continueShopping: 'Продолжить покупки',
    needHelp: 'Нужна помощь?',
    contacts: 'Контакты',
    delivery: 'Доставка',
    return: 'Возврат',
    loyalty: 'Программа лояльности',
    continue: 'Продолжить',
    login: 'Войти',
    colorsCount: 'Цветов',
    nextPage: 'Следующая',
    prevPage: 'Предыдущая',
    page: 'Страница',
    from: 'из',
    toTop: 'Вверх',
    productCount: {
        1: 'товар',
        2: 'товара',
        3: 'товаров',
    },
    subscribeBtn: 'подписка на новости',
    product: 'Продукты',
    openFullScreen: 'Открыть на всю стораницу.',
    close: 'Закрыть',

    sap: {
        title: 'adidas app',
        description: 'попробуй приложение',
        openApp: 'открыть',
    },

    signUp: {
        blockAgree: {
            title: 'ГОТОВО',
            agreeDesc: 'Подписка успешно оформлена. {0} был добавлен в список рассылки.',
        },
        form: {
            title: 'Подпишись и получи скидку -10% на первую покупку!',
            subtitle: 'Подпишись и узнавай первым о новых поступлениях коллекций.',
            sex: 'Пол',
            male: 'Женский',
            female: 'Мужской',
            noAnswer: 'Не хочу отвечать',
            btn: 'Подписаться',
            info1: 'Нажимая кнопку выше «Подписаться», я даю ТОО «АДИДАС» свое',
            link1: 'согласие на обработку персональных данных и получение информационных рассылок',
            info2: 'и соглашаюсь с',
            link2: 'Политикой по обработке персональных данных',
            text: 'Нажимая кнопку «Подписаться», я даю ТОО «АДИДАС» свое <a href="https://media.adidas.kz/personal_data_accept.pdf" target="_blank">согласие на обработку персональных данных и получение информационных рассылок</a> и соглашаюсь с <a href="https://media.adidas.kz/personal_data_policy.pdf" target="_blank">Политикой по обработке персональных данных</a>',
        },
        agree: {
            title: 'Остался один клик',
            text: 'Письмо со ссылкой для подтверждения подписки отправлено на почту',
            btn: 'Ок',
        },
        thanks: {
            title: 'Спасибо за подписку',
            text: 'Теперь ты будешь получать новости и рассылки от adidas. Проверяй свой почтовый ящик, чтобы быть в курсе будущих релизов.'
        },
    },

    days: {
        Monday: 'ПН',
        Tuesday: 'ВТ',
        Wednesday: 'СР',
        Thursday: 'ЧТ',
        Friday: 'ПТ',
        Saturday: 'СБ',
        Sunday: 'ВС',
    },
    weekend: 'Выходной',
    askQuestion: 'Задать вопрос',
    soldLabelText: 'нет в наличии',

    unsuccessfulPayment: {
        title: 'Ошибка оплаты',
        text: 'К сожалению, произошла ошибка и оплата не прошла.<br />Пожалуйста, создай заказ заново',
        btn: 'Закрыть',
    },

    bnpl: {
        name: 'РАССРОЧКА <span class="no-wrap">0-0-4</span> ОТ BNPL',
        description: 'Покупай любимые товары adidas как тебе удобно',
        body: {
            text1: 'Покупай в рассрочку на adidas.kz без комиссий и переплат с сервисом 0-0-4 от BNPL (ТОО «Центр Лизинг»)',
        },
        advantages: {
            title: 'ПРЕИМУЩЕСТВА',
            item1: 'Моментальное одобрение',
            item2: 'Погашение картой любого банка',
            item3: 'Досрочное погашение без комиссий и переплат',
        },
        steps: {
            title: 'КАК ОФОРМИТЬ ПОКУПКУ В РАССРОЧКУ',
            item1: 'Выбери товар на сайте adidas.kz',
            item2: 'На странице оплаты выбери «Оплатить в рассрочку 0-0-4» чтобы перейти к оформлению на сайте BNPL',
            item3: 'Укажи номер телефона и ИИН на платформе BNPL',
            item4: 'Сохрани данные своей банковской карты для списания ежемесячного платежа. Первый платеж спишется через 30 дней после отправки товара.',
        },
        rules: {
            title: 'УСЛОВИЯ ПОКУПКИ ТОВАРА В РАССРОЧКУ 0-0-4',
            item1: 'Возраст от 21 года до 60 лет',
            item2: 'Действующая карта любого банка Республики Казахстан со сроком действия не менее 4 месяцев',
            item3: 'Сумма заказа от 6,000 до 200,000 тенге',
            item4: 'Гражданство Республики Казахстан',
        },
    },

    halykPart: {
        title: {
            main: 'НomEbank Рассрочка',
            secondary: 'Без процентов и переплат!',
        },
        body: {
            text1: 'Оформи рассрочку на 3 месяца без переплат.',
            text2: 'Обрати внимание: для покупки тебе должно быть не менее 18 лет и ты должен быть резидентом Республики Казахстан.',
        },
        advantages: {
            title: 'Преимущества',
            item1: 'Быстрое оформление рассрочки - до 3 месяцев.',
            item2: '0% за оформление рассрочки.',
            item3: 'Досрочное погашение без комиссий и переплат.',
            item4: 'Сумма рассрочки от 4 000 тенге до 2 000 000 тенге.',
        },
        steps: {
            title: 'Как приобрести товар в рассрочку от Halyk bank',
            item1: 'Выбери товар на сайте',
            item2: 'На платежной странице выбери способ оплаты "Оплатить в рассрочку с Halyk Bank".',
            item3: 'Отсканируй QR c помощью Halyk Homebank.',
            item4: 'Пройди скоринг.',
        }
    },

    cookiePopup: {
        title: "Отслеживаем cookies для лучшего опыта на сайте ADIDAS",
        description: "Выбрав \"Принять cookies\", вы разрешаете adidas использовать файлы cookie, пиксели, теги и другие подобные технологии. Мы используем их для сбора информации про ваше устройство и браузер, чтобы отслеживать ваши действия на сайте в маркетинговых и функциональных целях, таких как персонализация рекламы и улучшения веб-сайта. adidas может передавать эту информацию третим лицам в маркетинговых целях, включая рекламных партнеров в социальных сетях Google, Meta. Перейдите к Положению о конфиденциальности.",
        button: "Принять Cookies",
    },
}
