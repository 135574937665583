//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import checkboxList
    from "@/components/pages/store/StoreFilter/Components/FilterBar/Components/FiltersList/Components/FiltersListItem/Components/CheckboxList";
import checkboxGrid
    from "@/components/pages/store/StoreFilter/Components/FilterBar/Components/FiltersList/Components/FiltersListItem/Components/CheckboxGrid";
import checkboxColors
    from "@/components/pages/store/StoreFilter/Components/FilterBar/Components/FiltersList/Components/FiltersListItem/Components/CheckboxColors";
import priceList
    from "@/components/pages/store/StoreFilter/Components/FilterBar/Components/FiltersList/Components/FiltersListItem/Components/PriceList";
import CommonScroll from "@/components/common/CommonScroll";
import arrow from "~/assets/img/icons/arrow-bottom-small.svg?raw";
import arrowBig from "~/assets/img/icons/arrow-left.svg?raw";
import { mapGetters } from "vuex";


export default {
    name: "FiltersListItem",

    components: {
        CommonScroll,
        "color-checkbox-list": checkboxColors,
        "grid-checkbox-list": checkboxGrid,
        "checkbox-list": checkboxList,
        "simple-checkbox-list": priceList
    },

    props: {
        filter: {
            type: Object,
            default: () => {
                return {};
            }
        },
        filterKey: {
            type: [String, Number],
            required: true
        }
    },

    computed: {
        ...mapGetters({
            getOpenedFilters: "catalogStore/getOpenedFilters",
        }),
        opened() {
            return this.getOpenedFilters.includes(this.filterKey);
        },
        arrow() {
            return this.$breakpoint.laptopSmallUp ? arrow : arrowBig;
        },
        appliedValues() {
            let values = this.filter.values.filter(i => i.selected),
                text = "";

            values.reduce((acc, item, index) => {
                text += (index ? ", " : "");
                text += item.title;
            }, "");

            return text;
        },
        filterType() {
            return this.filter.viewSubType || "checkboxList";
        },
        isDesktop() {
            return this.$breakpoint.laptopSmallUp;
        }
    },

    methods: {
        toggleSelect() {
            const computedCommit = `catalogStore/${ this.opened ? types.REMOVED_OPENED_FILTER : types.ADD_OPENED_FILTER }`;
            this.$store.commit(computedCommit, this.filterKey);
        },
        closeSelect() {
            this.$store.commit(`catalogStore/${ types.REMOVED_OPENED_FILTER }`, this.filterKey);
        },
        closeOnOuter() {
            if (this.$breakpoint.laptopSmallUp && this.opened) {
                this.closeSelect();
            }
        }
    }
};
