//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";
import CommonForm from "@/components/common/CommonForm";
import LazyFormRow from "@/components/common/LazyFormRow/index";
import types from "@/store/mutation-types";
import popupWithForm from "@/mixins/popupWithForm";
import InfoPopup from "~/components/common/infoPopup";
import FormRecaptca from "~/components/common/LazyFormRow/components/components/FormRecaptca.vue";

export default {
    name: "WaitListSubscribe",

    components: {
        FormRecaptca,
        VPopup,
        CommonForm,
        LazyFormRow
    },

    mixins: [popupWithForm],

    props: {
        unavailableSizes: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        selectedPropSize: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            changedSize: null,
            choseSizeFormFieldName: "trade_offer_id"
        };
    },

    computed: {
        chosenSize() {
            return this.changedSize || this.selectedPropSize;
        },
        selectSizeFormField() {
            return this.fields.find(el => el.name === this.choseSizeFormFieldName);
        }
    },

    watch: {
        chosenSize: {
            handler(newVal, oldVal) {
                if (newVal && typeof newVal === "object" && this.selectSizeFormField) {
                    const modifiedFiled = Object.assign({}, this.selectSizeFormField);
                    modifiedFiled.value = newVal.id.toString();
                    this.$store.commit(`forms/${ types.UPDATE_FORM_FIELD_BY_ID }`, {
                        formId: this.formId,
                        fieldName: this.choseSizeFormFieldName,
                        newFieldData: modifiedFiled
                    });
                }
            },
            immediate: true
        }
    },


    methods: {
        successSubmit($event) {
            this.$popups.close();

            let popupTypeTextName = "errorEmailSubscribe";
            const responseObjKey = Object.keys($event)[0];
            if (responseObjKey && Object.hasOwn($event[responseObjKey], "status")){
                switch ($event[responseObjKey].status){
                    case "subscribed_email":
                    case "duplicate_subscribed_email":
                        popupTypeTextName = "successEmailSubscribe";
                        break;
                    case "unsubscribed_email_sent":
                    case "duplicate_unsubscribed_email":
                        popupTypeTextName = "needConfirmEmailSubscribe";
                        break;
                }
            }

            this.$popups.open({
                component: InfoPopup,
                props: {
                    title: { text: this.$t(`waitList.${[popupTypeTextName]}.title`), },
                    description: { text: this.$t(`waitList.${[popupTypeTextName]}.description`), },
                    submitButton: {
                        text: this.$t(`waitList.${[popupTypeTextName]}.btnText`),
                        clickCallback: this.$popups.close,
                    }
                }
            });
        },
        errorSubmit(error) {
            if (error?.WaitListForm?.token) {
                this.hasCaptchaError = true;
                this.$store.commit(`forms/${ types.UPDATE_FORM_VALUE_BY_ID }`, {
                    formId: this.formId,
                    fieldName: 'token_type',
                    value: 'v2'
                });
            } else {
                this.$popups.close();

                this.$popups.open({
                    component: InfoPopup,
                    props: {
                        title: { text: this.$t('waitList.errorEmailSubscribe.title'), },
                        description: { text: this.$t('waitList.errorEmailSubscribe.description'), },
                        submitButton: {
                            text: this.$t('waitList.errorEmailSubscribe.btnText'),
                            clickCallback: this.$popups.close,
                        }
                    }
                });
            }
        },

        choseSize($event, size) {
            this.changedSize = size;
        },
    },


    beforeDestroy() {
        this.$store.commit(`forms/${ types.REMOVE_FORM_LIST }`, this.formId);
    }
};
