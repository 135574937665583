//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import FooterFollowUs from "./FooterFollowUs";
import FooterColumnLink from "./FooterClolumnLink";

export default {
    name: "FooterTopRow",
    components: {FooterColumnLink, FooterFollowUs},
    computed: {
        ...mapGetters({
            columns: 'core/getFooterColumns',
            columnsMobile: 'core/getFooterMobileColumns'
        }),
        columnsList() {
            return this.$breakpoint.tabletLandscapeUp ? this.columns : this.columnsMobile
        },
        isMobile() {
            return !this.$breakpoint.tabletLandscapeUp
        }
    },
    methods: {
        hasNoTitle(title) {
            return !title || title.trim() === ''
        }
    },
}
