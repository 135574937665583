//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";
import bnplIcon from '~/assets/img/icons/bnplIcon.svg?raw'

export default {
    name: "BnplPartPay",
    components: {VPopup},
    data() {
        return {
            bnplImg: bnplIcon,
        }
    },
    methods: {
        closePopup() {
            this.$popups.close();
            this.$router.push(this.$router.history.current.path);
        }
    }
}
