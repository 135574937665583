var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.promotionViewed,
      once: true,
      intersection: {
        threshold: 0.55,
      },
    }),expression:"{\n      callback: promotionViewed,\n      once: true,\n      intersection: {\n        threshold: 0.55,\n      },\n    }"}],staticClass:"banner",class:{'light-banner': _vm.isLightBanner, text: _vm.isText}},[_c('div',{staticClass:"banner__media",class:{small: _vm.isSmall, text: _vm.isText}},[_c('common-media',{staticClass:"banner__media--content",attrs:{"media":_vm.innerData.media,"no-lazy":_vm.isFirst}})],1),_vm._v(" "),_c('common-container',{staticClass:"banner__content",class:[
            {
                'text-banner': _vm.isText,
            },
            _vm.getPositionClass('x'),
            _vm.getPositionClass('y')
        ]},[_c('div',{staticClass:"banner__content--wrap"},[(_vm.hasLogo)?_c('div',{staticClass:"banner__logo"},[_c('common-media',{attrs:{"media":_vm.innerData.logo}})],1):_vm._e(),_vm._v(" "),(_vm.innerData.title)?_c('div',{staticClass:"banner__title",style:({color: _vm.innerData.titleColor})},[_vm._v("\n                "+_vm._s(_vm.innerData.title)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.innerData.subtitle)?_c('div',{staticClass:"banner__subtitle",style:({color: _vm.innerData.subtitleColor})},[_vm._v("\n                "+_vm._s(_vm.innerData.subtitle)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.innerData.buttons.length)?_c('div',{staticClass:"banner__buttons",class:{'no-subtitle': !_vm.innerData.subtitle}},_vm._l((_vm.innerData.buttons),function(button,index){return _c('main-btn',{key:index,staticClass:"mobile-small",attrs:{"hex-colors":{background: button.background, color: button.color},"to":_vm.localePath(button.href)},on:{"mouseup":function($event){return _vm.onMouseUp($event, button.text)},"click":function($event){return _vm.promotionClicked(button.text)}}},[_vm._v("\n                    "+_vm._s(button.text)+"\n                ")])}),1):_vm._e(),_vm._v(" "),(_vm.innerData.mainFileLink)?_c('nuxt-link',{staticClass:"banner__link",attrs:{"to":_vm.localePath(_vm.innerData.mainFileLink)},nativeOn:{"mouseup":function($event){return _vm.onMouseUp.apply(null, arguments)},"click":function($event){return _vm.promotionClicked.apply(null, arguments)}}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }