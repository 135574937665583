//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductContent from "@/components/pages/productCard/ProductContent/index";
import ProductSidebar from "@/components/pages/productCard/ProductSidebar/index";
import {mapGetters} from "vuex";
import productPageEvent from '~/assets/js/eSputnik/productPageEvent';

export default {
    name: "ProductCard",
    components: {
        ProductSidebar,
        ProductContent,
    },
    props: {
        product: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            widgetsDataLoading: 'dynamicPage/getWidgetsDataLoading',
            selectedSize: 'productView/getChosenSize',
            isGuest: 'user/isGuest'
        }),
        isDesktop() {
            return this.$breakpoint.laptopSmallUp;
        },
        locale() {
            return this.$i18n.locale === 'ru' ? 'ru' : 'kz'
        },
    },

    data() {
        return {
            timeOut: null,
        }
    },
    mounted() {
        this.fadeIn();
        window.addEventListener('scroll', this.stickyScroll);
        window.addEventListener('scroll', this.fadeIn);
        if(process.browser){
            productPageEvent(this.product, this.selectedSize);
            setTimeout(()=>{
                this.$store.dispatch('productView/getSizes', this.$route.path);
            }, 1000)
        }
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
        window.removeEventListener('scroll', this.stickyScroll);
        window.removeEventListener('scroll', this.fadeIn);
    },
    methods: {
        stickyScroll() {
            let scroll = window.scrollY;
            let sections = document.getElementsByClassName('scroll-section'),
                header = document.getElementsByClassName('header')[0].offsetHeight,
                product = document.getElementsByClassName('product-card')[0],
                sidebar = document.getElementsByClassName('product__sidebar__inner')[0],
                chooseSize = document.getElementsByClassName('choose-size')[0],
                nav = document.getElementsByClassName('navigation')[0],
                menuList = document.getElementsByClassName('navigation__list')[0],
                menuItems = document.getElementsByClassName('navigation__list--item');
            sections.forEach(i => {
                let top = i.offsetTop,
                    bottom = top + i.offsetHeight,
                    id = i.getAttribute('id');
                if (scroll > top && scroll < bottom) {
                    menuItems.forEach(el => {
                        if (el.dataset.anchor === id) {
                            el.classList.add('active');
                            let left = el.offsetLeft - 10;
                            menuList.scrollTo({
                                left: left,
                                behavior: 'smooth'
                            })

                        } else {
                            el.classList.remove('active');
                        }
                    })
                }
            });

            if (nav) {
                if (scroll >= nav.offsetTop + header) {
                    nav.classList.add('fixed');

                    if (scroll >=  product.offsetHeight + header - 2*nav.offsetHeight) {
                        nav.classList.add('hide');
                    } else {
                        nav.classList.remove('hide');
                    }
                } else {
                    nav.classList.remove('fixed');
                }
            }
            if (chooseSize) {
                if (chooseSize && scroll >= nav.offsetTop + header && scroll <= product.offsetHeight + header - window.innerHeight) {
                    chooseSize.classList.add('show');
                } else {
                    chooseSize.classList.remove('show');
                }
            }

            if (sidebar) {
                if (scroll >= sidebar.offsetTop + header && scroll <= product.offsetHeight + header - sidebar.offsetHeight) {
                    sidebar.classList.add('fixed')
                } else if (scroll > product.offsetHeight + header - sidebar.offsetHeight) {
                    sidebar.classList.remove('fixed')
                    sidebar.classList.add('bottom')
                } else {
                    sidebar.classList.remove('fixed', 'bottom')
                }
            }
        },
        fadeIn() {
            let scrollTop = window.scrollY + window.innerHeight,
                sections = document.querySelectorAll('.fade-in');

            sections.forEach(i => {
                let top = i.offsetTop + 200;

                if (scrollTop > top && !i.classList.contains("show")) {
                    i.classList.add("show")
                }
            });
        },
    },
    watch: {
        product: {
            handler(newVal, oldVal){
                let newValId = _get(newVal, 'id', null),
                    oldValId = _get(oldVal, 'id', null);
                if(newVal && newValId !== oldValId){
                    if (process.browser) {
                        this.$gtag('event', 'view_item', {
                            value: this.product.price.sale,
                            locale: this.locale,
                            items: this.product.sizes.map(i => {
                                return {
                                    id: i.id,
                                    google_business_vertical: 'retail'
                                }
                            })
                        });

                        this.timeOut = setTimeout(function (){
                            window.fbq && window.fbq('track', 'ViewContent',{
                                content_type: 'product',
                                value: this.product.price.sale,
                                currency: 'KZT',
                                content_ids: [this.product.id],
                            });
                        }.bind(this), 2000)
                    }
                }
            },
            immediate: true
        },
        isGuest: {
            handler(newVal){
                if(!newVal && this.product){
                    this.$criteo.productView(this.product.id)
                }
            },
            immediate: true
        },
        selectedSize: {
            handler(newVal, oldVal){
                if( newVal.id !== oldVal.id){
                    productPageEvent(this.product, this.selectedSize)
                }
            },
        }
    }
}
