export default {
    orderThank: 'Спасибо, ваша заяка отправлена',
    subscribeThank: 'СПАСИБО, МЫ СООБЩИМ, КАК ТОЛЬКО YEEZY ПОЯВЯТСЯ В ПРОДАЖЕ',
    subscribeBtnText: 'ПРОДОЛЖИТЬ ПОКУПКИ',
    confirmSize:{
        title: 'Проверьте выбранный размер',
        subtitle: 'Выбранный размер нельзя будет изменить или обменять в магазине после подтверждения',
        changeSize: 'Изменить размер',
        confirmSize: 'Подтведить',
    },
    sizePlaceholder: 'Выбери размер',
    apply: 'Отправить заявку',
    subscribe: 'Подписаться на рассылку',
    returnConditionsTitle: 'Условия возврата',
    returnSaleConditionsTitle: 'УСЛОВИЯ ПРИОБРЕТЕНИЯ',
    popupPhone: {
        title: 'Подтвердить номер телефона',
        inputPlaceholderPhone: 'Мобильный телефон',
        inputPlaceholderCode: 'Код подтверждения',
        codeSendToNumber: 'Код подтверждения отправлен на номер',
        buttonSend: 'Отправить код',
        buttonConfirm: 'Подтвердить',
        buttonBreak: 'Отменить',
        resend: 'Отправить еще раз',
        errorRequired: 'Поле должно быть заполнено',
        errorPhone: 'Телефон введен неверно',
        errorCode: 'Неправильный код подтверждения',
        errorCodeLength: 'Длина кода подтверждения должна составлять {length} символы(ов)'
    },
    fieldsTitle: {
        personalData: 'Персональные данные',
        birthDate: 'Дата рождения',
        gender: 'Пол',
        loginData: 'Данные для входа в аккаунт'
    },
    chooseYourSize: 'Выбери свой размер',
    confirmPhone: 'Подтвердите номер телефона',
    hasRequest: 'Вы уже отправляли заявку на участие',
}
