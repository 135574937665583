import * as formState from "../../store/formState";
import mutationTypes from "../../store/mutation-types";
import {createNamespacedHelpers} from 'vuex';
import registerModule from "../../store/registerModule";

export default {
    data: function () {
        return {
            changeWasEmitted: false,
            dataUpdateTimeout: null
        }
    },
    computed: {
        rowName: function () {
            return this.rowData.name
        },
        formName: function () {
            return  _get(this, 'row.formName', '')
        },
        rowId: function () {
            return this.rowData.id
        },
        rows: function () {
            return this.getRowsArr
        },
        row: function () {
            return this.getOneRow(this.rowId)
        },
        keyValueFormData: function () {
            return this.getKeyValueData
        },
        manualChange: function () {
            return _get(this, 'row.state.manualChange', true)
        },
        autoChange: function () {
            return _get(this, 'row.state.autoChange', true)
        }
    },
    methods: {
        removeRowFromState() {
            this.REMOVE_ROW(this.rowId);
        },
        setNewRowData(data, emit = true) {
            this.dataUpdateTimeout && clearTimeout(this.dataUpdateTimeout);
            this.dataUpdateTimeout = setTimeout(() => {
                data = Object.assign(data, {
                        state: Object.assign({}, _get(data, 'state', {}), {
                            requiredForSubmit: this.required
                        }),
                        error: Object.assign(
                            {},
                            _get(this, 'row.error',{}),
                            data.error
                        )
                    }
                );

                const newData = this.autoChange ? data : Object.assign({}, data, {
                    value: this._value,
                    selectedValue: this._selectedValue,
                });

                this.UPDATE_ROW_DATA({id: this.rowId, row: newData});
                this.newRowData = null;

                if (emit) {
                    this.changeWasEmitted = true;
                    this.$emit('input', JSON.parse(JSON.stringify(newData)));
                }
            }, 0)
        },
        selectedValueUpdate(val) {
            const newVal = this.getValueFromSelected(val);
            this.newRowData = Object.assign({}, (this.newRowData || this.row), {
                selectedValue: val,
                value: newVal
            });
            this.$v.value.$model = newVal;
        },
        manualSelectedValueUpdate(val) {
            this.selectedValueUpdate(this.manualChange ? val : this._selectedValue);
        },
        getValueFromSelected(selected) {
            if (typeof selected === 'object' && selected !== null) {
                return selected[this.valueProp] || '';
            } else {
                return selected;
            }
        }
    },
    watch: {
        'rowData': {
            handler(val, oldVal) {
                if ((JSON.stringify(val) !== JSON.stringify(oldVal)) && (JSON.stringify(val) !== JSON.stringify(this.row))) {
                    this.setNewRowData(JSON.parse(JSON.stringify(val)), false);
                }
            },
            deep: true
        },
        "row.value": {
            handler(val, oldVal) {
                if (val !== oldVal) {
                    this.$emit("valueChange", val);

                    // Clear old backEnd validation error text on change
                    if (this.row?.error?.permanentText) {
                        const newData = Object.assign({}, this.row, {
                            error: Object.assign({}, this.row.error, {
                                permanentText: ""
                            })
                        });
                        this.UPDATE_ROW_DATA({id: this.rowId, row: newData});
                    }
                }

            },
        }
    },
    beforeCreate() {
        const stateName = this.$options.propsData.stateName;
        const { mapMutations, mapGetters, mapState } = createNamespacedHelpers(stateName);
        const rowData = {...this.$options.propsData.rowData};

        registerModule(this.$store, stateName);

        this.$options.methods = {
            ...this.$options.methods,
            ...mapMutations({
                REMOVE_ROW: mutationTypes.REMOVE_ROW,
                UPDATE_ROW_DATA: mutationTypes.UPDATE_ROW_DATA
            })
        };
        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters({
                getRowsArr: 'rowsArr',
                validateFormNow: 'getValidateFormNow',
                getOneRow: 'getOneRow',
                getKeyValueData: 'keyValueData',
                getAllValidated: 'allValidated'
            }),
        };
        const canBeFilled = process.client ? !_get(window, `state[${rowData.formName}][${rowData.id}]`, false) : true;
        if (canBeFilled) {
            this.$store.dispatch(`${stateName}/${mutationTypes.ADD_ROW}`, rowData);
        }
    },
    beforeDestroy() {
        this.removeRowFromState();
        const stName = this.stateName;
        if (!(this.rows.length > 0)) {
            this.$store.unregisterModule(stName);
        }
    }
}
