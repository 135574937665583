//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainHeader from "~/widgets/header";
import thankYou from "@/components/popups/SubscribePopup/thankYou";
import UnsuccessfulPayment from "~/components/popups/UnsuccessfulPayment.vue";
import subscribePopupCallback from "@/mixins/subscribePopupCallback";
import cookiesPopupCallback from "@/mixins/cookiesPopupCallback";
import logoStructuredData from "~/mixins/structuredData/logoStructuredData";
import breadcrumbListStructureData from "~/mixins/structuredData/breadcrumbListStructureData";
import searchActionStructuredData from "~/mixins/structuredData/searchActionStructuredData";
import LazyHydrate from "vue-lazy-hydration";
import LiveChat from "../components/common/LiveChat";
import { mapGetters } from "vuex";
import InfoPopup from "~/components/common/infoPopup";


export default {
    name: "Default",
    middleware: ['fetch-dynamic-page-data'],

    head() {
        return {
            htmlAttrs: {
                lang: this?.$i18n?.localeProperties?.["iso-639-1"] ? this.$i18n?.localeProperties?.["iso-639-1"] : "en"
            },
            title: this.pageTitle,
            meta: this.metaWithAppStore.map(el => {
                const data = {};
                for (let k in el){
                    data[k] = el[k]
                }
                data.hid = data.name || data.property || '';
                return data
            }),
            link: this.metaLinks.map(el => {
                const data = {};
                for (let k in el){
                    data[k] = el[k]
                }
                return data
            }),
            script: this.computedStructuredDataScripts
        };
    },

    components: {
        LiveChat,
        LazyHydrate,
        FooterSmall: () => import("@/widgets/footerSmall/index"),
        LoginAndCart: () => import("~/widgets/loginAndCart/index"),
        MainSubscribe: () => import("~/widgets/subscribe/index"),
        MainFooter: () => import("~/widgets/footer"),
        MainHeader,
        PopupsWrapper: () => import("~/plugins/VPopup/wrapper")
    },

    mixins: [
        subscribePopupCallback,
        logoStructuredData,
        breadcrumbListStructureData,
        searchActionStructuredData,
        cookiesPopupCallback,
    ],

    data() {
        return {
            liveChatOpen: false,
        };
    },

    computed: {
        ...mapGetters({
            pageType: "dynamicPage/pageType",
            metaLinks: 'dynamicPage/metaLinks',
            metaTags: 'dynamicPage/metaTags',
            pageTitle: 'dynamicPage/pageTitle',
            localeApi: 'core/getApiLangPoint',
            isGuest: 'user/isGuest',
        }),

        fullLink() {
            return `https://www.adidas.kz${this.$route.path}`
        },
        metaWithAppStore() {
            return this.metaTags.concat({
                name:"apple-itunes-app",
                content:`app-id=6475962766, app-argument=${this.fullLink}`,
                hid:"apple-itunes-app",
            })
        },
        computedStructuredDataScripts() {
            return this.pageType === "main" ?
                [
                    this.logoStructuredData,
                    this.searchActionStructuredData,
                    this.criteoScript,
                ] :
                [
                    this.logoStructuredData,
                    this.breadcrumbListStructureData,
                    this.criteoScript,
                ];
        },
        lang() {
            return this.$i18n.locale
        },
        criteoPartnerId(){
            return this.lang === 'ru' ? PARAMS.criteo_id_ru : PARAMS.criteo_id_kz
        },
        criteoScript(){
            return {
                type: 'text/javascript',
                src: `//dynamic.criteo.com/js/ld/ld.js?a=${this.criteoPartnerId}`,
                async: true
            }
        },

        itsCart() {
            return !!["cart"].find(i => i === this.$route.name);
        },
        itsCheckout() {
            return !!["delivery", "payment", "confirmation"].find(i => i === this.$route.name);
        },
        itsNoCriteoView() {
            return !!["cart", "cart-ru", "confirmation"].find(i => i === this.$route.name);
        },
        route() {
            return this.$route.name;
        },
        localeApiIso() {
            return Helpers.getLocaleIso(this.$i18n,this.localeApi);
        },
        currentLang() {
            return this.$i18n.locale;
        },
        locale() {
            return this.lang === 'ru' ? 'ru' : 'kz'
        },
    },

    methods: {
        showThankYouPopup() {
            if (+this.$route.query.showIsConfirmedPopup === 1) {
                this.$popups.open({
                    component: thankYou
                });
            } else if (this.$route.query.wishListConfirmationPopup === "1") {
                setTimeout(() => {
                    this.$popups.close();
                    this.$popups.open({
                        component: InfoPopup,
                        props: {
                            title: { text: this.$t("waitList.successEmailSubscribe.title"), },
                            description: { text: this.$t("waitList.successEmailSubscribe.description"), },
                            submitButton: {
                                text: this.$t("waitList.successEmailSubscribe.btnText"),
                                clickCallback: this.$popups.close,
                            }
                        }
                    });
                }, 0);
            }
        },
        showUnsuccessfulPaymentPopup() {
            if (this.$route.query.payment === 'unsuccessful') {
                setTimeout(()=>{
                    this.$popups.open({
                        component: UnsuccessfulPayment
                    });
                })
            }
        },
        sendCriteo() {
            if (this.pageType === 'main') {
                this.$criteo.simpleEvent('viewHome')
            } else if (this.pageType !== 'pdp' && this.pageType !== 'plp' && !this.itsNoCriteoView) {
                this.$criteo.simpleEvent('viewPage')
            }
        },
        async getESToken() {
            return new Promise((resolve, reject) => {
                this.$axios.post(`${API.main}/customer/appinbox`).then((response) => {
                    resolve(response.data.token);
                }).catch((e) => {
                    resolve("");
                });
            });
        },
        initES() {
            if (process.browser) {
                this.$nextTick(function () {
                    window.eS('init', {APP_INBOX: true}, {getAuthTokenCallback: () =>this.getESToken(),  language: this.currentLang, })
                });
            }
        },
        reInitES() {
            if (process.browser) {
                window['eS']('reInitAppInbox', {
                    language: this.currentLang,
                    timeZone: 'Europe/Kiev',
                    getAuthTokenCallback: () =>this.getESToken()
                });
            }
        }
    },

    watch: {
        route: {
            handler() {
                this.showThankYouPopup();
                this.showUnsuccessfulPaymentPopup();
                this.sendCriteo();
                this.$gtag('event', 'setLocale', {
                    locale: this.locale,
                });
            }
        },
        isGuest: {
            handler() {
                this.reInitES()
            }
        }
    },

    async mounted() {

        this.showThankYouPopup();
        this.showUnsuccessfulPaymentPopup();
        setTimeout(async () => {
            this.$axios.defaults.headers['Accept-Language'] = this.localeApiIso;
            await this.$i18n.setLocale(this.localeApi);

            this.$cookies.set('responseLang', this.localeApiIso);
            this.$cookies.set('preferenceLang', this.localeApi);
            this.$cookies.set('responseSetLang', this.localeApiIso);
            this.canShowCookiesPopup();
        }, 0);
        setTimeout(() => {
            // this.$liveChat && this.$liveChat.init();
            this.liveChatOpen = true;
        }, 12000);

        await this.$store.dispatch("user/fetchUserData");
        this.sendCriteo();
        this.initES();

        if (process.browser) {
            this.$gtag('event', 'setLocale', {
                locale: this.locale,
            });
        }
    }
};
