export default {
    title: 'Жеке кабинет',
    readMore: 'Көбірек білу',
    welcomeText: 'Оралуыңмен',
    welcomeDelivery: 'Сені қайта көргенімізге қуаныштымыз - жеткізудің барлық егжей-тегжейін нақтылап алайық...',

    // DETAILS
    titleDetails: 'Менің деректерім',
    accountDetails: 'Жеке деректер',
    profile: {
        title: 'Менің деректерім',
        checkDetails: 'Көрсетілген деректерді тексеруіңді сұраймыз',
        data: 'Деректер',
        infoTitle: 'Аккаунтқа кіруге арналған деректер',
        changeProfile: 'Профильді өзгерту',
        removeAccount: 'Аккаунтты жою',
        removeInfo: 'Аккаунтты жойғаннан кейін бұдан былай Universe қатысушысы бола алмайсың.'
    },

    // HISTORY
    titleHistory: 'Тапсырыстар тарихы',
    history: {
        title: 'Тапсырыстар тарихы',
        findAnother: 'Басқа тапсырыс іздеп жатсың ба?',
        findOrder: 'Тапсырысты бақылау',
        yourOrder: 'Сенің тапсырысың',
        showDetail: 'Тапсырыстың егжей-тегжейін қарау',
        loadMore: 'Тағы да жүктеу',

        expectedDateLabel: 'Болжамды жеткізу күні',
        details: 'Егжей-тегжейлер',
        order: 'Тапсырыс',
        payment: 'Төлем',
        deliveryType: 'Жеткізу әдісі',
        address: 'Мекенжай',
        delivery: 'Жеткізу',
        discount: 'Жеңілдік',
        summaryLabel: 'Жалпы сома',

        paymentRepeatInfo: '<b>СІЗДІҢ Тапсырыс: {id} төленбеген.</b> Ресімдеуді аяқтау үшін «тапсырысты төлеу» батырмасын бассаң, автоматты түрде төлем жүйесінің сайтына өтесің.',
        paymentRepeatText: 'Ресімдеуді аяқтау үшін «тапсырысты төлеу» батырмасын бассаң, автоматты түрде төлем жүйесінің сайтына өтесің.',
        paymentRepeatBtn: 'тапсырысты төлеу',
        noPayment: 'Тапсырыс төленбеген',
    },

    // ADDRESSES
    titleAddresses: 'Жеткізу мекенжайлары',
    savedAddresses: 'Сақталған мекенжайлар',

    addresses: {
        title: 'Мекенжайлар',
        newBtn: 'Жаңа мекенжай',
        emptyList: 'Сенде әзірше сақталған мекенжай жоқ',
        changeBtn: 'Мекенжайды өзгерту',
        removeBtn: 'Жою',
        byDefault: 'Әдепкі бойынша мекенжай',
        titleAdd: 'Мекенжай қосу',
        courierDelivery: 'Курьерлік жеткізу',
        enterPhoneInfo: 'Ұялы телефонның өзекті нөмірін енгіз. Бұл нөмірге тапсырысыңның мәртебесі туралы ақпарат аласың',
        saveBtn: 'Сақтау',
        cancelBtn: 'Доғару',
    },

    //QR
    qr: {
        title: 'Сенің Member ID',
        info: 'adidas дүкендерінде сканерлер.',
    },

    // LOYALTY
    titleLoyalty: 'Клубтық бағдарлама',

    // preferences page
    preferences: {
        title: 'Ұнататын нәрселер',
        subscribe: 'Бөлісу',
        subscribeTitle: 'Adidas таратпасы',
        subscribeText: 'Акциялар, лимиттелген топтамалар, арнайы ұсыныстар, іс-шаралар мен конкурстар туралы бірінші боп білу үшін, adidas таратпасына жазыл. Төменде не туралы біліп отырған қызықты болатынын таңдауыңа болады.',
        subscribeRadio: 'Я даю ТОО «АДИДАС» ЖШС өзімнің <a href="https://media.adidas.kz/personal_data_accept_kz.pdf" target="_blank">жеке деректерімді өңдеуге және ақпараттық таратпалар алуға келісімімді беремін</a> және <a href="https://media.adidas.kz/personal_data_policy_kz.pdf" target="_blank">Дербес деректерді өңдеу бойынша саясатпен келісемін.</a><br/>' +
            'Мен adidas жіберген хабарламаларда арнайы сілтеме арқылы өтіп, келісімімді қайтып ала алатынымды түсінемін. ' +
            'Мен "Адидас" ЖШС және runtastic GmbH ( "runtastic") атынан электрондық пошта арқылы дербес маркетингтік хабарламалар алуға, сондай-ақ adidas және runtastic үшін менің дербес деректерімді осындай хабарламалар алу және қарау мақсатында өңдеуге келісемін.',
        youSubscribed: 'Сен біздің таратпаға жазылдың',
        unsubscribe: '* Жазылудан бас тарту үшін тиісті жолдағы белгішені алып таста',
        interestedTitle: 'Өзіңе қызықты нәрсені таңда',
        interestedText: 'Тек қажет ақпаратты алып отыру үшін, қандай бағыттар ұнайтынын таңда.',
        updateInterested: 'Ұнайтын нәрселер тізімін жаңарт',
    },

    // FAVORITES
    addToFavorite: 'Таңдаулыға қосу',
    favorite: 'Таңдаулы',
    favoriteList: 'қалаған заттар тізімі',
    titleFavorites: 'Таңдаулы',
    favoriteModel: 'Таңдаулы модельдер',
    favoriteTotal: 'Ұнаған модельдер',
    favoriteEmpty: 'Таңдаулы модельдер тізімі бос тұр. Сатып алуды баста және ұнаған модельдерді таңдаулыға қос.',

    //RegisterBanner
    registerTitle: 'Ұнаған модельдер тізімін қадағалап отыр',
    registerText: 'adidas universe қатарына қосыл және бірінші тапсырысқа 10% жеңілдік ал. Немесе өз аккаунтыңа кіріп, ұнаған модельді(дерді).',
    registerLoginQuestion: 'Сен adidas universe қатысушысысың бар?',

    welcomeHead: {
        hi: 'Сәлем',
        not: '{username} емес?',
        logout: 'Шығу',
    },
    cartWidget: {
        inCart: 'Себетте',
        totalProducts: 'Барлық тауар',
        goToCart: 'Себетке өту',
    },
    order: {
        dateOrder: 'Тапсырыс берген күн',
        showDetail: 'Тапсырыстың егжей-тегжейін қарау',
    },
    coupons: {
        title: 'Сенің купондарың',
        infoExpired: 'Промокодты бір рет қана белсендіруге болады. Ұпайлар 1 жыл бойы жарамды.',
        isCopy: 'Промокод көшірілді',
        showPromoCode: 'Промокодты көрсету',
        hidePromoCode: 'Промокодты жасыру',
        emptyList: 'Қосымша промокодтар жоқ.',
    },
    universe: {
        title: 'adidas universe ұпайлар мен жеңілдіктер',
        description: 'ADIDAS UNIVERSE - бұл adidas фирмалық дүкендері мен дисконт-орталықтарындағы, сондай-ақ adidas.kz сайтындағы adidas өнімдеріне 20% дейінгі үдемелі жеңілдігі бар бейілділік бағдарламасы.',
        expired: 'Сіздің жеңілдігіңіз әлі де жарамды',
        days: 'күн',
        points: 'ұпай',
        currentLevel: 'Қазіргі деңгей',
        accumulate1: 'Тағы жинақта',
        accumulate2: 'дейінгі',
        accumulate2_1: 'ұпай',
        accumulate3: 'және келесі жеңілдік деңгейіне жет! ',
        accumulate3_1: '1 ұпай - 1 тенге. Туралы көбірек білу',
        moreInfo: 'туралы көбірек білу'
    },

    removePopup: {
        titleEnd: 'біз сағынып қаламыз',
        info1: 'Сен adidas есептік жазбасын жойғың келетініне сенімдісің бе? Бұдан соң аккаунттағы: тапсырыстар тарихы, көркем альбом және үдеу статистикасы сияқты жеке ақпаратқа кіре алмайсың.',
        info2: 'Егер аккаунтыңды жоюға бел бусаң, аккаунтты жоюды растайтын хат мына жерге жіберіледі',
        removeBtn: 'Аккаунтты жою',
        cancel: 'Доғару'
    },

    changeData: {
        title: 'Деректерді редакциялау',
        saveBtn: 'Деректерді жаңарту',
        cancelBtn: 'Доғару',

    },

    changeEmail: {
        title: 'Email-ды редакциялау',
        saveBtn: 'Өзгерістерді сақтау',
        cancelBtn: 'Доғару',
    },

    changePassword: {
        title: 'Құпиясөзді өзгерту',
        saveBtn: 'Өзгерістерді сақтау',
        cancelBtn: 'Доғару',
        oldLabel: 'Ескі құпиясөз',
        newLabel: 'Жаңа құпиясөз',
        errorLength: 'Құпиясөз кемінде 8 символдан тұруы тиіс'
    },


    successRegister: {
        title: 'Бір рет түртсең, жетіп жатыр',
        text: 'Жазылымды растау сілтемесі бар хат\n' +
            'поштаға жіберілді',
        btn: 'ОК',
    }
}
