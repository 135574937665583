//
//
//
//
//
//
//
//

export default {
    name: "HeaderMobileContainer",
    components: {
        HeaderMobileMenu: () => import("@/widgets/header/HeaderMain/components/HeaderMobileMenu/index"),
        HeaderIconMenu: () => import("./HeaderIconMenu"),
        HeaderIconFavorite: () => import("./HeaderIconFavorite")
    }
}
