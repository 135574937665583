//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonMediaBackground from "@/components/common/CommonMediaBackground";

export default {
    name: "SectionBackgroundContainer",

    components: {
        CommonMediaBackground,
    },

    props: {
        widgetInfo: {
            type: Object,
            require: true,
            default() {
                return {};
            }
        },
        bgHeight: {
            type: String,
            default: "100%",
        },
        resizeCallback: {
            type: Function,
            default() {
                return () => {
                };
            }
        }
    },

    computed: {
        hasWidgetBg() {
            return Boolean(
                this.widgetBackground &&
                this.widgetBackground.media &&
                this.widgetBackground.media?.src?.desktop?.["1x"] &&
                this.widgetBackground.media?.src?.tablet?.["1x"] &&
                this.widgetBackground.media?.src?.mobile?.["1x"]
            );
        },
        widgetBackground() {
            return _get(this, "widgetInfo.data.widgetBackground", null);
        },
    },
};
