
export const state = () => ({
    routeInfo: null,
    macroPageStructure: {},
    microPageStructure: [],
    dynamicPageLoading: false,
    widgetsDataLoading: false,
});

export const getters = {
    getRouteInfo: ({ routeInfo }) => routeInfo,
    getMacroPageStructure: ({ macroPageStructure }) => macroPageStructure,
    getMicroPageStructure: ({ microPageStructure }) => microPageStructure,
    breadcrumbsData: ({routeInfo} ) => _get(routeInfo, 'metadata.breadcrumbs', []),
    pageType: ({routeInfo} ) => _get(routeInfo, 'type', 'unknown'),
    pageH1 : ({routeInfo} ) => _get(routeInfo, 'metadata.h1', ''),
    pageTitle : ({routeInfo} ) => _get(routeInfo, 'metadata.title', ''),
    metaLinks: ({routeInfo}) => _get(routeInfo, 'metadata.link', []),
    metaTags: ({routeInfo}) => _get(routeInfo, 'metadata.meta', []),
    getDynamicPageLoading: ({ dynamicPageLoading }) => dynamicPageLoading,
    getWidgetsDataLoading: ({ widgetsDataLoading }) => widgetsDataLoading,
};

export const actions = {
};

export const mutations = {
    [types.SET_ROUTE_INFO] (state, data) {
        state.routeInfo = data
    },
    [types.SET_MACRO_STRUCTURE] (state, data) {
        state.macroPageStructure = data
    },
    [types.SET_MICRO_STRUCTURE] (state, data) {
        state.microPageStructure = data
    },
    [types.CLEAR_PAGE_INFO] (state) {
        state.routeInfo = null
        state.macroPageStructure = {}
        state.microPageStructure = []
    },
    [types.SET_DYNAMIC_PAGE_LOADING] (state, data) {
        state.dynamicPageLoading = data
    },
    [types.SET_WIDGETS_DATA_LOADING] (state, data) {
        state.widgetsDataLoading = data
    }
};
