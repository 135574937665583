//
//
//
//
//
//
//
//
//
//

import mainLayout from "./layouts/Main";
import SectionFullWidth from "@/components/common/section/SectionFullWidth";
import widgetMixin from "@/mixins/widgetMixin";

export default {
    name: "MainHomeBanner",
    components: {
        SectionFullWidth,
        mainLayout
    },
    mixins: [widgetMixin],
};
