//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StoreListItem from "@/components/pages/store/StoreListItem/index";
import { mapGetters } from "vuex";

export default {
    name: "StoreList",

    components: { StoreListItem },

    props: {
        list: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    computed: {
        ...mapGetters({
            getCurrentPage: "catalogStore/getCurrentPage",
            getPerPageCount: "catalogStore/getPerPageCount",
            widgetsDataLoading: "dynamicPage/getWidgetsDataLoading",
            pageLoading: "dynamicPage/getDynamicPageLoading",
        }),
        products() {
            return this.widgetsDataLoading ?
                this.skeletonData
                : this.list.map(function(item) {
                    let colors = [];

                    if (!Array.isArray(item.colors) && typeof item.colors === "object") {
                        for (let color_id in item.colors) {
                            colors.push(item.colors[color_id]);
                        }
                    } else {
                        colors = item.colors;
                    }
                    return {
                        ...item,
                        colors: colors,
                    };
                });
        },
        getProductPositionByIndex() {
            return (index) => {
                return +(this.getCurrentPage > 1 ? ((this.getCurrentPage - 1) * this.getPerPageCount) : 0) + index;
            };
        },
        skeletonData() {
            let array = [];
            array.length = 12;
            return array.fill({});
        }
    },

    methods: {
        productVisibilityChanged(isVisible, entry, productInfo, positionInList) {
            if (isVisible) {
                this.$ga.sendViewedOnScrollProduct({
                    name: productInfo.displayName,
                    id: productInfo.id,
                    price: productInfo.price.sale,
                    brand: productInfo.division,
                    category: productInfo.productPath || [],
                    variant: "adidas",
                    position: positionInList
                });
            }
        }
    }
};
