//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "ProductPdp",
    props: {
        product: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasPdp() {
            return this.showTitle || this.showSubtitle;
        },
        title() {
            return (_get(this, 'product.description.pdpCallout.title', '') || '').trim()
        },
        subtitle() {
            return (_get(this, 'product.description.pdpCallout.subtitle', '') || '').trim()
        },
        showTitle() {
            return this.title !== ''
        },
        showSubtitle() {
            return this.subtitle !== ''
        }
    }
}
