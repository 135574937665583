//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";
import ShowHideBtn from "~/components/common/buttons/ShowHideBtn.vue";

export default {
    name: "Password",
    components: {ShowHideBtn},
    mixins: [fieldMixin],
    data: function () {
        return {
            show: false,
        }
    },
    computed: {
        btnText: function () {
            return this.$t(`common.${this.show ? 'hide' : 'show'}`).toLocaleLowerCase()
        },
        fieldType: function () {
            return this.show ? 'text' : 'password'
        }
    },
    methods: {
        changeShowState() {
            this.show = !this.show
        },
    }
}
