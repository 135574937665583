//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Carousel from "@/widgets/carousel/Index";
import SectionTitle from "@/components/common/section/SectionTitle";
import Item from "./Components/Item";
import CommonBtnText from "@/components/common/buttons/TextBtn";
import SlideCarousel from "@/components/common/slideCarousel/index";
import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";
import SectionHeader from "~/components/common/section/SectionHeader";

export default {
    name: "Main",

    components: {
        SectionHeader,
        SlideCarousel,
        CommonBtnText,
        SectionTitle,
        Carousel,
        Item
    },

    mixins: [widgetLayoutMixin],

    props: {
        slideWidth: {
            type: Array,
            required: true
        },
    },

    data() {
        return {
            settings: {
                dots: true,
                variableWidth: true,
                infinite: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 760,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            },
            activeSlide: 0,
            windowWidth: 0,
        };
    },

    computed: {
        showAllItemsLink() {
            return this.innerData.link &&
                this.innerData.link.href &&
                this.innerData.link.text &&
                this.innerData.link.href !== "" &&
                this.innerData.link.text !== "";
        },
        items() {
            return _get(this, "innerData.items", []);
        },
        computedTitle() {
            if(typeof (this.innerData.title) === "object"){
                return this.innerData.title;
            }
            return {
                text: this.innerData.title,
                color: ""
            }
        }
    },

    methods: {
        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
        },
        productVisibilityChanged(isVisible, entry, productInfo, positionInList) {
            if (isVisible) {
                this.$ga.sendViewedOnScrollProduct({
                    name: productInfo.displayName,
                    id: productInfo.id,
                    price: productInfo.price.sale,
                    brand: productInfo.division,
                    category: productInfo.productPath || [],
                    variant: "adidas",
                    position: positionInList,
                    list: this.widgetInfo.name
                });
            }
        }
    },

    mounted() {
        this.$nextTick(function() {
            this.getWindowWidth();
        });
    },
};
