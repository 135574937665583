import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            hasCaptchaError: false,
            captchaFieldName: 'token',
            captchaFieldTypeName: 'token_type',
        };
    },
    async fetch() {
        if (!this.formId) {
            return;
        }
        await this.$store.dispatch(`forms/loadFormByIdWithData`, {
            formId: this.formId,
            data: this.formIdParams ?? {},
            ctx: this.$nuxt.context,
        });
        this.$emit('formLoaded');
    },
    fetchOnServer: true,
    computed: {
        ...mapGetters({
            'getFormById': 'forms/getFormById',
            'getAllFormFieldsDataWithoutGrouping': 'forms/getAllFormFieldsDataWithoutGrouping'
        }),
        keyValueFormData() {
            return this.getAllFormFieldsDataWithoutGrouping(this.formId);
        },
        form() {
            return this.getFormById(this.formId);
        },
        fields() {
            return _get(this.form, 'fields', []);
        },
        fieldsStates() {
            return [...(_get(this, 'fields', []).reduce((acc, item) => {
                !acc.has(item.formName) && acc.add(item.formName);
                return acc;
            }, new Set()))];
        },
    },
    methods: {
        onInput(newFieldData) {
            this.$store.commit(`forms/${ types.UPDATE_FORM_FIELD_BY_ID }`, {
                formId: this.formId,
                fieldName: newFieldData.name,
                newFieldData: newFieldData
            });
        },
        successCaptcha(token) {
            this.hasCaptchaError = false;
            if (token && this.captchaFieldName) {
                this.$store.commit(`forms/${ types.UPDATE_FORM_VALUE_BY_ID }`, {
                    formId: this.formId,
                    fieldName: this.captchaFieldName,
                    value: token
                });
                this.$store.commit(`forms/${ types.UPDATE_FORM_VALUE_BY_ID }`, {
                    formId: this.formId,
                    fieldName: this.captchaFieldTypeName,
                    value: 'v2'
                });
            }
        },
        errorCaptcha() {
            this.hasCaptchaError = true;
        },
    },
    beforeDestroy() {
        this.$store.commit(`forms/${ types.REMOVE_FORM_LIST }`, this.formId);
    }
};
