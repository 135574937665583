//
//
//
//
//
//
//
//
//
//
//
//

import Breadcrumbs from "@/components/common/Breadcrumbs"
import {mapGetters} from "vuex"

export default {
    name: "Breadcrumbs",
    components: {
        'breadcrumbs-component': Breadcrumbs
    },
    computed: {
        ...mapGetters({
            breadcrumbs: 'dynamicPage/breadcrumbsData',
            pageType: 'dynamicPage/pageType',
        }),
        tabletLandscapeUp() {
            return this.$breakpoint.tabletLandscapeUp;
        },
        showBreadcrumbs() {
            return this.breadcrumbs.length > 0 && this.tabletLandscapeUp && !['pdp'].includes(this.pageType)
        },
        breadcrumbsClasses() {
            const classesArr = (['plp'].includes(this.pageType) ? [
                'store__breadcrumbs',
                'small',
                'catalog-breadcrumbs'
            ] : []);
            return classesArr
        },
        breadcrumbsWrapperClasses() {
            const classesArr = [];
            ['pdp'].includes(this.pageType) && (classesArr.push('product-page-breadcrumbs-wrapper'));
            return classesArr
        },
    },
}
