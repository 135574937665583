export default {
    save:'Сақтау',
    choseLang: 'Тілді өзгерту',
    edit:'Редакциялау',
    noResults: 'Нәтиже жоқ',
    region: 'обл.',
    district: 'ауд.',
    warehouse: 'Бөлімше',
    seeAll: 'Бәрін қарау',
    clear: 'Тазалау',
    clearAll: 'Бәрін тазалау',
    apply: 'Қолдану',
    show: 'Көрсету',
    hide: 'Жасыру',
    back: 'Артқа',
    toCart: 'Себетті қарау',
    toCheckout: 'Ресімдеу',
    continueShopping: 'Зат сатып алуды жалғастыру',
    needHelp: 'Көмек қажет пе?',
    contacts: 'Байланыс деректері',
    delivery: 'Жеткізу',
    return: 'Қайтару',
    loyalty: 'Бейілділік бағдарламасы',
    continue: 'Жалғастыру',
    login: 'Кіру',
    colorsCount: 'Түстер',
    nextPage: 'Келесі',
    prevPage: 'Алдыңғы',
    page: 'Бет',
    from: 'ішінде',
    toTop: 'Үстіге',
    productCount: {
        1: 'тауар',
        2: 'тауарды',
        3: 'тауар',
    },
    subscribeBtn: 'жаңалықтарға жазылу',
    product: 'Өнімдер',
    openFullScreen: 'Барлық бетке жазып ашу.',
    close: 'Жабу',

    sap: {
        title: 'adidas app',
        description: 'қолданбаны қолданып көріңіз',
        openApp: 'ашық',
    },

    signUp: {
        blockAgree: {
            title: 'ДАЙЫН',
            agreeDesc: 'Жазылым сәтті ресімделді. {0} таратпа тізіміне қосылды.',
        },
        form: {
            title: 'Жазыл және бірінші сатып алуға -10% жеңілдік ал!',
            subtitle: 'Жазыл және жаңа топтамалардың келгені жайлы хабардарлардың бірінші қатарында бол.',
            sex: 'Жынысы',
            male: 'Әйел',
            female: 'Еркек',
            noAnswer: 'Жауап бергім келмейді',
            btn: 'Жазылу',
            info1: 'Жоғарыдағы «Жазылу» түймесін басып, «АДИДАС» ЖШС-ға өзімнің',
            link1: 'дербес деректерімді өңдеуге және ақпараттық таратпалар алуға келісімімді беремін',
            info2: 'және мынаған',
            link2: 'Дербес деректерді өңдеу саясатымен келісемін',
            text: 'Мен «Жазылу» түймесін басқанда «АДИДАС» ЖШС-ға өз <a href="https://media.adidas.kz/personal_data_accept_kz.pdf" target="_blank">дербес деректерді өңдеуге және ақпараттық таратпалар алуға келісімімді беремін</a> және <a href="https://media.adidas.kz/personal_data_policy_kz.pdf" target="_blank">Дербес деректерді өңдеу жөніндегі саясатпен</a> келісемін',
        },
        agree: {
            title: 'Бір түрту қалды',
            text: 'Жазылуды растау үшін сілтемесі бар хат поштаға жіберілді',
            btn: 'Ок',
        },
        thanks: {
            title: 'Жазылғаныңыз үшін алғыс',
            text: 'Енді сен adidas-тан жаңалықтар мен таратпалар алып тұрасың. Алдағы релиздерден хабардар болу үшін, өзіңнің пошта жәшігіңді тексер.'
        },
    },

    days: {
        Monday: 'ДС',
        Tuesday: 'СС',
        Wednesday: 'СР',
        Thursday: 'БС',
        Friday: 'ЖМ',
        Saturday: 'СБ',
        Sunday: 'ЖС',
    },
    weekend: 'Демалыс',
    askQuestion: 'Сұрақ қою',
    soldLabelText: 'сатылымда жоқ',

    unsuccessfulPayment: {
        title: 'Төлемнің қатесі',
        text: 'Өкінішке қарай, қате болды және төлем өткен жоқ.<br />Тапсырысты қайта жасауыңды сұраймыз',
        btn: 'Жабу',
    },

    bnpl: {
        name: 'BNPL-ден 0-0-4 БӨЛІП ТӨЛЕУ',
        description: 'Сүйікті adidas тауарларын өзіңе ыңғайлы жолмен сатып ал',
        body: {
            text1: 'adidas.kz-дан BNPL -ден («Центр Лизинг» ЖШС) 0-0-4 сервисімен комиссиялар мен артық төлемдерсіз бөліп төлеу арқылы сатып ал',
        },
        advantages: {
            title: 'АРТЫҚШЫЛЫҚТАРЫ',
            item1: 'Жедел мақұлдау',
            item2: 'Кез келген банк картасымен өтеу',
            item3: 'Комиссиясыз және артық төлемсіз мерзімінен бұрын өтеу',
        },
        steps: {
            title: 'БӨЛІП ТӨЛЕУ АРҚЫЛЫ САТЫП АЛУДЫ ҚАЛАЙ РӘСІМДЕУГЕ БОЛАДЫ',
            item1: 'adidas.kz сайтынан тауарды таңдап ал',
            item2: 'BNPL серіктестік платформасына өту үшін төлем бетінде «0-0-4 бөліп төлеуді» таңда',
            item3: 'BNPL платформасында телефон нөміріңді және ЖСН енгіз',
            item4: 'Ай сайынғы төлемді есептен шығару үшін банк картасының деректерін сақта. Бірінші төлем тауар жөнелтілгеннен кейін 30 күн өткен соң алынады',
        },
        rules: {
            title: 'ТАУАРДЫ 0-0-4 БӨЛІП ТӨЛЕУ АРҚЫЛЫ САТЫП АЛУ ШАРТТАРЫ',
            item1: '21 жастан 60 жасқа дейін',
            item2: 'Қолданылу мерзімі кемінде 4 ай болатын Қазақстан Республикасының кез келген банкінің қолданыстағы картасы',
            item3: 'Тапсырыс сомасы 6,000 теңгеден 200,000 теңгеге дейін',
            item4: 'Қазақстан Республикасының азаматтығы',
        },
    },

    halykPart: {
        title: {
            main: 'НomEbank Бөліп төлеу',
            secondary: 'БПайызсыз және артық төлемсіз!',
        },
        body: {
            text1: '3 айға артық төлемсіз бөліп төлеуді ресімде.',
            text2: 'Назар аудар: сатып алу үшін сен кемінде 18 жасқа толуың керек және сен Қазақстан Республикасының резиденті болуың керек.',
        },
        advantages: {
            title: 'Артықшылықтар',
            item1: '3 айға дейін - бөліп төлеуді жылдам ресімдеу.',
            item2: 'Бөліп төлеуді ресімдеу үшін 0%.',
            item3: 'Комиссиясыз және артық төлемсіз мерзімінен бұрын өтеу.',
            item4: 'Бөліп төлеу сомасы 4 000 теңгеден бастап 2 000 000 теңгеге дейін.',
        },
        steps: {
            title: 'Тауарды Halyk bank-тан бөліп төлеуге қалай сатып алуға болады',
            item1: 'Тауарды сайттан таңда',
            item2: 'Төлем бетінде "Halyk Bank-тан бөліп төлеу" төлем тәсілін таңда.',
            item3: 'Halyk Homebank көмегімен QR сканерден өткіз.',
            item4: 'Скорингтен өт.',
        }
    },

    cookiePopup: {
        title: "ADIDAS веб-сайтындағы ең жақсы тәжірибе үшін cookies-ті қадағалаймыз",
        description: "\"Cookies қабылдау\" таңдау арқылы сіз adidas-қа cookie файлдарын, пикселдерді, тегтерді және басқа да ұқсас технологияларды пайдалануға рұқсат бересіз. Біз оларды жарнамаларды дербестендіру және веб-сайтты жақсарту сияқты маркетингтік және функционалдық мақсаттар үшін сайттағы әрекеттеріңізді бақылау үшін құрылғы мен браузер туралы ақпарат жинау үшін пайдаланамыз. adidas бұл ақпаратты маркетингтік мақсаттар үшін үшінші тұлғаларға, соның ішінде Google, Meta әлеуметтік медиа жарнамалық серіктестеріне бере алады. Құпиялылық туралы Ережеге өтіңіз.",
        button: "Cookies-ті қабылдау",
    },
}
