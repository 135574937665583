//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "@/plugins/VPopup/popup";

export default {
    name: "CookiesPopup",

    components: {
        VPopup,
    },

    props: {
        formId: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        button: {
            type: String,
            default: ''
        },

        localStorageKey: {
            type: String,
            required: true,
        },

    },

    methods: {
        submitPopup() {
            this.$popups.close();
            localStorage.setItem(this.localStorageKey, Date.now().toString())
        }
    },

}
