export const state = () => ({
    imagesCount: 1,
});

export const getters = {
    getCurrentImageCount: ({imagesCount}) => imagesCount,
};
export const mutations = {
    INCREASE_IMAGE_COUNT(state,) {
        state.imagesCount++;
    },
};


export const actions = {
    async getLocalizeData({dispatch}, lang) {
        try {
            await Promise.allSettled([
                dispatch('header/fetchData', lang),
                dispatch('core/fetchFooterData', lang),
            ]);
        } catch (err) {
        }
    },
    async getUserData({dispatch}) {
        try {
            await Promise.allSettled([
                dispatch('cart/getCart'),
                dispatch('favorite/getFavorite'),
            ]);
        } catch (err) {
        }
    },
    async nuxtServerInit(store, ctx) {
        try {
            store.commit(`core/${types.SET_FB_API_KEY}`, ctx.env?.facebookAppId);
            store.commit(`core/${types.SET_GOOGLE_API_KEY}`, ctx.env?.googleAuth2ClientAppId);
        } catch (err) {
        }
    }
};



