export default {
    search: 'Іздеу',
    searchHistory: 'Іздеу тарихы',
    pageTitle: 'Біз сұратым бойынша ештеңе таба алмадық',
    pageSubtitle: 'Берілме! Жазылуын тексер немесе іздеу сұратымын басқаша жаз.',
    pageSomeElse: 'Саған ұнауы мүмкін:',
    pageWeRecommended: 'Біз кеңес береміз',
    clear: 'тазалау',
    showAll: 'Бәрін көру',

    // Error page

    errorPageTitle: 'Бет табылмады, бірақ берілмеу керек',
    errorSubtitle: 'Сұратымды мына жерге енгіз',
    errorSomeElse: 'Браузер жолында мекенжайдың дұрыс жазылуын тексер. Немесе қайтып орал',
    errorSomeElse2: 'басты бетке',
    errorSomeElse3: 'және қайтадан істеп көр.'
}
