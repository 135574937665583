//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "TrousersTable",
    data: function () {
        return {
            tableHeadData: [
                this.$t('sizeTable.womenTrousers.table.header.title1'),
                this.$t('sizeTable.womenTrousers.table.header.title2'),
                this.$t('sizeTable.womenTrousers.table.header.title3'),
                this.$t('sizeTable.womenTrousers.table.header.title4')
            ],
            tableBodyData: [
                [
                    "XS", "40 - 42", "71 - 75", "82 - 86"
                ],
                [
                    "S", "44 - 46", "76 - 82", "87 - 93"
                ],
                [
                    "M", "48 - 50", "83 - 90", "94 - 101"
                ],
                [
                    "L", "52 - 54", "91 - 99", "102 - 110"
                ],
                [
                    "XL", "56 - 58", "100 - 109", "111 - 119"
                ],
                [
                    "2XL", "60 - 62", "110 - 121", "120 - 128"
                ],
                [
                    "3XL", "64 - 66", "122 - 134", "129 - 138"
                ]
            ]
        }
    }
}
