export default {
    cart: 'Корзина',
    addToCart: 'Добавить в корзину',
    addedToCart: 'Добавлено в корзину',
    added: 'Добавлено',
    addLoading: 'Добавляется...',
    freeDelivery: 'Бесплатная доставка заказов от 50 000 ₸',
    chooseSize: 'Выбрать размер',
    sizeChart: 'Таблица размеров',
    availableColors: 'Доступные цвета',
    chooseYourSize: 'Выбери свой размер',
    onWarehouse: 'Осталось на складе',
    gallery: 'Галерея',
    description: 'Описание',
    care: 'Уход',
    aboutModel: 'О модели',
    modelColor: 'Цвет модели',
    modelArticle: 'Номер модели',
    careRecommendation: 'Рекомендации по стирке',
    extraCareRecommendation: 'Дополнительная информация по уходу',
    color: 'Цвет',
    size: 'Размер',
    count: 'Количество',
    countShort: 'Кол-во',
    withPDV: '(включая НДС)',
    totalSum: 'Общая сумма',
    totalProduct: 'Всего товаров',
    costProduct: 'Стоимость товаров',
    halykPartTitle: 'Покупай в рассрочку c ',
    halykPartTitleWithTerm: 'Покупай в рассрочку на 3 месяца с ',
    halykMore: 'Узнать больше',
    bnplTitle: 'Рассрочка 0-0-4',
    bnplMonth: 'мес',
    bnplReadMore: 'Подробнее',
    infoRow: {
        row1: 'указан британский размер, сверься с таблицей размеров',
        row2: 'Бесплатная доставка заказов на сумму от 50 000 ₸',
        row3: 'Доставка по Республике Казахстан службами Казпочта, EMEX и DHL',
        row4: '60 дней на возврат товара',
    },
    sizeEmpty: {
        title: 'Товар полностью распродан',
        text: 'К сожалению, на данный момент данного товара нет в наличии.',
    }
}
