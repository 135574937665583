//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "MenuColumnItem",
    components: {
        mediaItem: () => import("./components/mediaItem"),
        textItem: () => import("./components/textItem"),
        emptyItem: () => import("./components/emptyItem"),
        emptyLineItem: () => import("./components/emptyLineItem"),
    },
    props: {
        itemsList: {
            required: true,
            type: Array
        }
    },
    computed: {
        hasItemsList: function () {
            return !!_get(this, 'itemsList', false) && this.itemsList.length > 0
        },
    },
    methods: {
        itemComponentName(type) {
            const suffix = type.indexOf('item') !== -1? '' : "-item";
            return  `${type.trim()}${suffix}`
        },
    }

}
