//
//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";

export default {
    name: "UnsuccessfulPayment",
    components: {VPopup},
    methods: {
        closePopup() {
            this.$popups.close();
            this.$router.push(this.$router.history.current.path);
        }
    }
}
