//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "StoreHead",
    props: {
        info: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            showDescription: false
        }
    },
    computed: {
        isDesktop() {
            return this.$breakpoint.laptopSmallUp
        },
        btnText() {
            return this.showDescription ? 'Згорнути' : 'Дивитись більше';
        },
        platform() {
            return process.browser ? navigator.platform : false
        },
        isMac() {
            return process.browser ? navigator.platform.indexOf('Mac') > -1 : false
        }
    },
    methods: {
        toggleDescription() {
            this.showDescription = !this.showDescription;
        }
    }
}
