export default {
    // SUBSCRIBE
    subscribe: {
        buttonText: 'Подпишись на рассылку',
        mainText: 'Подпишись на новости и получи скидку -10%'
    },

    // LOGIN AND CART
    loginAndCart: {
        myAccount: 'Мой аккаунт',
        login: 'Войти',
        cart: 'Корзина',
        toTop: 'Вверх'
    },

    // FOOTER
    footer: {
        followUs: 'Следите за нами',
        legalInfo: 'Юридическая информация',
        privacyData: 'Обработка персональных данных',
        rules: 'Условия',
        rights: 'ТОО "АДИДАС". Все права защищены.'
    }
}
