export const state = () => ({
    catalog: {},
    lastHistoryIndex: 0,
    filterSelectionHistory: [],
    openedFilters: [],
    allFiltersShowed: false,
    mobileFiltersPopupShowed: false,
});

export const getters = {
    getProducts: (store) => (_get(store, 'catalog.products', [])),
    getFilters: (store) => (_get(store, 'catalog.attributes.available', [])),
    getAppliedFilters: (store) => (_get(store, 'catalog.attributes.selected', [])),
    getSortingLinks: (store) => (_get(store, 'catalog.attributes.sorting', [])),
    productsCount: (store) => (_get(store, 'catalog.count', 0)),
    pageCountsInfo: (store) => (_get(store, 'catalog.counts', null)),
    getCurrentPage: (store) => +(_get(store, 'catalog.counts.currentPage', 1)),
    getPerPageCount: (store) => +(_get(store, 'catalog.counts.perPage', AppConfig.perPage)),
    links: (store) => (_get(store, 'catalog.links', {})),
    getFilterSelectionHistory: (store) => (_get(store, 'filterSelectionHistory', [])),
    attributesFilterSelectionHistory: (store, getters) => (_get(getters, 'getFilterSelectionHistory', [])),
    getOpenedFilters: (store) => (_get(store, 'openedFilters', [])),
    firsPageLink: (store) => (_get(store, 'catalog.links.first', null)),
    getAllFiltersShowed:  (store) => (_get(store, 'allFiltersShowed', false)),
    getMobileFiltersPopupShowed:  (store) => (_get(store, 'mobileFiltersPopupShowed', false)),
    showClearAllLink: (store, getters) => (_get(getters, 'attributesFilterSelectionHistory', []).length > 0),
    clearLinkUrl: (store, getters) => {
        if(!getters.showClearAllLink){
            return '';
        }
        return getters.attributesFilterSelectionHistory[0]['clearUrl']
    }
};

export const actions = {
    updateHistoryLinksAfterFiltersDataLoad ({state, dispatch, commit}, appliedFiltersList) {
        const removedIds = []
        state.filterSelectionHistory.forEach(function (el) {
            !appliedFiltersList.some(filter=>filter.id === el.id) && (removedIds.push(el.id))
        });
        removedIds.forEach(filterId => {
            commit(types.REMOVE_FILTER_HISTORY_ITEM, {id:filterId})
        })
        appliedFiltersList.forEach(filter => {
            commit(types.ADD_NEW_FILTER_TO_HISTORY, filter)
        })
    }
};

export const mutations = {
    [types.SET_CATALOG_DATA](state, data) {
        state.catalog = data;
    },
    [types.ADD_NEW_FILTER_TO_HISTORY](state, data) {
        const indexOfThisItemInHistory = state.filterSelectionHistory.findIndex(el => el.id === data.id)

        if (indexOfThisItemInHistory >= 0) {
            Vue.set(state.filterSelectionHistory, indexOfThisItemInHistory,  {
                ...data,
                orderIndex: state.filterSelectionHistory[indexOfThisItemInHistory].lastHistoryIndex
            })
        } else {
            state.filterSelectionHistory.push({
                ...data,
                orderIndex: state.lastHistoryIndex
            });
            state.lastHistoryIndex++
        }

    },
    [types.REMOVE_FILTER_HISTORY_ITEM](state, {id}) {
        const filterIndexInHistory = state.filterSelectionHistory.findIndex(el => el.id === id);
        if (filterIndexInHistory >= 0) {
            state.filterSelectionHistory.splice(filterIndexInHistory, 1);
        }
    },
    [types.TOGGLE_SELECT_STATE_FOR_FILTER](state, {filterKey, filterItem}) {
        state.catalog.attributes.available[filterKey].values = state.catalog.attributes.available[filterKey].values.map((el) => {
            el = Object.assign({}, el, {
                selected: el.id === filterItem.id? !el.selected : el.selected
            })
            return el
        })
        state.catalog.attributes.available[filterKey].selected = state.catalog.attributes.available[filterKey].values.some(el=>el.selected)

    },
    [types.CLEAR_FILTER_HISTORY](state) {
        const fullClear = !(state.filterSelectionHistory.length > 1)
        state.filterSelectionHistory = fullClear ? [] : [state.filterSelectionHistory[0]]
    },

    [types.ADD_OPENED_FILTER](state, key) {
        const newOpenedList = new Set([
            ...state.openedFilters,
            key
        ]);
        state.openedFilters = Array.from(newOpenedList)
    },
    [types.REMOVED_OPENED_FILTER](state, key) {
        const openedFilterIndex = state.openedFilters.findIndex(el => el === key);
        if (openedFilterIndex >= 0) {
            state.openedFilters.splice(openedFilterIndex, 1);
        }
    },
    [types.REMOVED_ALL_OPENED_FILTER](state) {
        state.openedFilters = []
    },
    [types.CHANGE_ALL_FILTERS_SHOWED_STATE](state, val ) {
       state.allFiltersShowed = val
    },
    [types.CHANGE_MOBILE_FILTERS_POPUP_SHOWED_STATE](state, val ) {
       state.mobileFiltersPopupShowed = val
    }

};
