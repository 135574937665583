export default {
    data() {
        return {
            searchActionStructuredData: {
                type: "application/ld+json",
                json: {
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "url": `${ this.$config?.baseURL }`,
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": {
                            "@type": "EntryPoint",
                            "urlTemplate": `${ this.$config?.baseURL }/search?s={search_term_string}`
                        },
                        "query-input": "required name=search_term_string"
                    }
                }
            }
        };
    },
};
