//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FilterMobilePopup
    from "@/components/pages/store/StoreFilter/Components/FilterMobile/Components/FilterMobilePopup/index";
import arrow from '~/assets/img/icons/FilterDropdown.svg?raw'
import {mapGetters} from "vuex";
import FiltersList from "@/components/pages/store/StoreFilter/Components/FilterBar/Components/FiltersList/index";

export default {
    name: "FilterMobileList",
    components: {FiltersList, FilterMobilePopup},
    data() {
        return {
            arrowIcon: arrow,
        }
    },
    computed: {
        ...mapGetters({
            filters: 'catalog/getFiltersList',
            productsCount: 'catalogStore/productsCount',
            showClearAllLink: 'catalogStore/showClearAllLink',
            clearLinkUrl: 'catalogStore/clearLinkUrl',
            getMobileFiltersPopupShowed: 'catalogStore/getMobileFiltersPopupShowed',
        }),
        closeLink() {
            return '/man'
        },
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            } else  {
                return false;
            }
        }
    },
    methods: {
        togglePopup() {
            this.$store.commit(`catalogStore/${types.CHANGE_MOBILE_FILTERS_POPUP_SHOWED_STATE}`, !this.getMobileFiltersPopupShowed)

            if (this.getMobileFiltersPopupShowed) {
                this.body && this.body.classList.add('v-popup-open');
                this.$breakpoint.isMacOs && this.body.classList.add('isMacOs');
                this.$liveChat && this.$liveChat.hide();
            } else  {
                this.body && this.body.classList.remove('v-popup-open');
                this.$liveChat && this.$liveChat.show();
            }
        }
    }
}
