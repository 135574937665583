export default {
    title: 'Корзина',
    toCheckout: 'Оформить',
    product: 'товар',
    products: 'товаров',
    product_n: 'товара',

    cartInfo: '• Бесплатная доставка заказов на сумму от 50 000 ₸<br>' +
        '• Доставка по Республике Казахстан службами Казпочта, EMEX и DHL',

    timeOut: {
        title: 'Время сессии',
        title2: 'исчерпано',
        subTitle: 'Что это означает?',
        text: 'В целях обеспечения онлайн-безопасности и защиты личных данных сессия браузера прекращается после 20 минут отсутствия активности. Но время на сайте не прошло зря. Мы сохранили все выбранные товары в корзине.',
        button: 'Вернуться в корзину'
    },

    popup: {
        here: 'здесь',
        forLink: 'ссылке',
        delivery: {
            title: 'Доставка',
            rules: {
                title: 'Какие условия доставки?',
                text_01: 'Мы отправляем товары по всей территории Республики Казахстан.',
                text_02: 'Мы доставим бесплатно по Республике Казахстан любой заказ на сумму 50 000 тенге и выше.',
                text_03: 'Стоимость доставки других заказов рассчитывается на этапе оформления заказа на сайте и зависит от выбранного способа и региона доставки.',
                text_04: 'Отправка осуществляется в день оформления заказа или максимум на следующий день.',
            },
            dpdW: {
                title: 'Доставка в отделение DPD',
                text_01: 'Доставка осуществляется в течение 1-2 дней по г.Алматы и от 2 до 8 дней в другие населенные пункты Республики Казахстан в зависимости региона.',
                text_02: 'Срок хранения посылки в отделении DPD – 14 дней.',
            },
            dpdC: {
                title: 'Доставка по адресу курьером DPD',
                text_01: 'Доставка курьером осуществляется в течение 2 дней по г.Алматы и от 2 до 8 дней в другие населенные пункты Республики Казахстан в зависимости региона.',
            },
            kazpostW: {
                title: 'Доставка в отделение Казпочта',
                text_01: 'Доставка Казпочта осуществляется от 2 до 10 дней в зависимости региона (доставка по г. Алматы – в течение 2 дней).',
                text_02: 'Срок хранения посылки в отделении – 7 дней.',
            },
            dhlC: {
                title: 'ДОСТАВКА ПО АДРЕСУ КУРЬЕРОМ DHL',
                text_01: 'Доставка осуществляется в течение 1-2 дней по г.Алматы и от 2 до 5 дней в другие населенные пункты Республики Казахстан в зависимости региона.',
            },
            emex: {
                title: 'ДОСТАВКА КУРЬЕРОМ EMEX',
                text_01: 'Доставка осуществляется в течение 1-2 дней по г.Алматы и от 2 до 11 дней в другие населенные пункты Республики Казахстан в зависимости региона.',
            },
            summary: 'Чтобы получить детальную информацию по стоимости доставки в твой населенный пункт, позвони на горячую линию по номеру +7 747 362 21 77 или напиши в online чат на сайте.',
        },
        return: {
            title: 'Возврат',
            text_01: 'Ты можешь вернуть товар, который по каким-либо причинам не подошел, при условии сохранения его товарного вида, упаковки, этикеток, товарной накладной и чека <b>в течение 60 дней с момента покупки.</b>',
            text_02: 'Мы советуем примерить и внимательно осмотреть товар после его получения в комфортной обстановке, не срывая этикеток.',
            text_03: 'Ознакомиться с правилами возврата ты можешь по',
            text_04: 'Обрати внимание: согласно законодательству Республики Казахстан есть группы товаров, не подлежащих возврату и обмену. Со списком таких товаров ты можешь ознакомиться по',
        },
        contacts: {
            title: 'Контакты',
            supportTitle: 'Служба поддержки',
            supportSchedule: 'Время работы: 7 дней в неделю с 10:00 до 19:00, без выходных:',
            supportB1: 'по номеру +7 747 362 21 77',
            supportB2: 'в онлайн чате на сайте adidas.kz. Иконка чата доступна на каждой странице сайта.',
            supportB3: 'Отправить сообщение можно через диалоговое окно чата или выбрать удобный тебе мессенджер (Telegram или Viber).',
            supportCancel: 'Для того, чтобы отменить заказ нажми',
            chapter_01: {
                title: 'Адреса и контакты магазинов:',
                text: 'Информация о розничных магазинах доступна по',
            },
            chapter_02: {
                title: 'ТОО "АДИДАС"',
                text: 'Алматы, Медеуский район, проспект Достық, 210, офис А-3 Блок',
            },
        }
    },

    sidebar: {
        help: {
            title: 'Нужна помощь?',
            delivery: 'Доставка',
            return: 'Возврат',
            contacts: 'Контакты',
        },
        pay: {
            title: 'Методы оплаты'
        }
    },

    empty: {
        title: 'Корзина пуста',
        text: 'Здесь появятся товары добавленные в корзину. Начать покупки?',
        toStore: 'За покупками',
        tooltip: 'В корзине нет товаров'
    },

    content: {
        total: 'Всего',
        size: 'Размер',
        sizeEnded: 'Этот размер скоро закончится',
        sizeEnd: 'Нет в наличии',
        count: 'Количество',
        yourOrder: 'Твой заказ',
        firstPrice: 'Начальная цена',
        sale: 'Скидка',
        withVat: 'включая НДС',
        promocode: 'Промо-код',
        noIdentify: 'не идентифицирован. Проверь, правильный ли промо-код, и попробуй еще раз.',
        apply: 'Применить',
        code: 'Код',
        applied: 'Применено',
        delivery: 'Доставка',
        enterPromoCode: 'Введи промо-код',
        maxCountTitle: 'Пожалуйста, измени свой заказ ',
        maxCountText: 'Количество товаров в корзине превышает максимальное значение 10.',
    },

    notification: {
        updateCartTitle: {
            title: 'Список добавленых в корзину товаров был обновлен.',
            text: ' {name} добавлено в избранное.'
        }
    }
}
