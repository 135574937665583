//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import Item from "./Components/Item";
import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";

export default {
    name: "Main",

    components: {
        ResponsiveImage,
        Item
    },

    mixins: [widgetLayoutMixin],

    data() {
        return {
            gaSendTimeout: null,
            dataToSendInGa: []
        };
    },

    computed: {
        sortList() {
            return _get(this, "innerData.items", []);
        },
        computedClassCount() {
            return `cards-count-${ this.sortList.length }`;
        }
    },

    methods: {
        oneCardViewed(isVisible, entry, item, position) {
            if (!isVisible) {
                return;
            }
            this.dataToSendInGa.push(Object.assign({}, item, {
                position
            }));
            this.gaSendTimeout && clearTimeout(this.gaSendTimeout);
            this.gaSendTimeout = setTimeout(() => {
                this.$ga.viewPromotion(_get(this, "dataToSendInGa", []).map((el) => {
                    let creative = _get(el, "link.text", "");
                    creative = creative !== "" ? creative : _get(this, "innerData.title", "");
                    return {
                        name: this.widgetInfo.name,
                        id: this.widgetInfo.id,
                        creative: creative,
                        position: el.position
                    };
                }));
                this.dataToSendInGa = [];
            }, 50);
        }
    }
};
